import React, { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import { useNavigate } from "react-router-dom";

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from "@mui/lab/LoadingButton";
import Delete from '@mui/icons-material/Delete';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import Checkbox from '@mui/material/Checkbox';

import axios from 'api/axios';
import PdfRecursiveListItem from './PdfRecursiveListItem';
import ProtisPhoto from './MissionDetailsProtisPhoto';
import { FormControlLabel } from '@mui/material';

const PdfModal = (props) => {
  const navigate = useNavigate();

  const MISSIONPROTISPDF_URL = "/mission/pdf/photo"
  const MISSIONPROTISPDFFILE_URL = "/mission/pdf/photo/file"
  const MISSIONPROTISPDFSTORE_URL = "/mission/pdf/photo/store"

  const [pdfModalLoading, setPdfModalLoading] = useState(false);
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [protisFullTree, setProtisFullTree] = useState([]);
  const [protisFullData, setProtisFullData] = useState({ containers: [], photos: [], videos: [] });
  const [currentFolderId, setCurrentFolderId] = useState(null);
  const [showMissionComment, setShowMissionComment] = useState(true);
  const [groupByFolder, setGroupByFolder] = useState(true);
  const [showFoldersComment, setShowFoldersComment] = useState(true);
  const [showPhotoType, setShowPhotoType] = useState(true);
  const [showPhotoDate, setShowPhotoDate] = useState(true);
  const [showPhotoComment, setShowPhotoComment] = useState(true);


  useEffect(() => {
    if (props.protisData) {
      prepareFullTree(props.protisData);
    }
  }, [props.protisData]);

  useEffect(() => {
    if (props.open) {
      setCurrentFolderId(null);
      if (protisFullData.photos) {
        initSelected();
      }
    } else {
      resetForm();
    }
  }, [props.open]);

  useEffect(() => {
    selectedPhotos.sort((a, b) => (a.selectOrder > b.selectOrder) ? 1 : -1)
  }, [selectedPhotos]);

  useEffect(() => {
    selectedFolders.forEach((folder) => {
      folder.photos.sort((a, b) => (a.selectOrder > b.selectOrder) ? 1 : -1);
    });
  }, [selectedFolders]);

  const handleResetClick = () => {
    resetForm();
  }

  const resetForm = () => {

    setShowMissionComment(false);
    setGroupByFolder(false);
    setShowFoldersComment(false);
    setShowPhotoType(false);
    setShowPhotoDate(false);
    setShowPhotoComment(false);
    setSelectedFolders([]);
    setSelectedPhotos([]);

    var fullData = protisFullData;
    fullData.photos?.forEach((row) => {
      row.isSelected = false;
      delete row.selectOrder;
    });
    fullData.containers?.forEach((row) => {
      row.isSelected = false;
      delete row.selectOrder;
      row.photos.forEach((photo) => {
        photo.isSelected = false;
        delete photo.selectOrder;
      });
    });
    prepareFullTree(fullData);
  }

  const handleSaveClick = async () => {
    try {
      setPdfLoading(true);

      await savePdfData();

      setPdfLoading(false);
    } catch (err) {
      setPdfLoading(false);
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        props.setSnackBarMessage("Technical error ! Please try again or contact our support.");
        props.setSnackBarType("error");
        props.setOpenSnackBar(true);
      }
    }

  }

  const handleSharepointClick = async () => {
    try {
      setPdfLoading(true);

      await savePdfData();

      var config = {
        method: 'post',
        url: MISSIONPROTISPDFSTORE_URL,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'multipart/form-data',
        },
        data: {
          id_mission: props.idMission
        }
      };

      await axios(config);
      
      props?.fileForm?.current.reloadFiles();

      props.setSnackBarMessage('Pdf stored in sharepoint successfully');
      props.setSnackBarType("success");
      props.setOpenSnackBar(true);

      setPdfLoading(false);
    } catch (err) {
      setPdfLoading(false);
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        props.setSnackBarMessage("Technical error ! Please try again or contact our support.");
        props.setSnackBarType("error");
        props.setOpenSnackBar(true);
      }
    }
  }

  const prepareFullTree = (data) => {
    var containers = data.containers.map((rowContainer) => {
      rowContainer.photos = data.photos.filter((rowPhoto) => rowPhoto.id_mission_container == rowContainer.id)
      rowContainer.containers = data.containers.filter((child) => child.id_mission_container_master == rowContainer.id)
      return rowContainer;
    });
    var rootPhotos = data.photos.filter((rowPhoto) => rowPhoto.id_mission_container == null);
    var fullTree = {
      containers: containers.filter((container) => container.id_mission_container_master == null),
      photos: rootPhotos
    };
    setProtisFullTree(fullTree);
    setProtisFullData(props.protisData);
  }

  const initSelected = async () => {
    try {
      setPdfLoading(true);
      var config = {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        },
        params: {
          id_mission: props.idMission
        }
      };

      const response = await axios.get(MISSIONPROTISPDF_URL, config);
      var data = response.data.data;

      if (data) {
        setShowMissionComment(data.show_mission_comment);
        setGroupByFolder(data.group_by_folder);
        setShowFoldersComment(data.show_folders_comment);
        setShowPhotoType(data.show_photo_comment);
        setShowPhotoDate(data.show_photo_date);
        setShowPhotoComment(data.show_photo_comment);

        setSelectedFolders([]);
        setSelectedPhotos([]);
        var photos = [];
        var folders = [];

        data.photos.forEach((dbRow) => {
          var photo = protisFullData.photos.find((row) => row.id == dbRow.id_mission_photo);
          if (photo) {
            photo.isSelected = true;
            photo.isPhoto = true;
            photo.selectOrder = dbRow.order;

            //Root case => add to selectedPhotos
            if ((!data.group_by_folder || photo.id_mission_container == null) && !photos.some((item) => item.id == photo.id)) {
              photo.selectOrder = photos.length;
              photos.push(photo);
            }
            //Folder case => add to folder and add folder if not already selected
            if (photo.id_mission_container !== null && data.group_by_folder) {
              //try to find in selectedFolders
              var folder = folders.find((row) => photo.id_mission_container == row.id);
              //If not found => search in full data and pass to selected
              if (!folder) {
                folder = protisFullData.containers.find((row) => photo.id_mission_container == row.id);
                var pdfFolderData = data.containers.find((row) => photo.id_mission_container == row.id_mission_container);
                folder.isSelected = true;
                folder.selectOrder = pdfFolderData.order;
                folder.photos = folder.photos.filter((row) => row.isSelected);
              }
              if (!folder.photos?.some((row) => row.id == photo.id)) {
                folder.photos = [...folder.photos, photo];
              }
              if (!folders.some((item) => item.id == folder.id)) {
                folders.push(folder);
              }
            }
          }
        });

        setSelectedFolders(folders);
        setSelectedPhotos(photos);
      }
      setPdfLoading(false);

    } catch (err) {
      setPdfLoading(false);
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        props.setSnackBarMessage("Technical error ! Please try again or contact our support.");
        props.setSnackBarType("error");
        props.setOpenSnackBar(true);
      }
    }
  }

  const handlePreviewClick = async () => {
    try {
      setPdfLoading(true);

      await savePdfData();

      var config = {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        },
        params: {
          id_mission: props.idMission
        },
        responseType: 'arraybuffer'
      };

      const response = await axios.get(MISSIONPROTISPDFFILE_URL, config);

      // Let the user save the file.
      var blob = new Blob([response.data], { type: 'application/pdf' });

      saveAs(blob, Date.now() + ' - ' + props.idMission + '.pdf');

      setPdfLoading(false);
    } catch (err) {
      setPdfLoading(false);
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        props.setSnackBarMessage("Technical error ! Please try again or contact our support.");
        props.setSnackBarType("error");
        props.setOpenSnackBar(true);
      }
    }
  }

  const savePdfData = async () => {
    try {
      var photosIds = [];
      var containersIds = [];
      selectedFolders.forEach((row) => {
        row.photos.map((photo) => {
          if (photo.isSelected) {
            photosIds = [...photosIds, photo.id];
          }
        });
        containersIds.push(row.id);
      });
      selectedPhotos.forEach((row) => {
        if (row.isSelected) {
          photosIds.push(row.id);
        }
      });

      var config = {
        method: 'post',
        url: MISSIONPROTISPDF_URL,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'multipart/form-data',
        },
        data: {
          id_mission: props.idMission,
          mission_photos: photosIds,
          mission_containers: containersIds,
          show_mission_comment: showMissionComment ? 1 : 0,
          group_by_folder: groupByFolder ? 1 : 0,
          show_folders_comment: showFoldersComment ? 1 : 0,
          show_photo_type: showPhotoType ? 1 : 0,
          show_photo_date: showPhotoDate ? 1 : 0,
          show_photo_comment: showPhotoComment ? 1 : 0
        }
      };

      await axios(config);

      props.setSnackBarMessage('Pdf configuration saved');
      props.setSnackBarType("success");
      props.setOpenSnackBar(true);
    } catch (err) {
      setPdfLoading(false);
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        props.setSnackBarMessage("Technical error ! Please try again or contact our support.");
        props.setSnackBarType("error");
        props.setOpenSnackBar(true);
      }
    }
  }

  const addPhotoToSelected = (photo, folderId) => {

    var order = protisFullData.photos.reduce((counter, row) => {
      if (row.id_mission_container === photo.id_mission_container && row.isSelected) {
        counter++;
      }
      return counter;
    }, 0);

    var selectedPhoto = protisFullData.photos.find((row) => photo.id == row.id);
    selectedPhoto.isSelected = true;
    selectedPhoto.selectOrder = order
    //Root case => add to selectedPhotos
    if ((folderId == null || !groupByFolder) && !selectedPhotos.some((item) => item.id == photo.id)) {
      setSelectedPhotos([...selectedPhotos, selectedPhoto]);
    }
    //Folder case => add to folder and add folder if not already selected
    if (groupByFolder) {
      //try to find in selectedFolders
      var folders = selectedFolders;
      var folder = folders.find((row) => photo.id_mission_container == row.id);
      //If not found => search in full data and pass to selected
      if (!folder) {
        folder = protisFullData.containers.find((row) => photo.id_mission_container == row.id);
        folder.isSelected = true;
        folder.photos = folder.photos.filter((row) => row.isSelected);
        folders.push(folder);
      }
      //update selected folders
      var folders = folders.map((item) => {
        if (item.id == folder.id) {
          if (!folder.photos.some((row) => row.id == selectedPhoto.id)) {
            folder.photos.push(selectedPhoto);
          }
          folder.selectOrder = folders.length;
          return folder;
        }
        return item;
      });
      setSelectedFolders(folders);
    }
  }

  const handleRemovePhotoClick = (photo) => {
    setSelectedPhotos(selectedPhotos.filter((row) => row.id !== photo.id));
    protisFullData.photos.forEach((row) => {
      if (row.id == photo.id) {
        row.isSelected = false;
        delete row.selectOrder;
      }
    });
    //if folder is not null
    if (photo.id_mission_container !== null) {
      var folder = selectedFolders.find((row) => row.id == photo.id_mission_container);
      //remove photo from folder
      folder.photos = folder.photos.filter((row) => row.id !== photo.id);
      //reorder photos
      folder.photos.forEach((row, i) => {
        row.selectOrder = i;
      });
      //if folder is empty => remove folder
      if (!folder.photos?.some((photo) => photo.isSelected)) {
        var folders = selectedFolders.filter((row) => row.id !== folder.id);
        folders.forEach((row, i) => {
          row.selectOrder = i;
        })
        setSelectedFolders(folders);
      } else {//otherwise remove only photo from folder
        setSelectedFolders(selectedFolders.filter((row) => {
          if (row.id == folder.id) {
            return folder;
          }
          return row;
        }));
      }
    }
  }

  const handleFolderDownClick = (folder) => {
    const currentOrder = folder.selectOrder;

    var folders = selectedFolders.map((row) => {
      if (row.isSelected && row.selectOrder == (currentOrder + 1)) {
        row.selectOrder--;
      }
      if (folder.id == row.id) {
        row.selectOrder++;
      }
      return row;
    });

    folders.sort((a, b) => (a.selectOrder > b.selectOrder) ? 1 : -1)
    setSelectedFolders(folders);
  }

  const handleFolderUpClick = (folder) => {
    const currentOrder = folder.selectOrder;

    var folders = selectedFolders.map((row) => {
      if (row.isSelected && row.selectOrder == (currentOrder - 1)) {
        row.selectOrder++;
      }
      if (folder.id == row.id) {
        row.selectOrder--;
      }
      return row;
    });

    folders.sort((a, b) => (a.selectOrder > b.selectOrder) ? 1 : -1)
    setSelectedFolders(folders);
  }

  const handlePhotoDownClick = (photo, folder) => {
    const currentOrder = photo.selectOrder;
    var folderPhotos = folder == undefined ? selectedPhotos : folder.photos;

    var photos = folderPhotos.map((row) => {
      if (photo.id_mission_container == row.id_mission_container
        && row.isSelected
        && row.selectOrder == (currentOrder + 1)) {
        row.selectOrder--;
      }
      if (photo.id == row.id) {
        row.selectOrder++;
      }
      return row;
    });

    photos.sort((a, b) => (a.selectOrder > b.selectOrder) ? 1 : -1)
    if (folder == undefined) {
      setSelectedPhotos(photos);
    } else {
      folder.photos = photos;
      var folders = selectedFolders.map((row) => {
        if (row.id = folder.id) {
          return folder;
        }
        return row;
      })
      setSelectedFolders(folders);
    }
  }

  const handlePhotoUpClick = (photo, folder) => {
    const currentOrder = photo.selectOrder;
    var folderPhotos = folder == undefined ? selectedPhotos : folder.photos;

    var photos = folderPhotos.map((row) => {
      if (photo.id_mission_container == row.id_mission_container
        && row.isSelected
        && row.selectOrder == (currentOrder - 1)) {
        row.selectOrder++;
      }
      if (photo.id == row.id) {
        row.selectOrder--;
      }
      return row;
    });

    photos.sort((a, b) => (a.selectOrder > b.selectOrder) ? 1 : -1)
    if (folder == undefined) {
      setSelectedPhotos(photos);
    } else {
      folder.photos = photos;
      var folders = selectedFolders.map((row) => {
        if (row.id = folder.id) {
          return folder;
        }
        return row;
      })
      setSelectedFolders(folders);
    }
  }

  const handleRestoreClick = () => {
    resetForm();
    prepareFullTree(protisFullData);
    initSelected();
  }

  const handleLoadedPhoto = (photo) => {
    protisFullData.photos.forEach((row) => {
      if (row.id == photo.id) {
        row = photo;
      }
    })
  }

  const handleGroupByFolderChange = (group) => {
    var folders = [];
    var photos = [];
    if (group) {
      selectedPhotos.forEach((photo) => {
        //sort folder photos
        if (photo.id_mission_container == null) {
          photo.selectOrder = photos.length;
          photos.push(photo);
        } else {
          var folder = folders.find((row) => photo.id_mission_container == row.id);
          //If not found => search in full data and pass to selected
          if (!folder) {
            folder = protisFullData.containers.find((row) => photo.id_mission_container == row.id);
            folder.isSelected = true;
            folder.selectOrder = folders.length;
            photo.selectOrder = 0;
            folder.photos = [photo];
            folders.push(folder)
          } else {
            photo.selectOrder = folder.photos.length;
            folder.photos.push(photo);
          }
        }
      })
    } else {
      selectedFolders.forEach((folder) => {
        //sort folder photos
        var sorted = folder.photos.filter((row) => row.isSelected);
        sorted.sort((a, b) => (a.selectOrder > b.selectOrder) ? 1 : -1);
        //add each to photos
        folder.photos.forEach((photo) => {
          photo.selectOrder = photos.length;
          photos.push(photo);
        })
      })
      selectedPhotos.forEach((photo) => {
        photo.selectOrder = photos.length;
        photos.push(photo);
      })
    }
    setSelectedPhotos(photos);
    setSelectedFolders(folders);
    setGroupByFolder(group);
  }

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => props.setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus
        className={"modalPopin info"}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            height: '99%',
            width: '60%',
            bgcolor: 'rgb(52,52,52)',
            //border: '2px solid #000',
            boxShadow: 24,
            borderRadius: 2,
            p: 2,
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h4 style={{ margin: 0, color: 'white' }}>PDF Configuration</h4>
            </div>
            <IconButton aria-label="close" onClick={() => props.setOpen(false)}>
              <CloseIcon style={{ marginBottom: '3px', fontSize: '30px', color: 'white' }} />
            </IconButton>
          </div>
          <div className="pdfModalCheckboxDiv">
            <FormControlLabel control={<Checkbox checked={showMissionComment ? true : false} onChange={(e) => { setShowMissionComment(!showMissionComment) }} />} label="Show mission comment" />
          </div>
          <div className="pdfModalCheckboxDiv">
            <FormControlLabel control={<Checkbox checked={groupByFolder ? true : false} onChange={(e) => { handleGroupByFolderChange(!groupByFolder) }} />} label="Group by folder" />
            <FormControlLabel control={<Checkbox checked={showFoldersComment ? true : false} onChange={(e) => { setShowFoldersComment(!showFoldersComment) }} />} label="Show folder comment" />
          </div>
          <div className="pdfModalCheckboxDiv">
            <FormControlLabel control={<Checkbox checked={showPhotoType ? true : false} onChange={(e) => { setShowPhotoType(!showPhotoType) }} />} label="Show photo type" />
            <FormControlLabel control={<Checkbox checked={showPhotoDate ? true : false} onChange={(e) => { setShowPhotoDate(!showPhotoDate) }} />} label="Show photo date" />
            <FormControlLabel control={<Checkbox checked={showPhotoComment ? true : false} onChange={(e) => { setShowPhotoComment(!showPhotoComment) }} />} label="Show photo comment" />
          </div>
          <div style={{ display: 'flex', height: '75%', marginTop: '10px' }}>
            <div style={{ width: '49%', margin: '1%', height: '90%', border: '1px solid lightgray', overflow: 'auto' }}>
              <PdfRecursiveListItem
                {...props}
                currentFolderId={currentFolderId}
                setCurrentFolderId={setCurrentFolderId}
                currentFolderData={protisFullTree}
                addPhotoToSelected={addPhotoToSelected} />
            </div>
            <div style={{ width: '49%', margin: '1%', height: '90%', border: '1px solid lightgray', overflow: 'auto' }}>
              {showMissionComment ? <div className='containerPdfModal'>
                <div className='photoPdfModalColumnDiv'>
                  <div className='photoPdfModalComment'>{props.photosComment}</div>
                </div>
                <div className='containerSeparator'></div>
              </div> : ''}
              {selectedFolders.map((element, index) => (
                <div key={index} className='containerPdfModal'>
                  <div className='containerPdfModalHeader'>
                    <div className={`photoPdfModalColumnDiv`} style={{ width: '6%' }}>
                      <IconButton sx={{ color: 'lightGrey', padding: '0px', display: element.selectOrder > 0 ? 'inline-block' : 'none' }} onClick={() => handleFolderUpClick(element)}>
                        <ExpandLess className='photoPdfArrowIcon' />
                      </IconButton>
                      <IconButton sx={{ color: 'lightGrey', padding: '0px', display: element.selectOrder !== selectedFolders.filter((folder) => folder.isSelected).length - 1 ? 'inline-block' : 'none' }} onClick={() => handleFolderDownClick(element)}>
                        <ExpandMore className='photoPdfArrowIcon' />
                      </IconButton>
                    </div>
                    <div className='photoPdfModalColumnDiv' style={{ width: '93%' }}>
                      <div><b>{element.label}</b></div>
                      {showFoldersComment ? <div className='photoPdfModalComment'>{element.comment}</div> : ''}
                    </div>
                  </div>
                  {element.photos.map((photo, i) => (
                    photo.isSelected && <div key={i} className='photoPdfModal'>
                      <div className={`photoPdfModalColumnDiv`} style={{ width: '8%' }}>
                        <IconButton sx={{ color: 'lightGrey', display: photo.selectOrder > 0 ? 'inline-block' : 'none' }} onClick={() => handlePhotoUpClick(photo, element)}>
                          <ExpandLess className='photoPdfArrowIcon' />
                        </IconButton>
                        <IconButton sx={{ color: 'lightGrey', display: photo.selectOrder !== element.photos.filter((folderPhoto) => folderPhoto.isSelected).length - 1 ? 'inline-block' : 'none' }} onClick={() => handlePhotoDownClick(photo, element)}>
                          <ExpandMore className='photoPdfArrowIcon' />
                        </IconButton>
                      </div>
                      <div className={`photoPdfModalColumnDiv`} style={{ maxWidth: '30%' }}>
                        <IconButton sx={{ color: 'red' }} onClick={() => handleRemovePhotoClick(photo)}>
                          <ProtisPhoto
                            {...props}
                            element={photo}
                            width={60}
                            height={60}
                            loadPhoto={photo.isLoaded ? false : true}
                            handleLoadedPhoto={handleLoadedPhoto}
                          />
                          {/* <img src={`data:image/jpeg;base64,${photo?.file?.file_path}`} className='photoPdf' /> */}
                          <Delete className='photoPdfModalIcon' style={{ width: '50px', height: '50px', margin: '2px 0px 0px 7px' }} />
                        </IconButton>
                      </div>
                      <div className={`photoPdfModalColumnDiv`} style={{ width: '60%' }}>
                        {showPhotoType ? <div><b>{photo.type?.label}</b></div> : ''}
                        {showPhotoDate ? <div className='photoPdfModalComment'>{photo.created_at}</div> : ''}
                        {showPhotoComment ? <div className='folderCommentShort'>{photo.comment}</div> : ''}
                      </div>
                    </div>
                  ))}
                  <div className='containerSeparator'></div>
                </div>
              ))}
              {selectedPhotos.map((element, index) => (
                <div key={index} className='photoPdfModal'>
                  <div className={`photoPdfModalColumnDiv`} style={{ width: '8%' }}>
                    <IconButton sx={{ color: 'lightGrey', display: element.selectOrder > 0 ? 'inline-block' : 'none' }} onClick={() => handlePhotoUpClick(element)}>
                      <ExpandLess className='photoPdfArrowIcon' />
                    </IconButton>
                    <IconButton sx={{ color: 'lightGrey', display: element.selectOrder !== selectedPhotos.length - 1 ? 'inline-block' : 'none' }} onClick={() => handlePhotoDownClick(element)}>
                      <ExpandMore className='photoPdfArrowIcon' />
                    </IconButton>
                  </div>
                  <div className={`photoPdfModalColumnDiv`} style={{ maxWidth: '30%' }}>
                    <IconButton sx={{ color: 'red' }} onClick={() => handleRemovePhotoClick(element, null)}>
                      <ProtisPhoto
                        {...props}
                        element={element}
                        width={60}
                        height={60}
                        loadPhoto={!element.isLoaded}
                        handleLoadedPhoto={handleLoadedPhoto}
                      />
                      {/* <img src={`data:image/jpeg;base64,${element?.file?.file_path}`} className='photoPdf' /> */}
                      <Delete className='photoPdfModalIcon' style={{ width: '50px', height: '50px', margin: '2px 0px 0px 7px' }} />
                    </IconButton>
                  </div>
                  <div className={`photoPdfModalColumnDiv`} style={{ width: '60%' }}>
                    {showPhotoType ? <div><b>{element.type?.label}</b></div> : ''}
                    {showPhotoDate ? <div className='photoPdfModalComment'>{element.created_at}</div> : ''}
                    {showPhotoComment ? <div className='folderCommentShort'>{element.comment}</div> : ''}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <LoadingButton
              onClick={(e) => { handleResetClick(); }}
              variant="contained"
              disabled={
                (selectedPhotos.length == 0 && selectedFolders.length == 0 || pdfLoading)
                && !showMissionComment
                && !groupByFolder
                && !showFoldersComment
                && !showPhotoType
                && !showPhotoDate
                && !showPhotoComment}
              style={{ marginRight: '8px' }}
              sx={{
                backgroundColor: 'red',
                "&.Mui-disabled": {
                  background: "#727272"
                }
              }}
              loading={pdfModalLoading}
            >
              Reset
            </LoadingButton>
            <LoadingButton
              onClick={(e) => { handleRestoreClick(); }}
              variant="contained"
              disabled={pdfLoading}
              style={{ marginRight: '8px' }}
              sx={{
                backgroundColor: '#1976d2',
                "&.Mui-disabled": {
                  background: "#727272"
                }
              }}
              loading={pdfModalLoading}
            >
              Restore last saved
            </LoadingButton>
            <LoadingButton
              onClick={(e) => { handlePreviewClick(); }}
              variant="contained"
              disabled={selectedPhotos.length == 0 && selectedFolders.length == 0 || pdfLoading}
              style={{ marginRight: '8px' }}
              sx={{
                backgroundColor: '#1976d2',
                "&.Mui-disabled": {
                  background: "#727272"
                }
              }}
              loading={pdfModalLoading}
            >
              Preview
            </LoadingButton>
            <LoadingButton
              onClick={(e) => { handleSaveClick(); }}
              variant="contained"
              disabled={selectedPhotos.length == 0 && selectedFolders.length == 0 || pdfLoading}
              style={{ marginRight: '8px' }}
              sx={{
                backgroundColor: '#1976d2',
                "&.Mui-disabled": {
                  background: "#727272"
                }
              }}
              loading={pdfModalLoading}
            >
              Save
            </LoadingButton>
            <LoadingButton
              onClick={(e) => { handleSharepointClick(); }}
              variant="contained"
              disabled={selectedPhotos.length == 0 && selectedFolders.length == 0 || pdfLoading}
              sx={{
                backgroundColor: '#1976d2',
                "&.Mui-disabled": {
                  background: "#727272"
                }
              }}
              loading={pdfModalLoading}
            >
              Save to Sharepoint
            </LoadingButton>
          </div>
        </Box>
      </Modal>
    </>);
};

export default PdfModal;
