import React, { useState, useEffect, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { ContextBlock } from "context/ContextBlock";
import { ContextMissionDetails } from "context/ContextMissionDetails";
import { ContextMissionWorkflow } from "context/ContextMissionWorkflow";
import { ContextMissionDetailsTreatment } from "context/ContextMissionDetailsTreatment";
import { ContextMissionDetailsReceiverBL } from "../ContextMissionDetailsReceiverBL";

import axios from 'api/axios';


const ContextMissionDetailsReceiver = createContext();

const MissionDetailsReceiverProvider = ({ children }) => {

  //init config
  const navigate = useNavigate();
  const { receiverRef } = useContext(ContextMissionDetails);
  const { getMissionDetail } = useContext(ContextMissionDetails);

  //generic
  const { loading, setLoading } = useContext(ContextBlock);
  const { loadingUpdate, setLoadingUpdate } = useContext(ContextBlock);

  const { triggerInfosPopin } = useContext(ContextBlock);

  const { setSnackErrorMsg } = useContext(ContextBlock);
  const { openSnackBar, setOpenSnackBar } = useContext(ContextBlock);
  const { snackBarType, setSnackBarType } = useContext(ContextBlock);
  const { snackBarMessage, setSnackBarMessage } = useContext(ContextBlock);

  //workflow state
  const {canWrite} = useContext(ContextMissionWorkflow);
  const {isRequired} = useContext(ContextMissionWorkflow);
  const {controlMultipleFieldsData} = useContext(ContextMissionWorkflow);
  const {checkBlockOnWorkflowError} = useContext(ContextMissionWorkflow);
  const {controlFields, setControlFields} = useContext(ContextMissionWorkflow);
  const {workflowLoaded, setWorkflowLoaded} = useContext(ContextMissionWorkflow);
  const {idButtonWorkflow, setIdButtonWorkflow} = useContext(ContextMissionWorkflow);
  const {checkWorkflowData, setCheckWorkflowData} = useContext(ContextMissionWorkflow);
  const {controlFieldsError, setControlFieldsError} = useContext(ContextMissionWorkflow);
  const {controlFieldsReceiver, setControlFieldsReceiver} = useContext(ContextMissionWorkflow);

  //init mission
  const {idMission, setIdMission} = useContext(ContextMissionDetails);

  //mission general state
  const {general, setGeneral} = useContext(ContextMissionDetails);

  //treament
  const [blockCurrentSave, setBlockCurrentSave] = useState({});
  
  const { setDoneBlockSave } = useContext(ContextBlock);
  const { setErrorBlockSave } = useContext(ContextBlock);
  const { blockEdit, setBlockEdit } = useContext(ContextBlock);
  const { blockSave, setBlockSave } = useContext(ContextBlock);
  const {saveBlockAndCheckWorkflow} = useContext(ContextMissionDetailsTreatment);

  //init receiver data
  const {rowsReceiver, setRowsReceiver} = useContext(ContextMissionDetailsReceiverBL);

  const [rowsReceiverView, setRowsReceiverView] = useState([]);
  const [rowsReceiverDeleted, setRowsReceiverDeleted] = useState([]);
  const [rowsLotDeleted, setRowsLotDeleted] = useState([]);

  const [importCRData, setImportCRData] = useState([]);
  const [importPreviousCRData, setImportPreviousCRData] = useState([]);
  const [generateStatusCR, setGenerateStatusCR] = useState('none');

  const { handleClickBlockCancel } = useContext(ContextBlock);
  const handleClickCancel = async () => {
    await controlFieldsErrorTruncate();
    handleClickBlockCancel(receiverRef);
  }

  //MAPPING FIELD'S RECEIVER
  const controlFieldsMapping = [];
  controlFieldsMapping.push(
    //receiver
    "id_receiver",
    "r.tonnage",
    "id_cargo",
    "id_mission_ending",
    //receiver lot
    "id_packaging",
    "id_cargo_origin",
    "id_loading_type",
    "id_cargo_brand",
    "id_cargo_quality",
    "packaging_quantity",
    "net_tonnage",
    "gross_tonnage",
    "empty_spare_packaging_percentage"
  );


  //useEffect
  useEffect(() => {
    setControlFieldsReceiver(controlFieldsMapping);
  }, []);

  //INIT general data
  useEffect(() => {
    //load data
    if (Object.keys(general).length > 0) {        
      //INIT MISSION RECEIVER EXPORT CR CREATE STATUS
      setGenerateStatusCR(general.cr_created_status);
    }
  }, [general]);

  //INIT receivers view
  useEffect(() => {
    //load data
    if (rowsReceiver) {
      if (rowsReceiver.length > 0) {
        var rowsReceiversApi = [];
        for(var i=0; i < rowsReceiver.length; i++) {
          //not receiver deleted
          if (!rowsReceiverDeleted.find(obj => obj.id === rowsReceiver[i].id)) {
            rowsReceiversApi[i] = {};
            rowsReceiversApi[i].id = rowsReceiver[i].id;
            rowsReceiversApi[i].id_receiver = rowsReceiver[i].receiver.id;
            rowsReceiversApi[i].receiver = rowsReceiver[i].receiver.name;
            rowsReceiversApi[i].tonnage = rowsReceiver[i].tonnage;
            if (rowsReceiver[i].cargo) rowsReceiversApi[i].cargo = rowsReceiver[i].cargo.label;
            if (rowsReceiver[i].mission_ending) rowsReceiversApi[i].mission_ending = rowsReceiver[i].mission_ending.label;
            rowsReceiversApi[i].lots = rowsReceiver[i].lots;
            //not lot deleted
            rowsReceiversApi[i].lots.map((lot, index) => {
              if (rowsLotDeleted.find(obj => obj.id === lot.id)) {
                delete rowsReceiversApi[i].lots[index];
              }
            });
          }
        }
        setRowsReceiverView(rowsReceiversApi);
      }
    }
  }, [rowsReceiver, rowsReceiverDeleted, rowsLotDeleted]);

  //init block save
  useEffect(() => {
    saveBlockAndCheckWorkflow(receiverRef, 
      async () => {
        await deleteReceivers();
        await deleteLots();
        await saveReceivers(idButtonWorkflow);
      }, 
      async () => {
        await checkWorkflowRequiredFieldsReceiver();
        await checkWorkflowMissionDetailsReceiver();
        await checkWorkflowMissionDetailsReceiverLot();
      }
    );
  }, [blockSave, idMission]);

  //check block save
  useEffect(() => {
    //load current block
    var blockCurrentIndex  = blockSave.findIndex(obj => obj.blockRef === receiverRef);
    if (blockCurrentIndex >= 0) {
      if (blockCurrentSave !== blockSave[blockCurrentIndex]) {
        setBlockCurrentSave(blockSave[blockCurrentIndex]);
      }
    }
  }, [blockSave]);

  //check workflow error
  useEffect(() => {
    checkBlockOnWorkflowError(receiverRef, controlFieldsMapping, true);
  }, [controlFieldsError, blockEdit]);


  //function interactive
  const generateLotLabel = (lot, short = false) => {
    //generate lot label
    var lotLabel = '', lotShortLabel = '';

    if (lot.loading_type && lot.loading_type.id > 0) lotLabel += lot.loading_type.label;
    if (lot.brand && lot.brand.id > 0) {
      //test value api or form
      if (lot.brand.name === undefined) var brand_name = lot.brand.label;
      else var brand_name = lot.brand.name;

      if (lotLabel !== "") lotLabel += " - ";
      lotLabel += brand_name;

      lotShortLabel += brand_name;
    } else {
      lotShortLabel += (lot.loading_type && lot.loading_type.id > 0) ? lot.loading_type.label : '';
    }


    if (lot.variety && lot.variety.id > 0) {
      if (lotLabel !== "") lotLabel += " - ";
      lotLabel += lot.variety.label;
    }

    if (lot.packaging && lot.packaging.id > 0) {
      //test value api or form
      if (lot.packaging.size === undefined) var packaging_size = lot.packaging.label;
      else var packaging_size = lot.packaging.size;

      if (lotLabel !== "") lotLabel += " - ";
      if (lotShortLabel !== "") lotShortLabel += ' - ';
      lotLabel += packaging_size;
      lotShortLabel += packaging_size;
    } else {
      if (lotShortLabel !== "") lotShortLabel += ' - ';
      lotShortLabel += (lot.variety && lot.variety.id > 0) ? lot.variety.label : '';
    }

    return !short ? lotLabel : lotShortLabel;
  }

  const updateLot = (id_lot, id_receiver, data) => {
    setRowsReceiver(rowsReceiver.map((item) => {
      if (item.id == id_receiver) {
        item.lots = item.lots.map((lot) => {
          if (lot.id == id_lot) {
            return data;
          }
          return lot;
        });
      }
      return item;
    }));
  }

  //function treatment
  //check if field is a controlled field & required
  async function requiredFieldsReceiver() {
    var checkWorkflowData = true;
    controlFieldsMapping.map((item) => {
      //check field is a controlled field
      var fieldControl = controlFields.findIndex(obj => obj.id.toUpperCase() === item.toUpperCase());
      if (fieldControl >= 0) {
        //check if required
        if (isRequired(item)) {
          checkWorkflowData = false;
        }
      }
    });

    return checkWorkflowData;
  }

  async function checkWorkflowRequiredFieldsReceiver() {
    if (workflowLoaded && rowsReceiver.length <= 0 && !!idButtonWorkflow != false) {
      var checkWorkflowData = await requiredFieldsReceiver();
      //init false not valid workflow
      if (!checkWorkflowData) {
        setCheckWorkflowData(checkWorkflowData);
        triggerInfosPopin("Receiver required", "You must enter a receiver.", "error");
      }
    }
  }

  const getDataSaveMissionDetailsReceiver = async (receiver) => {
    var data = new FormData();
    if (receiver) {
      var id = receiver.id;
      data.append('id_mission', Number(idMission));
      if (id.toString().length < 16) data.append('id_mission_receiver', id);

      if (canWrite('id_receiver')) {
        if (receiver.receiver?.id && receiver.receiver.id != "0")
          data.append('id_receiver', receiver.receiver.id);
        else data.append('id_receiver', null);
      }
      if (canWrite('r.tonnage')) {
        if (receiver.tonnage) data.append('tonnage', receiver.tonnage);
        else data.append('tonnage', null);
      }
      if (canWrite('id_cargo')) {
        if (receiver.cargo?.id && receiver.cargo.id != "0")
          data.append('id_cargo', receiver.cargo.id);
        else data.append('id_cargo', null);
      }
      if (canWrite('id_mission_ending')) {
        if (receiver.mission_ending?.id && receiver.mission_ending.id != "0")
          data.append('id_mission_ending', receiver.mission_ending.id);
        else data.append('id_mission_ending', null);
      }
    }

    return data;
  }

  const getDataCheckMissionDetailsReceiver = async () => {
    var dataReceiver = [];
    for (var i = 0; i < rowsReceiver.length; i++) {
      if (rowsReceiver[i].receiver) {
        var data = await getDataSaveMissionDetailsReceiver(rowsReceiver[i]);
        var id = rowsReceiver[i].id;

        if (canWrite('id_receiver')) {
          dataReceiver.push({ key: 'id_receiver', id: id, val: rowsReceiver[i].receiver.id });
        }
        if (canWrite('r.tonnage')) {
          dataReceiver.push({ key: 'r.tonnage', id: id, val: data.get('tonnage') });
        }
        if (canWrite('id_cargo')) {
          dataReceiver.push({ key: 'id_cargo', id: id, val: data.get('id_cargo') });
        }
        if (canWrite('id_mission_ending')) {
          dataReceiver.push({ key: 'id_mission_ending', id: id, val: data.get('id_mission_ending') });
        }
      }
    }

    return dataReceiver;
  }

  const getDataSaveMissionDetailsReceiverLot = async (receiverLot, receiver) => {
    var data = new FormData();
    if (receiverLot) {
      data.append('id_mission', Number(idMission));
      data.append('id_mission_receiver', receiver.id);

      var id = receiverLot.id;
      if (id.toString().length < 16) data.append('id_mission_receiver_lot', id);

      if (canWrite('id_loading_type')) {
        if (receiverLot.loading_type?.id && receiverLot.loading_type.id != "0")
          data.append('id_loading_type', receiverLot.loading_type.id);
        else data.append('id_loading_type', null);
      }
      if (canWrite('id_packaging')) {
        if (receiverLot.packaging?.id && receiverLot.packaging.id != "0")
          data.append('id_packaging', receiverLot.packaging.id);
        else data.append('id_packaging', null);
      }
      if (canWrite('id_cargo_origin')) {
        if (receiverLot.origin?.id && receiverLot.origin.id != "0")
          data.append('id_cargo_origin', receiverLot.origin.id);
        else data.append('id_cargo_origin', null);
      }
      if (canWrite('id_cargo_brand')) {
        if (receiverLot.brand?.id && receiverLot.brand.id != "0")
          data.append('id_cargo_brand', receiverLot.brand.id);
        else
          data.append('id_cargo_brand', null);
      }
      if (canWrite('id_cargo_quality')) {
        if (receiverLot.quality?.id && receiverLot.quality.id != "0")
          data.append('id_cargo_quality', receiverLot.quality.id);
        else data.append('id_cargo_quality', null);
      }
      if (canWrite('id_cargo_variety')) {
        if (receiverLot.variety?.id && receiverLot.variety.id != "0")
          data.append('id_cargo_variety', receiverLot.variety.id);
        else data.append('id_cargo_variety', null);
      }
      if (canWrite('packaging_quantity')) {
        if (receiverLot.packaging_quantity) data.append('packaging_quantity', receiverLot.packaging_quantity);
        else data.append('packaging_quantity', null);
      }
      if (canWrite('net_tonnage')) {
        if (receiverLot.net_tonnage) data.append('net_tonnage', receiverLot.net_tonnage);
        else data.append('net_tonnage', null);
      }
      if (canWrite('gross_tonnage')) {
        if (receiverLot.gross_tonnage) data.append('gross_tonnage', receiverLot.gross_tonnage);
        else data.append('gross_tonnage', null);
      }
      if (canWrite('empty_spare_packaging_percentage')) {
        if (receiverLot.empty_spare_packaging_percentage) data.append('empty_spare_packaging_percentage', receiverLot.empty_spare_packaging_percentage);
        else data.append('empty_spare_packaging_percentage', null);
      }
      if (canWrite('number_of_container')) {
        if (receiverLot.number_of_container) data.append('number_of_container', receiverLot.number_of_container);
        else data.append('number_of_container', null);
      }
      if (canWrite('container_type')) {
        if (receiverLot.container_type) {
          data.append('container_type', receiverLot.container_type);
          if (receiverLot.container_type == 0) data.append('container_type', null);
        } else {
          data.append('container_type', null);
        }
      }
      if (canWrite('quantity_of_packing_per_container')) {
        if (receiverLot.quantity_of_packing_per_container) data.append('quantity_of_packing_per_container', receiverLot.quantity_of_packing_per_container);
        else data.append('quantity_of_packing_per_container', null);
      }

      //BLs
      if (canWrite('bills_of_lading')) {
        var bls = [];
        for (var j = 0; j < receiverLot.bills_of_lading.length; j++) {
          if (receiverLot.bills_of_lading[j].id.toString().length < 16) {
            bls.push(receiverLot.bills_of_lading[j].id);
          }
        }
        data.append('bills_of_lading[]', bls);
      }

      //Companies/Stevedores
      if (canWrite('companies_stevedore')) {
        var companies = [];
        for (var j = 0; j < receiverLot.companies.length; j++) {
          if (receiverLot.companies[j].id.toString().length < 16) {
            companies.push(receiverLot.companies[j].id);
          }
        }
        data.append('companies[]', companies);
      }
    }

    return data;
  }

  const getDataCheckMissionDetailsReceiverLot = async () => {
    var dataReceiverLot = [];
    for (var k = 0; k < rowsReceiver.length; k++) {
      if (rowsReceiver[k].receiver) {
        var receiver = rowsReceiver[k];

        for (var i = 0; i < receiver.lots.length; i++) {
          var data = await getDataSaveMissionDetailsReceiverLot(receiver.lots[i], receiver);
          var id = receiver.lots[i].id;

          if (canWrite('id_loading_type')) {
            dataReceiverLot.push({ key: 'id_loading_type', id: id, val: data.get('id_loading_type') });
          }
          if (canWrite('id_packaging')) {
            //si loading type has packaging
            if (receiver.lots[i].loading_type && receiver.lots[i].loading_type.has_packaging == 1)
              dataReceiverLot.push({ key: 'id_packaging', id: id, val: data.get('id_packaging') });
          }
          if (canWrite('id_cargo_origin')) {
            //si loading type has packaging
            if (receiver.lots[i].loading_type && receiver.lots[i].loading_type.has_packaging == 1)
              dataReceiverLot.push({ key: 'id_cargo_origin', id: id, val: data.get('id_cargo_origin') });
          }
          if (canWrite('id_cargo_brand')) {
            dataReceiverLot.push({ key: 'id_cargo_brand', id: id, val: data.get('id_cargo_brand') });
          }
          if (canWrite('id_cargo_quality')) {
            dataReceiverLot.push({ key: 'id_cargo_quality', id: id, val: data.get('id_cargo_quality') });
          }
          if (canWrite('id_cargo_variety')) {
            dataReceiverLot.push({ key: 'id_cargo_variety', id: id, val: data.get('id_cargo_variety') });
          }
          if (canWrite('packaging_quantity')) {
            //si loading type has packaging
            if (receiver.lots[i].loading_type && receiver.lots[i].loading_type.has_packaging == 1)
              dataReceiverLot.push({ key: 'packaging_quantity', id: id, val: data.get('packaging_quantity') });
          }
          if (canWrite('net_tonnage')) {
            dataReceiverLot.push({ key: 'net_tonnage', id: id, val: data.get('net_tonnage') });
          }
          if (canWrite('gross_tonnage')) {
            dataReceiverLot.push({ key: 'gross_tonnage', id: id, val: data.get('gross_tonnage') });
          }
          if (canWrite('empty_spare_packaging_percentage')) {
            //si loading type has packaging
            if (receiver.lots[i].loading_type && receiver.lots[i].loading_type.has_packaging == 1)
              dataReceiverLot.push({ key: 'empty_spare_packaging_percentage', id: id, val: data.get('empty_spare_packaging_percentage') });
          }
          if (canWrite('number_of_container')) {
            //si loading type has packaging
            if (receiver.lots[i].loading_type && receiver.lots[i].loading_type.has_packaging == 1)
              dataReceiverLot.push({ key: 'number_of_container', id: id, val: data.get('number_of_container') });
          }
          if (canWrite('container_type')) {
            //si loading type has packaging
            if (receiver.lots[i].loading_type && receiver.lots[i].loading_type.has_packaging == 1)
              dataReceiverLot.push({ key: 'container_type', id: id, val: data.get('container_type') });
          }
          if (canWrite('quantity_of_packing_per_container')) {
            dataReceiverLot.push({ key: 'quantity_of_packing_per_container', id: id, val: data.get('quantity_of_packing_per_container') });
          }
        }
      }
    }

    return dataReceiverLot;
  }

  const checkWorkflowMissionDetailsReceiver = async () => {
    //CHECK WORKFLOW DATA
    await controlMultipleFieldsData(await getDataCheckMissionDetailsReceiver());
  };

  const checkWorkflowMissionDetailsReceiverLot = async () => {
    //CHECK WORKFLOW DATA
    await controlMultipleFieldsData(await getDataCheckMissionDetailsReceiverLot());
  };

  const saveReceivers = async (id_button = null, justBlock = false) => {
    if (justBlock) {
      setLoading(true);
      setLoadingUpdate(false);
    }

    try {
      //check & alert
      await checkWorkflowRequiredFieldsReceiver();

      var saveReceiverPromises = [];
      for (var i = 0; i < rowsReceiver.length; i++) {
        if (rowsReceiver[i].receiver) {
          var data = await getDataSaveMissionDetailsReceiver(rowsReceiver[i]);
          var id = rowsReceiver[i].id;

          //check data required
          var receiverSaved = false;
          if ( data.get('id_receiver') != "0" && data.get('id_cargo') != "0" && data.get('id_mission_ending') != "0") {
            var jsonRequestDataReceiver = JSON.stringify(Object.fromEntries(data));
            var jsonRequestDataFinal = jsonRequestDataReceiver.replace(/"null"/g, 'null');

            var methode = 'post';
            if (id.toString().length < 16) methode = 'put';

            var config = {
              url: 'mission/receiver',
              method: methode,
              headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                'Content-Type': 'application/json'
              },
              data: jsonRequestDataFinal,
            };

            const response = await axios(config);
            saveReceiverPromises.push(response);
            if (rowsReceiver[i].id.toString().length > 16) {
              rowsReceiver[i].id = response.data.data.id;
            }

            receiverSaved = true;
          }

          await saveLots(rowsReceiver[i], receiverSaved, id_button);
        }
      }

      await Promise.all(saveReceiverPromises);

      //CHECK WORKFLOW DATA
      await checkWorkflowMissionDetailsReceiver();

      if (justBlock) {
        setSnackBarMessage("Receivers saved.");
        setSnackBarType("success");
        setOpenSnackBar(true);
        //reload data api
        await getMissionDetail();
      } else {
        setDoneBlockSave(receiverRef);
      }
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        if (justBlock) {
          var errorMsg = (!!err.response != false ? setSnackErrorMsg(err.response.data) : "");
          setSnackBarMessage(errorMsg);
          setSnackBarType("error");
          setOpenSnackBar(true);
        } else {
          setErrorBlockSave(receiverRef);
        }
      }
    } finally {
      if (justBlock) {
        setLoading(false);
        setLoadingUpdate(true);
      }
    }
  }

  const deleteReceivers = async () => {
    var delReceiversPromises = [];
    for (var i = 0; i < rowsReceiverDeleted.length; i++) {
      var data = new FormData();
      data.append('id_mission', Number(idMission));
      data.append('id_mission_receiver', rowsReceiverDeleted[i].id);

      var jsonRequestDataReceiver = JSON.stringify(Object.fromEntries(data));

      var config = {
        url: 'mission/receiver',
        method: 'delete',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        },
        data: jsonRequestDataReceiver,
      };

      const response = await axios(config);
      delReceiversPromises.push(response);
    }
    setRowsReceiverDeleted([]);
    await Promise.all(delReceiversPromises);
  }

  const saveLots = async (receiver, receiverSaved, id_button) => {
    var saveLotsPromises = [];
    if (workflowLoaded && receiver.lots.length <= 0 && !!id_button != false) {
      var checkWorkflowData = requiredFieldsReceiver();
      //init false not valid workflow
      if (!checkWorkflowData) {
        setCheckWorkflowData(checkWorkflowData);
        triggerInfosPopin("Receiver required", "You must enter a receiver's lot", "error");
      }
    }
    for (var i = 0; i < receiver.lots.length; i++) {
      var data = await getDataSaveMissionDetailsReceiverLot(receiver.lots[i], receiver);
      var id = receiver.lots[i].id;

      //check data receiver required
      if (receiverSaved) {
        var jsonRequestDataReceiver = JSON.stringify(Object.fromEntries(data));
        var jsonRequestDataFinal = jsonRequestDataReceiver.replace(/"null"/g, 'null');

        var methode = 'post';
        if (id.toString().length < 16) methode = 'put';

        var config = {
          url: 'mission/receiver/lot',
          method: methode,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json'
          },
          data: jsonRequestDataFinal,
        };
        const response = await axios(config);
        saveLotsPromises.push(response);
        if (id.toString().length > 16) {
          receiver.lots[i].id = response.data.data.id;
          updateLot(receiver.lots[i].id, receiver.id, receiver.lots[i]);
        }
      }
    }

    await Promise.all(saveLotsPromises);

    //CHECK WORKFLOW DATA
    await checkWorkflowMissionDetailsReceiverLot();
  }

  const deleteLots = async () => {
    var delLotsPromises = [];
    for (var i = 0; i < rowsLotDeleted.length; i++) {
      var data = new FormData();
      data.append('id_mission', Number(idMission));
      data.append('id_mission_receiver', rowsLotDeleted[i].id_receiver);
      data.append('id_mission_receiver_lot', rowsLotDeleted[i].id);

      var jsonRequestDataReceiver = JSON.stringify(Object.fromEntries(data));

      var config = {
        url: 'mission/receiver/lot',
        method: 'delete',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        },
        data: jsonRequestDataReceiver,
      };

      const response = await axios(config);
      delLotsPromises.push(response)
    }
    setRowsLotDeleted([]);
    await Promise.all(delLotsPromises);
  }

  async function controlFieldsErrorTruncate() {
    if (controlFieldsError.length > 0) {
      var fieldsError = structuredClone(controlFieldsError);
      controlFieldsMapping.map((fields) => {
        var items = controlFieldsError.filter(obj => obj.id.toUpperCase().substr(0, fields.length) === fields.toUpperCase());
        if (items.length > 0) {
          items.map((item) => {
            if (fieldsError.length > 0) {
              fieldsError = fieldsError.filter(obj => obj.id.toUpperCase() !== item.id.toUpperCase());
            }
          });
        }
      });
      if (fieldsError.length != controlFieldsError.length) {
        setControlFieldsError(fieldsError);
      }
    }
  }


  return (
    <ContextMissionDetailsReceiver.Provider
      value=
      {{
        rowsReceiverView, setRowsReceiverView,
        rowsReceiverDeleted, setRowsReceiverDeleted,
        rowsLotDeleted, setRowsLotDeleted,

        importCRData, setImportCRData,
        importPreviousCRData, setImportPreviousCRData,
        generateStatusCR, setGenerateStatusCR,

        updateLot, generateLotLabel,
        saveReceivers, deleteReceivers, deleteLots,

        handleClickCancel
      }}
    >
      {children}
    </ContextMissionDetailsReceiver.Provider>
  );
};

export { MissionDetailsReceiverProvider, ContextMissionDetailsReceiver };
