import React, { useState, useEffect, forwardRef, useContext, Children } from 'react';

import { randomId } from '@mui/x-data-grid-generator';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { isInternalRole, isPortRole } from 'components/config/Roles';
import SelectAutoCustom from 'components/select/SelectAutoCustom';
import SelectCustom from 'components/select/SelectCustom';
import TextFieldCustom from 'components/select/TextFieldCustom';

import MissionDetailsReceiverFormLot from './MissionDetailsReceiverFormLot';
import BlockFormSaveButton from '../../../Block/BlockFormSaveButton';

import { ContextBlock } from "context/ContextBlock";
import { ContextMissionDetails } from "context/ContextMissionDetails";
import { ContextMissionWorkflow } from "context/ContextMissionWorkflow";
import { ContextMissionDetailsReceiver } from "./ContextMissionDetailsReceiver";
import { ContextMissionDetailsReceiverBL } from "../ContextMissionDetailsReceiverBL";

import 'styles/Receivers.css';


const MissionDetailsReceiverForm = forwardRef((props, ref) => {

  //init config
  const {receiverRef} = useContext(ContextMissionDetails);
  const [PortMode] = useState(isPortRole(localStorage.getItem("role")));
  const [JLBInternalMode] = useState(isInternalRole(localStorage.getItem("role")));

  //workflow state
  const {canRead} = useContext(ContextMissionWorkflow);
  const {canWrite} = useContext(ContextMissionWorkflow);
  const {isRequired} = useContext(ContextMissionWorkflow);
  const {getControlFieldsErrorMsg} = useContext(ContextMissionWorkflow);
  const {getControlFieldsErrorState} = useContext(ContextMissionWorkflow);
  const {controlFieldsError, setControlFieldsError} = useContext(ContextMissionWorkflow);
  const {workflowLoaded, setWorkflowLoaded} = useContext(ContextMissionWorkflow);

  //init mission
  const {idMission, setIdMission} = useContext(ContextMissionDetails);

  //init receiver data
  const {rowsReceiver, setRowsReceiver} = useContext(ContextMissionDetailsReceiverBL);
  const {rowsReceiverDeleted, setRowsReceiverDeleted} = useContext(ContextMissionDetailsReceiver);
  const {rowsLotDeleted, setRowsLotDeleted} = useContext(ContextMissionDetailsReceiver);
  const {generateLotLabel} = useContext(ContextMissionDetailsReceiver);
  const [errorReceiver, setErrorReceiver] = useState(false);
  const [expandedReceivers, setExpandedReceivers] = useState([]);

  //init BL data
  const {rowsBL, setRowsBL} = useContext(ContextMissionDetailsReceiverBL);
  const {newRowsBL, setNewRowsBL} = useContext(ContextMissionDetailsReceiverBL);
  const {deletedRowsBL, setDeletedRowsBL} = useContext(ContextMissionDetailsReceiverBL);

  //handle
  const {saveReceivers} = useContext(ContextMissionDetailsReceiver);
  const {deleteReceivers} = useContext(ContextMissionDetailsReceiver);
  const {handleClickCancel} = useContext(ContextMissionDetailsReceiver);
  const {deleteLots} = useContext(ContextMissionDetailsReceiver);
  const handleClickSave = async () => {
    //Remove focus from any focused element
    if (document.activeElement) {
      document.activeElement.blur();
    }
    //saving
    await deleteReceivers();
    await deleteLots();
    await saveReceivers(null, true);
    await handleClickCancel();
  };


  //useEffect
  useEffect(() => {
    rowsReceiver.length >= 1 && rowsReceiver.map((item) => {
      if ((workflowLoaded && getControlFieldsErrorState("id_receiver" + item.id)) ||
        getControlFieldsErrorState("r.tonnage" + item.id) ||
        getControlFieldsErrorState("id_cargo" + item.id) ||
        getControlFieldsErrorState("id_mission_ending" + item.id)) {
        if (!expandedReceivers.includes(item.id)) {
          setExpandedReceivers((prevExpandedReceivers) => [...prevExpandedReceivers, item.id]);
        }
      }
    })
  }, [rowsReceiver]);


  //function interactive
  const { companySelectRefresh } = useContext(ContextBlock);
  const {updateLot} = useContext(ContextMissionDetailsReceiver);

  function createReceiversHandler(data) {
    return { id: data.id, label: data.name.toUpperCase().trim(), labelValue: data.name.toUpperCase().trim() };
  }

  function createCargoHandler(data) {
    return { id: data.id, label: data.label.toUpperCase().trim(), labelValue: data.label.toUpperCase().trim() };
  }

  function createMissionEndingHandler(data) {
    return { id: data.id, title: data.label.toUpperCase().trim(), alias: data.label, labelValue: data.label.toUpperCase().trim() };
  }
  
  const handleAddReceiver = () => {
    const id = randomId();
    setRowsReceiver((oldRows) => [...oldRows, { id: id, receiver: { name: "" }, lots: [], isExpanded: true }]);
  };

  const removeReceiver = (id) => {
    if (id.toString().length < 16) {
      setRowsReceiverDeleted((oldRowsBl) => [...oldRowsBl, { id: id }]);
    }
    setRowsReceiver(rowsReceiver.filter((receiver) => receiver.id !== id));
  }

  const setExpandedLots = (receiver, value) => {
    receiver.lots.length >= 1 && receiver.lots.map((lot, index) => {
      updateLot(
        lot.id,
        receiver.id,
        { ...lot, isExpanded: value }
      );
    });
  }

  const handleAddLot = (event) => {
    const id = randomId();
    setRowsReceiver(rowsReceiver.map((item) => {
      if (item.id == event.target.value) {
        //close all lot of same receiver
        setExpandedLots(item, false);
        //add lot
        item.lots.push({
          id: id,
          packaging: { id: 0, label: "", net_weight: 0 },
          origin: { id: 0, label: "" },
          loading_type: { id: 0, label: "", has_packaging: 0 },
          brand: { id: 0, label: "" },
          quality: { id: 0, label: "" },
          packaging_quantity: "",
          container_type: "",
          number_of_container: "",
          net_tonnage: "",
          gross_tonnage: "",
          empty_spare_packaging_percentage: "",
          bills_of_lading: [],
          companies: [],
          //new param for default extand
          isExpanded: true
        });
      }
      return item;
    }));
  }

  const removeLot = (id_lot, id_receiver) => {
    if (id_lot.toString().length < 16) {
      setRowsLotDeleted((oldRowsBl) => [...oldRowsBl, { id: id_lot, id_receiver: id_receiver }]);
    }
    setRowsReceiver(rowsReceiver.map((item) => {
      if (item.id == id_receiver) {
        item.lots = item.lots.filter((lot) => lot.id !== id_lot);
      }
      return item;
    }));
  }

  const duplicateLot = (id_lot, id_receiver) => {
    const id = randomId();
    setRowsReceiver(rowsReceiver.map((item) => {
      if (item.id == id_receiver) {
        //select element to duplicate
        var lot_duplicated;
        item.lots.map((lot) => {
          if (lot.id == id_lot) {
            lot_duplicated = lot;
          }
        });
        //add new element
        item.lots.push({
          id: id,
          packaging: lot_duplicated.packaging,
          origin: lot_duplicated.origin,
          loading_type: lot_duplicated.loading_type,
          brand: lot_duplicated.brand,
          quality: lot_duplicated.quality,
          variety: lot_duplicated.variety,
          packaging_quantity: lot_duplicated.packaging_quantity,
          container_type: lot_duplicated.container_type,
          number_of_container: lot_duplicated.number_of_container,
          net_tonnage: lot_duplicated.net_tonnage,
          gross_tonnage: lot_duplicated.gross_tonnage,
          empty_spare_packaging_percentage: lot_duplicated.empty_spare_packaging_percentage,
          bills_of_lading: [],
          companies: lot_duplicated.companies,
          //new param for default extand
          isExpanded: true,
          isDuplicate: true
        });
      }
      return item;
    }));
  }

  const handleChangeReceiver = (id, label, labelValue, target) => {
    setRowsReceiver(rowsReceiver.map((item) => {
      if (item.id === target.id) {
        return { ...item, receiver: { id: id, name: label } };
      }
      return item;
    }));
  }

  const tonnageHandler = (event) => {
    var receiver_id = getRowIdFromInput(event.target.id);
    setRowsReceiver(rowsReceiver.map((item) => {
      if (item.id == receiver_id) {
        return { ...item, tonnage: event.target.value };
      }
      return item;
    }));
  }

  const cargoChangeHandler = (id, label, labelValue, target) => {
    setRowsReceiver(rowsReceiver.map((item) => {
      if (item.id === target.id) {
        return { ...item, cargo: { id: id, label: label } };
      }
      return item;
    }));
  }

  const missionEndingChangeHandler = (id, label, target) => {
    setRowsReceiver(rowsReceiver.map((item) => {
      if (item.id === target.id) {
        return { ...item, mission_ending: { id: id, label: label } };
      }
      return item;
    }));
  }

  const getRowIdFromInput = (inputId) => {
    var str = inputId.split("_");
    return str[1];
  }

  //function for change state of expand
  const handleExpand = (itemId) => () => {
    if (expandedReceivers.includes(itemId)) {
      setExpandedReceivers(prevExpandedReceivers => prevExpandedReceivers.filter(id => id !== itemId));
    } else {
      setExpandedReceivers(prevExpandedReceivers => [...prevExpandedReceivers, itemId]);
    }
  };

  return (
    <div>
      {(Boolean(canWrite("receiver_block"))) && (
        <Box sx={{ '& > button': { m: 1 } }}>
          <LoadingButton
            onClick={handleAddReceiver}
            loadingIndicator="Adding Receiver"
            variant="outlined"
          >
            Add Receiver
          </LoadingButton>
        </Box>
      )}

      <div className="blocReceiverDiv">
        {rowsReceiver.length >= 1 && Children.toArray(rowsReceiver.map((item, index) => {
          //generate index
          var index_receiver = '';
          if (item.receiver && !!item.receiver.name != false) index_receiver += item.receiver.name;
          if (item.cargo && !!item.cargo.label != false) {
            if (index_receiver !== '') index_receiver += " - ";
            index_receiver += item.cargo.label;
          }

          return (
            <div className="receiverDiv">
              <Accordion className="receiverAccordion" expanded={expandedReceivers.includes(item.id)} onChange={handleExpand(item.id)}>
                <AccordionSummary className="receiverAccordionSummary" expandIcon={<ExpandMoreIcon />}>
                  {(canWrite("receiver_block")) ? (
                    <IconButton aria-label="delete" onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      removeReceiver(item.id);
                    }}>
                      <DeleteIcon />
                    </IconButton>
                  ) : <div className="receiverIconSpacer"></div>}
                  <div className="receiverNameDiv">{index_receiver !== '' ? index_receiver : "New Receiver"}</div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="receiverFieldsDiv">
                    <SelectAutoCustom
                      title="Receiver"
                      api="company/list"
                      apiParam="company_role"
                      apiParamValue="Receiver"
                      dataValue={item.receiver ? item.receiver.name : ""}
                      targetItem={item}
                      setDataValue={handleChangeReceiver}
                      defaultAlias=""
                      creator={createReceiversHandler}
                      enableAddButton={JLBInternalMode || PortMode}
                      companySelectRefresh={companySelectRefresh}
                      classType="addButtonSelect2"
                      apiAddButton="company"
                      field4={
                        {
                          componentName: "CompanyForm",
                          componentClass: "companyFormClass",
                          buttonDisableControls: true,
                          thirdButton:
                            { label: "Save & Add Contact", url: "/updateCompany?id={id}&key=", focusElementId: "contactFormId" },
                          initValues:
                            { principals: false, trader: false, insured: false, receiver: true, warehouseKeeper: false, stevedore: false },
                        }}
                      cached={true}
                      cacheTTL="600"
                      resetCache="1"

                      read={canRead("id_receiver")}
                      write={canWrite("id_receiver")}
                      required={isRequired("id_receiver")}
                      controlFieldsStatus={workflowLoaded}
                      error={(workflowLoaded ? getControlFieldsErrorState("id_receiver" + item.id) : errorReceiver)}
                      helperText={getControlFieldsErrorMsg("id_receiver" + item.id)}
                    />

                    <TextFieldCustom
                      id={"tonnage_" + item.id}
                      label="Tonnage"
                      variant="standard"
                      value={item.tonnage ? item.tonnage : ""}
                      onChange={tonnageHandler}
                      type="decimal"

                      read={canRead("r.tonnage")}
                      write={canWrite("r.tonnage")}
                      required={isRequired("r.tonnage")}
                      controlFieldsStatus={workflowLoaded}
                      error={getControlFieldsErrorState("r.tonnage" + item.id)}
                      helperText={getControlFieldsErrorMsg("r.tonnage" + item.id)}
                    />

                    <SelectAutoCustom
                      title="Cargo"
                      api="cargo/list"
                      apiParam=""
                      apiParamValue=""
                      dataValue={item.cargo ? item.cargo?.label?.toUpperCase().trim() : ""}
                      targetItem={item}
                      setDataValue={cargoChangeHandler}
                      setDataAlias=""
                      defaultAlias=""
                      creator={createCargoHandler}
                      enableAddButton={JLBInternalMode || PortMode}
                      classType="addButtonSelect2"
                      apiAddButton="cargo"
                      field1={{ field: "label", label: "Label", required: true }}
                      cached={true}
                      cacheTTL="600"
                      resetCache="1"

                      read={canRead("id_cargo")}
                      write={canWrite("id_cargo")}
                      required={isRequired("id_cargo")}
                      controlFieldsStatus={workflowLoaded}
                      error={getControlFieldsErrorState("id_cargo" + item.id)}
                      helperText={getControlFieldsErrorMsg("id_cargo" + item.id)}
                    />

                    <SelectCustom
                      title="Mission Ending"
                      api="mission_ending/list"
                      apiParam=""
                      apiParamValue=""
                      dataValue={item.mission_ending ? item.mission_ending.id : ""}
                      targetItem={item}
                      setDataValue={missionEndingChangeHandler}
                      setDataAlias=""
                      defaultAlias=""
                      creator={createMissionEndingHandler}
                      cached={true}

                      read={canRead("id_mission_ending")}
                      write={canWrite("id_mission_ending")}
                      required={isRequired("id_mission_ending")}
                      controlFieldsStatus={workflowLoaded}
                      error={getControlFieldsErrorState("id_mission_ending" + item.id)}
                      helperText={getControlFieldsErrorMsg("id_mission_ending" + item.id)}
                    />

                    {(Boolean(canWrite("receiver_block"))) && (
                      <LoadingButton sx={{ top: "8px" }}
                        onClick={handleAddLot}
                        loadingIndicator="Adding Lot"
                        variant="outlined"
                        value={item.id}
                        className="addLotButton"
                      >
                        Add Lot
                      </LoadingButton>
                    )}
                  </div>

                  <div className="blocReceiverLotDiv">
                    {//Lots
                      item.lots.length >= 1 && Children.toArray(item.lots.map((lot, index) => {
                        //generate index
                        var index_lot = generateLotLabel(lot);
                        //if not value exist then default autoincrement
                        if (index_lot === '') index_lot = index;

                        return (
                          <MissionDetailsReceiverFormLot
                            rowsBL={rowsBL}
                            newRowsBL={newRowsBL}
                            deletedRowsBL={deletedRowsBL}
                            
                            lot={lot}
                            index={index_lot}
                            receiver={item}
                            updateLot={updateLot}
                            removeLot={removeLot}
                            duplicateLot={duplicateLot}
                            isExpanded={lot.isExpanded !== undefined ? lot.isExpanded : false}
                            expandParent={setExpandedReceivers}
                            expandedReceivers={expandedReceivers}

                            canRead={canRead}
                            canWrite={canWrite}
                            isRequired={isRequired}
                            workflowLoaded={workflowLoaded}
                            getControlFieldsErrorState={getControlFieldsErrorState}
                            getControlFieldsErrorMsg={getControlFieldsErrorMsg}
                            controlFieldsError={controlFieldsError}
                          />
                        )
                      }))
                    }
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          );
        }))}
      </div>

      <BlockFormSaveButton blockRef={receiverRef} blockCancel={async ()=> await handleClickCancel()} blockSave={handleClickSave}/>

    </div>
  );
});

export default MissionDetailsReceiverForm;
