import React, { useState, forwardRef, useContext, useEffect, GridRenderEditCellParams } from 'react';
import { useNavigate } from "react-router-dom";

import momentDate from 'moment';

import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import Skeleton from '@mui/material/Skeleton';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import DeleteIcon from '@mui/icons-material/Delete';
import { randomId } from '@mui/x-data-grid-generator';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import { useGridApiRef, DataGridPro, GridToolbarContainer, DataGridListValueFormatterCustom, DataGridRenderCellEditableCustom, DataGridDatePickerCustom, DataGridTextCustom, DataGridSelectCustom } from 'components/page/object/DataGridProCustom';

import { ContextBlock } from "context/ContextBlock";
import { ContextShippingInstruction } from "context/ContextShippingInstruction";
import { ContextShippingInstructionBL } from "./ContextShippingInstructionBL";
import { ContextShippingInstructionMission } from "../Mission/ContextShippingInstructionMission";

import axios from 'api/axios';

import "styles/dataGrid.css";


const ShippingInstructionBLForm = forwardRef((props, ref) => {

  //init config
  const navigate = useNavigate();
  const dataGridRef = useGridApiRef();
  const { blRef } = useContext(ContextShippingInstruction);

  //generic
  const { setSnackErrorMsg } = useContext(ContextBlock);
  const { openSnackBar, setOpenSnackBar } = useContext(ContextBlock);
  const { snackBarType, setSnackBarType } = useContext(ContextBlock);
  const { snackBarMessage, setSnackBarMessage } = useContext(ContextBlock);

  const { setFieldErrorBlockGrid } = useContext(ContextBlock);

  //info popin
  const { triggerInfosPopin } = useContext(ContextBlock);

  //SI BL Grid
  const { rowsMissions, setRowsMissions } = useContext(ContextShippingInstructionMission);

  const { rowsSiBls, setRowsSiBls } = useContext(ContextShippingInstructionBL);
  const { rowsSiBlsDeleted, setRowsSiBlsDeleted } = useContext(ContextShippingInstructionBL);

  //context
  const { handleRowEditStop } = useContext(ContextBlock);
  const { handleRowEditStart } = useContext(ContextBlock);
  const { CustomNoRowsOverlay } = useContext(ContextBlock);

  //list dataGrid
  const [rowsVessel, setRowsVessel] = useState([]);
  const [rowsBeansType, setRowsBeansType] = useState([]);
  const [rowsMissionList, setRowsMissionList] = useState([]);
  const [rowsTypesOfIntervention, setRowsTypesOfIntervention] = useState([]);


  //useEffect
  useEffect(() => {
    loadVessel();
    loadBeansType();
    loadMissionList();
    loadTypesOfIntervention();
  }, []);

  useEffect(() => {
    loadMissionList();
  }, [rowsMissions]);


  //function interactive
  const { processRowUpdateSiBL } = useContext(ContextShippingInstructionBL);

  const loadTypesOfIntervention = async () => {
    try {
      const config = {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      };
      const response = await axios.get('type_of_intervention/list', config);

      var rowsFromApi = [];
      for (var i = 0; i < response.data.length; i++) {
        rowsFromApi[i] = { value: response.data[i].id, label: response.data[i].label.toUpperCase().trim() };
      }
      setRowsTypesOfIntervention(rowsFromApi);
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      }
      else {
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    }
  }

  const loadBeansType = async () => {
    try {
      const config = {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      };
      const response = await axios.get('beans_type/list', config);

      var rowsFromApi = [];
      for (var i = 0; i < response.data.length; i++) {
        rowsFromApi[i] = { value: response.data[i].id, label: response.data[i].label.toUpperCase().trim() };
      }
      rowsFromApi.sort((a, b) => (a.labelValue > b.labelValue) ? 1 : -1);
      setRowsBeansType(rowsFromApi);
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      }
      else {
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    }
  }

  const loadVessel = async () => {
    try {
      const config = {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      };
      const response = await axios.get('vessel/list', config);

      var rowsFromApi = [];
      for (var i = 0; i < response.data.length; i++) {
        rowsFromApi[i] = { value: response.data[i].id, label: response.data[i].name.toUpperCase().trim() };
      }
      rowsFromApi.sort((a, b) => (a.labelValue > b.labelValue) ? 1 : -1);
      setRowsVessel(rowsFromApi);
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      }
      else {
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    }
  }

  const loadMissionList = async () => {
    if (rowsMissions.length > 0) {
      var rowsFromApi = [];
      for (var i = 0; i < rowsMissions.length; i++) {
        rowsFromApi[i] = { value: rowsMissions[i].id, label: rowsMissions[i].jlb_ref };
      }
      rowsFromApi.sort((a, b) => (a.labelValue > b.labelValue) ? 1 : -1);
      setRowsMissionList(rowsFromApi);
    }
  }

  const deleteRowSiBL = (event, id) => {
    setRowsSiBls(rowsSiBls.filter((row) => row.id !== id));
    if (id.toString().length < 16) setRowsSiBlsDeleted((oldRows) => [...oldRows, { id: id }]);
  }

  function EditToolbarSiBL(props) {
    const { setRowsSiBls } = props;

    const handleClick = () => {
      const id = randomId();
      setRowsSiBls((oldRows) => [...oldRows, {
        id: id,
        id_vessel: 0,
        id_si_report: 0,
        id_beans_type: 0,
        id_type_of_intervention: 0,

        deadline_date: '',
        delivery_date: '',
        bl_date: '',

        type_of_damage: '',
        quantity: '',
        tonnage: '',
        number: '',
        smv: '',
        note: '',

        missions: []
      }]);
      setFieldErrorBlockGrid(blRef, 'number', true);
    };

    return (
      <GridToolbarContainer className="DataGridToolbar">
        <div className="toolbarButton left">
          <Button className="iconAddButton" color="primary" startIcon={<AddIcon />} onClick={handleClick}>
            Add Bill of Lading
          </Button>
        </div>
      </GridToolbarContainer>
    );
  }


  //xGrid
  var listColumnSiBL = [];
  //mode edit
  if (props.editable) {
    listColumnSiBL.push({
      field: 'id', headerName: '', editable: false, sortable: false, width: 60,
      renderCell: (cellValues) => {
        return (
          <IconButton aria-label="delete" onClick={(event) => { deleteRowSiBL(event, cellValues.id) }}>
            <DeleteIcon />
          </IconButton>
        );
      }
    });
  }
  listColumnSiBL.push(
    { field: 'bl_date', headerName: 'Date', width: 160, sortable: true, type: "date", editable: props.editable,
      valueFormatter: params => { if (params?.value != null) return momentDate(params?.value).format("DD/MM/YYYY") },
      renderCell: (cellValues) => {
        return DataGridRenderCellEditableCustom(cellValues);
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridDatePickerCustom
            {...params}
            processRowUpdate={processRowUpdateSiBL}
          />
        )
      }
    },
    { field: 'number', headerName: 'BL Number *', sortable: false, minWidth: 120, editable: props.editable, required: true,
      valueParser: (value: GridCellValue, params: GridCellParams) => {
        return value.toString().toUpperCase();
      },
      renderCell: (cellValues) => {
        return DataGridRenderCellEditableCustom(cellValues);
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridTextCustom
            {...params}
            processRowUpdate={processRowUpdateSiBL}
            error={params.value == 0 ? true : false}
            required={true}
          />
        )
      },
      //check cell data after edit
      preProcessEditCellProps: async (params) => {
        const hasError = (params.props.value?.trim() === '' || params.props.value === null);
        setFieldErrorBlockGrid(blRef, 'number', hasError);
        //alert popin if error
        if (hasError) {
          triggerInfosPopin("Fields required", 'BL Number is required', "error");
        }
        return { ...params.props, error: hasError };
      }
    }, 
    { field: 'id_vessel', headerName: 'Vessel', sortable: false, type: 'singleSelect', width: 150, editable: props.editable,
      valueOptions: rowsVessel,
      valueFormatter: ({ id: rowId, value, field, api }) => {
        return DataGridListValueFormatterCustom(rowId, value, field, api);
      },
      renderCell: (cellValues) => {
        return DataGridRenderCellEditableCustom(cellValues);
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridSelectCustom
            {...params}
            processRowUpdate={processRowUpdateSiBL}
          />
        )
      }
    },
    { field: 'tonnage', headerName: 'Tonnage', width: 150, sortable: true, type: 'number', editable: props.editable,
      renderCell: (cellValues) => {
        return DataGridRenderCellEditableCustom(cellValues);
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridTextCustom
            {...params}
            type="decimal"
            processRowUpdate={processRowUpdateSiBL}
          />
        )
      }
    },
    { field: 'missions', headerName: 'Missions', sortable: false, type: 'singleSelect', width: 200, editable: props.editable,
      valueOptions: rowsMissionList,
      valueFormatter: () => {},
      renderCell: (cellValues) => {
        if (props.editable && ((Array.isArray(cellValues.value) && cellValues.value.length === 0) || cellValues.value == '')) {
          return (
            <Tooltip title="Double click to edit" placement="top">
              <Skeleton variant="text" sx={{ height: '40px', width: '100%' }} />
            </Tooltip>
          );
        } else if (Array.isArray(cellValues.value) && cellValues.value.length > 0) {
          var txt = '', prefix = '';
          for (var i = 0; i < cellValues.value.length; i++) {
            const option = rowsMissionList.find(obj => Number(obj.value) === Number(cellValues.value[i]));
            if (!!option !== false) {
              txt = txt + prefix + option.label;
              prefix = "\n\r";
            }
          }
          return (
            <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{txt}</span>} placement="right">
              <IconButton>
                <FormatListBulletedIcon />
              </IconButton>
            </Tooltip>
          );
        }
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridSelectCustom
            {...params}
            multiple={true}
            disableNone={true}
            keepCellEditMode={true}
            processRowUpdate={processRowUpdateSiBL}
          />
        )
      }
    },
    { field: 'id_type_of_intervention', headerName: 'Type of intervention', sortable: false, width: 150, type: "singleSelect", editable: props.editable,
      valueOptions: rowsTypesOfIntervention,
      valueFormatter: ({ id: rowId, value, field, api }) => {
        return DataGridListValueFormatterCustom(rowId, value, field, api);
      },
      renderCell: (cellValues) => {
        return DataGridRenderCellEditableCustom(cellValues);
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridSelectCustom
            {...params}
            processRowUpdate={processRowUpdateSiBL}
          />
        )
      }
    }, 
    { field: 'quantity', headerName: 'Cocoa - to be sold (kg)', sortable: false, width: 180, type: 'number', editable: props.editable,
      renderCell: (cellValues) => {
        return DataGridRenderCellEditableCustom(cellValues);
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridTextCustom
            {...params}
            type="decimal"
            processRowUpdate={processRowUpdateSiBL}
          />
        )
      }
    }, 
    { field: 'id_beans_type', headerName: 'Type of beans', sortable: false, width: 150, type: "singleSelect", editable: props.editable,
      valueOptions: rowsBeansType,
      valueFormatter: ({ id: rowId, value, field, api }) => {
        return DataGridListValueFormatterCustom(rowId, value, field, api);
      },
      renderCell: (cellValues) => {
        return DataGridRenderCellEditableCustom(cellValues);
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridSelectCustom
            {...params}
            processRowUpdate={processRowUpdateSiBL}
          />
        )
      }
    }, 
    { field: 'deadline_date', headerName: 'Deadline', sortable: false, width: 160, type: 'date', editable: props.editable,
      valueFormatter: params => { if (params?.value != null) return momentDate(params?.value).format("DD/MM/YYYY") },
      renderCell: (cellValues) => {
        return DataGridRenderCellEditableCustom(cellValues);
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridDatePickerCustom
            {...params}
            processRowUpdate={processRowUpdateSiBL}
          />
        )
      }
    }, 
    { field: 'delivery_date', headerName: 'Delivery (cocoa)', sortable: false, width: 160, type: 'date', editable: props.editable,
      valueFormatter: params => { if (params?.value != null) return momentDate(params?.value).format("DD/MM/YYYY") },
      renderCell: (cellValues) => {
        return DataGridRenderCellEditableCustom(cellValues);
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridDatePickerCustom
            {...params}
            processRowUpdate={processRowUpdateSiBL}
          />
        )
      }
    }, 
    { field: 'smv', headerName: 'SMV (EUR)', sortable: false, width: 160, type: 'number', editable: props.editable,
      renderCell: (cellValues) => {
        return DataGridRenderCellEditableCustom(cellValues);
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridTextCustom
            {...params}
            type="decimal"
            processRowUpdate={processRowUpdateSiBL}
          />
        )
      }
    }, 
    { field: 'note', headerName: 'Comment', sortable: false, width: 200, editable: props.editable,
      renderCell: (cellValues) => {
        return DataGridRenderCellEditableCustom(cellValues);
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <DataGridTextCustom
            {...params}
            fullWidth
            multiline
            processRowUpdate={processRowUpdateSiBL}
          />
        )
      }
    }
  );
  const columnsSiBL: GridColDef[] = listColumnSiBL;


  return (
    <div className="lineContent noJustify">
      <FormControl sx={{ m: 1, width: '100%' }} size="small" id="datagrid-si-bl">
        <DataGridPro
          apiRef={dataGridRef}
          disableSelectionOnClick
          disableColumnMenu
          disableColumnFilter
          autoHeight
          hideFooter={true}
          rows={rowsSiBls}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdateSiBL}
          columns={columnsSiBL}
          experimentalFeatures={{ newEditingApi: true }}
          componentsProps={{
            toolbar: { setRowsSiBls },
          }}
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
            Toolbar: props.editable ? EditToolbarSiBL : null,
          }}
        />
      </FormControl>
    </div>
  );
});

export default ShippingInstructionBLForm;
