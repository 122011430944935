import React, { useEffect, SyntheticEvent } from 'react';
import SelectAutoCustom from 'components/select/SelectAutoCustom';
import LoadingButton from '@mui/lab/LoadingButton';
import { randomId } from '@mui/x-data-grid-generator';
import BLDateRow from './MissionDetailsBLDateRow';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function SignaturePlaceRow(props) {

  const [signaturePlace, setSignaturePlace] = React.useState({ id: "" });
  const [rowsBLDate, setRowsBLDate] = React.useState([]);
  const [countries, setCountries] = React.useState([]);
  //new param for default extand
  const [expanded, setExpanded] = React.useState((!!props.signaturePlace.isExpanded != false ? props.signaturePlace.isExpanded : props.isExpanded));

  useEffect(() => {
    setSignaturePlace(props.signaturePlace);
    setBLDates();
  }, [props.signaturePlace]);

  useEffect(() => {
    setCountries(props.countries);
  }, [props.countries]);

  function setBLDates() {
    var blDates = [];
    if (props.signaturePlace.bl_rows) {
      for (var i = 0; i < props.signaturePlace.bl_rows.length; i++) {
        var exists = blDates.find(dateRow => {
          return dateRow.value === props.signaturePlace.bl_rows[i].bl_date;
        });
        if (!exists) {
          blDates.push({
            id: randomId(),
            value: props.signaturePlace.bl_rows[i].bl_date,
            bl_rows: [props.signaturePlace.bl_rows[i]],
            isExpanded: (!!props.signaturePlace.bl_rows[i].isExpanded != false ? props.signaturePlace.bl_rows[i].isExpanded : false)
          });
        } else {
          exists.bl_rows.push(props.signaturePlace.bl_rows[i]);
          if (!!props.signaturePlace.bl_rows[i].isExpanded != false && props.signaturePlace.bl_rows[i].isExpanded != false) {
            exists.isExpanded = true;
          }
        }
      }
    }
    setRowsBLDate(blDates);
  }

  function createSelectItem(datas) {
    return { id: datas.id, label: datas.label.toUpperCase().trim(), labelValue: datas.label.toUpperCase().trim() };
  }

  function updateSignaturePlace(value) {
    var sigPlace = props.countries.find((country) => { return country.id === parseInt(value); });
    setSignaturePlace(sigPlace);
    for (var i = 0; i < props.signaturePlace.bl_rows.length; i++) {
      props.setRowsBLGlobal(props.rowsBLGlobal.map((row) => {
        if (row.id == props.signaturePlace.bl_rows[i].id) {
          row.signature_place = sigPlace;
        }
        return row;
      }));
    };
  }


  const handleAddBlDate = () => {
    const id = randomId();
    setRowsBLDate((oldRows) => [...oldRows, { id: id, value: '', bl_rows: [], isExpanded: true }]);
  };

  //function for change state of extand
  const handleChange = (toggle) => (event: SyntheticEvent) => {
    setExpanded(!toggle);
  }

  return (
    <div className="signaturePlaceDiv">
      <Accordion className="signaturePlaceAccordion" expanded={expanded} onChange={handleChange(expanded)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {!!signaturePlace != false && signaturePlace.label ? signaturePlace.label.toUpperCase() : "NEW SIGNATURE PLACE"}
        </AccordionSummary>
        <AccordionDetails>
          <div className='lineContentBL'>
            <SelectAutoCustom
              title="Signature Place"
              api="country/list"
              apiParam=""
              apiParamValue=""
              dataValue={signaturePlace?.label || ""}
              setDataValue={updateSignaturePlace}
              error={false}
              setDataAlias=""
              defaultAlias=""
              creator={createSelectItem}
              cached={true}
              cacheTTL="600"
              resetCache="1"
              nosort

              read={props.canRead("bl_block")}
              write={props.canWrite("bl_block")}
              required={props.isRequired("bl_block")}
              controlFieldsStatus={props.workflowLoaded}
            />

            {(props.canWrite("bl_block")) ? (
              <LoadingButton
                onClick={handleAddBlDate}
                loadingIndicator="Adding Date"
                variant="outlined"
                className='addButtonSelect2'
              >
                Add BL Date
              </LoadingButton>
            ) : ""}
          </div>
          <div >
            {rowsBLDate.length >= 1 && rowsBLDate.map((item, index) => {
              return (
                <BLDateRow
                  key={item.id}
                  {...props}
                  blDateRow={item}
                  setRowsBLDate={setRowsBLDate}
                  signaturePlace={signaturePlace}
                  isExpanded={item.isExpanded !== undefined ? item.isExpanded : false}

                  canRead={props.canRead}
                  canWrite={props.canWrite}
                  isRequired={props.isRequired}
                  workflowLoaded={props.workflowLoaded}
                  getControlFieldsErrorState={props.getControlFieldsErrorState}
                  getControlFieldsErrorMsg={props.getControlFieldsErrorMsg}
                />
              );
            })}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
