import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import {checkSecurity} from 'components/config/Security';
import MissionsListDashboard  from './section/MissionsListDashboard'
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { saveAs } from 'file-saver';
import Moment from 'moment';
import Masonry from '@mui/lab/Masonry';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

//FILTER
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {GridLinkOperator} from '@mui/x-data-grid-pro';
import FilterCheckBox from '../filter/FilterCheckBox'
import FilterStatusList from '../filter/FilterStatusList'
//END FILTER
import Menu from './section/Menu';
import 'styles/globalPortalDashboard.css';
import 'styles/Dashboard.css';
import 'bootstrap/dist/css/bootstrap.css';

import axios from 'api/axios';
const EXPORTMISSIONLIST_URL = 'mission/export';

const Alert = React.forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Item = styled(Paper)(({ theme }) => ({
  padding:0,
}));

const Dashboard = () => {
    // CHECK SECURITY
    const navigate = useNavigate();

    useEffect(() => {
      checkSecurity(navigate, "Dashboard");
      var filter = JSON.parse(localStorage.getItem("filter"));

      if (filter)
      {
        //INIT DATAS Filter list
        setFilterDatas(filter.datas);

        //INIT FILTER Status
        if (filter.status)
        {
          filter.status.map((itemStatus) => {
              setFilterStatus(filter.status, itemStatus, true, true);
          });
        }

        //INIT SEARCH TXT CATEGORY
        if (filter.search || filter.category)
        {
          setSearchTxt(filter.search);
          setCategory(filter.category);
          setSearchTxtAction(filter.search, filter.category, filter.datas, filter.status);
        }
        resetFilterResetButton(filter.datas, filter.status, filter.search, filter.category);
      }
    }, []);
    // END SECURITY
    const [openSnackBar, setOpenSnackBar] = React.useState(false);

    const [rowsModified, setRowsModified] = useState([]);
    const [rowsModifiedMe, setRowsModifiedMe] = useState([]);
    const [rowsToBeupdated, setRowsToBeupdated] = useState([]);
    const [rowsPinned, setRowsPinned] = useState([]);

    const [totalRowsModified, setTotalRowsModified] = useState([]);
    const [totalRowsModifiedMe, setTotalRowsModifiedMe] = useState([]);
    const [totalRowsToBeupdated, setTotalRowsToBeupdated] = useState([]);
    const [totalRowsPinned, setTotalRowsPinned] = useState([]);

    const [vesselRows, setVesselRows] = useState([]);
    const [surveyRows, setSurveyRows] = useState([]);
    const [principalRows, setPrincipalRows] = useState([]);
    const [missionRows, setMissionRows] = useState([]);
    const [portRows, setPortRows] = useState([]);
    const [insuredRows, setInsuredRows] = useState([]);
    const [receiverRows, setReceiverRows] = useState([]);
    const [loadingGrid, setLoadingGrid] = useState(false);
    const [dataGridLoaded1, setDataGridLoaded1] = React.useState(false);
    const [dataGridLoaded2, setDataGridLoaded2] = React.useState(false);
    const [dataGridLoaded3, setDataGridLoaded3] = React.useState(false);
    const [dataGridLoaded4, setDataGridLoaded4] = React.useState(false);

    const [survey, setSurvey] = React.useState('');
    const [principals, setPrincipals] = React.useState('');
    const [missionType, setMissionType] = React.useState('');
    const [port, setPort] = React.useState('');
    const [insured, setInsured] = React.useState('');
    const [receiver, setReceiver] = React.useState('');
    const [vessel, setVessel] = React.useState('');

    const [category, setCategory] = React.useState('');
    const [searchTxt, setSearchTxt] = React.useState('');
    const [searchTxtState, setSearchTxtState] = React.useState('');

    const [filterModel, setFilterModel] = React.useState({items: []});
    const [filterDatas, setFilterDatas] = React.useState({survey:'',principals:'',missionType:'',port:'',insured:'',receiver:'',vessel:''});
    const [filterStatusArray, setFilterStatusArray] = React.useState([]);

    const [filterOn, setFilterOn] = React.useState(false);
    const [statusFilter1, setStatusFilter1] = React.useState(false);
    const [resetStatusFilter1, setResetStatusFilter1] = React.useState(false);
    const [statusFilter2, setStatusFilter2] = React.useState(false);
    const [resetStatusFilter2, setResetStatusFilter2] = React.useState(false);
    const [statusFilter3, setStatusFilter3] = React.useState(false);
    const [resetStatusFilter3, setResetStatusFilter3] = React.useState(false);
    const [statusFilter4, setStatusFilter4] = React.useState(false);
    const [resetStatusFilter4, setResetStatusFilter4] = React.useState(false);
    const [statusFilter5, setStatusFilter5] = React.useState(false);
    const [resetStatusFilter5, setResetStatusFilter5] = React.useState(false);
    const [statusFilter6, setStatusFilter6] = React.useState(false);
    const [resetStatusFilter6, setResetStatusFilter6] = React.useState(false);
    const [statusFilter7, setStatusFilter7] = React.useState(false);
    const [resetStatusFilter7, setResetStatusFilter7] = React.useState(false);
    const [statusFilter8, setStatusFilter8] = React.useState(false);
    const [resetStatusFilter8, setResetStatusFilter8] = React.useState(false);
    const [statusFilter9, setStatusFilter9] = React.useState(false);
    const [resetStatusFilter9, setResetStatusFilter9] = React.useState(false);
    const [statusFilter10, setStatusFilter10] = React.useState(false);
    const [resetStatusFilter10, setResetStatusFilter10] = React.useState(false);

    const [visibleRows, setVisibleRows] = React.useState([]);
    const [isDownload, setIsDownload] = React.useState(false);

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }

      setIsDownload(false);
    };

    function selectDatacolomn(filterDataParam, filterStatusArray, totalRows, setRows)
    {
      var selectedRows = [];
      var addRow = true;
      for(var i=0; i < totalRows.length; i++) {
        addRow = true;
        //Filtre SURVEY non applicable sur Dashboard utilisateur
        //if (filterDataParam.survey.length > 0 && totalRows[i].survey_handler !== filterDataParam.survey) addRow = false;
        if (filterDataParam.principals.length > 0 && totalRows[i].principals !== filterDataParam.principals) addRow = false;
        if (filterDataParam.missionType.length > 0 && totalRows[i].missionTypeLabel !== filterDataParam.missionType) addRow = false;
        if (filterDataParam.port.length > 0 && totalRows[i].port !== filterDataParam.port) addRow = false;
        if (filterDataParam.insured.length > 0 && totalRows[i].insured !== filterDataParam.insured) addRow = false;
        if (filterDataParam.receiver.length > 0 && totalRows[i].receiver.indexOf(filterDataParam.receiver) < 0) addRow = false;
        if (filterDataParam.vessel.length > 0 && totalRows[i].vessel !== filterDataParam.vessel) addRow = false;

        //Status filter
        if (filterStatusArray.length > 0)
        {
          if (!filterStatusArray.includes(totalRows[i].status.statut_type)) addRow = false;
        }
        else
        {
          if (!['1','2','3','4','5','6','7','8'].includes(totalRows[i].status.statut_type.toString())) addRow = false;
        }

        if (addRow) selectedRows[selectedRows.length] = totalRows[i];
      }

      setRows(selectedRows);
    }

    function setFilterSelectedLocalStorage(filterDataParam, filterStatusArray, searchTxt, category)
    {
      var filter = {datas:filterDataParam, status:filterStatusArray, search:searchTxt, category:category};
      localStorage.setItem("filter", JSON.stringify(filter));
    }

    function initFilterMissions(rowsFromApi, param, paramValue)
    {
      var filter = JSON.parse(localStorage.getItem("filter"));

      if (!filter)
      {
        filter = {datas:filterDatas, status:filterStatusArray, search:searchTxt, category:category};
      }

      if (param == "last_modified_by")
      {
        if (paramValue == "other")
        {
          selectDatacolomn(filter.datas, filter.status, rowsFromApi, setRowsModified);
        }
        else if (paramValue == "me")
        {
          selectDatacolomn(filter.datas, filter.status, rowsFromApi, setRowsModifiedMe);
        }
      }
      else if (param == "days_without_modif")
      {
        selectDatacolomn(filter.datas, filter.status, rowsFromApi, setRowsToBeupdated);
      }
      else if (param == "pinned")
      {
        selectDatacolomn(filter.datas, filter.status, rowsFromApi, setRowsPinned);
      }

      //setSurvey(filter.datas.survey);
      setPrincipals(filter.datas.principals);
      setMissionType(filter.datas.missionType);
      setPort(filter.datas.port);
      setInsured(filter.datas.insured);
      setReceiver(filter.datas.receiver);
      setVessel(filter.datas.vessel);
    }

    function selectDataContent(filterDataParam, filterStatusArray)
    {
      setLoadingGrid(true);

      selectDatacolomn(filterDataParam, filterStatusArray, totalRowsModified, setRowsModified);
      selectDatacolomn(filterDataParam, filterStatusArray, totalRowsModifiedMe, setRowsModifiedMe);
      selectDatacolomn(filterDataParam, filterStatusArray, totalRowsToBeupdated, setRowsToBeupdated);
      selectDatacolomn(filterDataParam, filterStatusArray, totalRowsPinned, setRowsPinned);

      setLoadingGrid(false);
      setFilterSelectedLocalStorage(filterDataParam, filterStatusArray, searchTxt, category);
      resetFilterResetButton(filterDataParam, filterStatusArray, searchTxt, category);
    }

    function setSearchTxtAction(searchTxtAct, cat, filterDatasParam, filterStatusArrayParam)
    {
      var filterDatasVar = filterDatas;
      var filterStatusArrayVar = filterStatusArray;
      if (filterDatasParam) filterDatasVar = filterDatasParam;
      if (filterStatusArrayParam) filterStatusArrayVar = filterStatusArrayParam;


      if (searchTxtAct.length > 0)
        {
          if (cat.length < 1)
          {
            setFilterModel({
                items: [
                  {id: 1,columnField: "ref", operatorValue: 'contains', value: searchTxtAct,},
                  {id: 2,columnField: "port", operatorValue: 'contains', value: searchTxtAct,},
                  {id: 3,columnField: "principals", operatorValue: 'contains', value: searchTxtAct,},
                  {id: 4,columnField: "principalsRef", operatorValue: 'contains', value: searchTxtAct,},
                  {id: 5,columnField: "vessel", operatorValue: 'contains', value: searchTxtAct,},
                  {id: 6,columnField: "b_o_l", operatorValue: 'contains', value: searchTxtAct,},
                  {id: 7,columnField: "warehouse", operatorValue: 'contains', value: searchTxtAct,},
                  {id: 8,columnField: "receiverSearch", operatorValue: 'contains', value: searchTxtAct,},
                  {id: 9,columnField: "cargo", operatorValue: 'contains', value: searchTxtAct,},
                  {id: 10,columnField: "trader", operatorValue: 'contains', value: searchTxtAct,},
                  {id: 11,columnField: "insured", operatorValue: 'contains', value: searchTxtAct,},
                ],
                linkOperator: GridLinkOperator.Or,
              });
          }
          else {
            setFilterModel({
                items: [
                  {
                    columnField: cat,
                    operatorValue: 'contains',
                    value: searchTxtAct,
                  },
                ],
              });
          }
        }
        else
        {
            setFilterModel({items: []});
        }
        setFilterSelectedLocalStorage(filterDatasVar, filterStatusArrayVar, searchTxtAct, cat);
        resetFilterResetButton(filterDatasVar, filterStatusArrayVar, searchTxtAct, cat);
    }

    const handleChangeCategory = (event) => {
      setCategory(event.target.value);
      setSearchTxtAction(searchTxt, event.target.value);
    };

    const handleChangeSearchText = (event) => {
      setSearchTxt(event.target.value);
      if (event.target.value.length > 0)
      {
        setSearchTxtState("");
        setSearchTxtAction(event.target.value, category);
      }
      else {
        if (event.target.value.length === 0) {
          setSearchTxtState("");
          setSearchTxtAction(event.target.value, category);
        }
        else {
          setSearchTxtState("warning");
        }
      }
    }

    //SETTER
    function createSurveyHandler(data) {
      return {id:data.id, alias:data.firstname+" "+data.lastname};
    }

    function createPrincipalsHandler(data) {
      return {id:data.id, alias:data.name};
    }

    function createMissionTypeHandler(data) {
      return {id:data.nomenclature, alias:data.label};
    }

    function createPortHandler(data) {
      return {id:data.nomenclature, alias:data.label};
    }

    function createInsuredHandler(data) {
      return {id:data.id, alias:data.name};
    }

    function createReceiverHandler(data) {
      return {id:data.id, alias:data.name};
    }

    function createVesselHandler(data) {
      return {id:data.id, alias:data.name};
    }
    //Filter
    function setSurveyHandler(data) {
      setSurvey(data);
      var filterData = {survey:data,principals:principals,missionType:missionType,port:port,insured:insured,receiver:receiver,vessel:vessel};
      setFilterDatas(filterData);
      selectDataContent(filterData, filterStatusArray);
    }

    function setPrincipalsHandler(data) {
      setPrincipals(data);
      var filterData = {survey:survey,principals:data,missionType:missionType,port:port,insured:insured,receiver:receiver,vessel:vessel};
      setFilterDatas(filterData);
      selectDataContent(filterData, filterStatusArray);
    }

    function setMissionTypeHandler(data) {
      setMissionType(data);
      var filterData = {survey:survey,principals:principals,missionType:data,port:port,insured:insured,receiver:receiver,vessel:vessel};
      setFilterDatas(filterData);
      selectDataContent(filterData, filterStatusArray);
    }

    function setPortHandler(data) {
      setPort(data);
      var filterData = {survey:survey,principals:principals,missionType:missionType,port:data,insured:insured,receiver:receiver,vessel:vessel};
      setFilterDatas(filterData);
      selectDataContent(filterData, filterStatusArray);
    }

    function setInsuredHandler(data) {
      setInsured(data);
      var filterData = {survey:survey,principals:principals,missionType:missionType,port:port,insured:data,receiver:receiver,vessel:vessel};
      setFilterDatas(filterData);
      selectDataContent(filterData, filterStatusArray);
    }

    function setReceiverHandler(data) {
      setReceiver(data);
      var filterData = {survey:survey,principals:principals,missionType:missionType,port:port,insured:insured,receiver:data,vessel:vessel};
      setFilterDatas(filterData);
      selectDataContent(filterData, filterStatusArray);
    }

    function setVesselHandler(data) {
      setVessel(data);
      var filterData = {survey:survey,principals:principals,missionType:missionType,port:port,insured:insured,receiver:receiver,vessel:data};
      setFilterDatas(filterData);
      selectDataContent(filterData, filterStatusArray);
    }

    function setFilterStatus(filter, index, value, init)
    {
      setFilterStatusArray(filter);
      if (!init) selectDataContent(filterDatas, filter);
      if (index == 1) {setStatusFilter1(value);}
      else if (index == 2) {setStatusFilter2(value);}
      else if (index == 3) {setStatusFilter3(value);}
      else if (index == 4) {setStatusFilter4(value);}
      else if (index == 5) {setStatusFilter5(value);}
      else if (index == 6) {setStatusFilter6(value);}
      else if (index == 7) {setStatusFilter7(value);}
      else if (index == 8) {setStatusFilter8(value);}
      else if (index == 9) {setStatusFilter9(value);}
      else if (index == 10) {setStatusFilter10(value);}
    }

    function resetFilterResetButton(filterDatas, filter, searchTxt, category)
    {
      var showFilter = false;
      if (filter.length > 0) showFilter = true;
      if (filterDatas.survey !== ''
          || filterDatas.principals !== ''
          || filterDatas.missionType !== ''
          || filterDatas.port !== ''
          || filterDatas.insured !== ''
          || filterDatas.receiver !== ''
          || filterDatas.vessel) showFilter = true;
      if (searchTxt !== '') showFilter = true;
      if (category !== '') showFilter = true;

      setFilterOn(showFilter);
    }

    function handleResetAll()
    {
      setSurvey('');
      setPrincipals('');
      setMissionType('');
      setPort('');
      setInsured('');
      setReceiver('');
      setVessel('');

      setVesselRows([]);
      setSurveyRows([]);
      setPrincipalRows([]);
      setMissionRows([]);
      setPortRows([]);
      setInsuredRows([]);
      setReceiverRows([]);
      setFilterStatusArray([]);

      setCategory('');
      setSearchTxt('');
      setSearchTxtState("");

      setResetStatusFilter1(true);
      setStatusFilter1(false);
      setResetStatusFilter2(true);
      setStatusFilter2(false);
      setResetStatusFilter3(true);
      setStatusFilter3(false);
      setResetStatusFilter4(true);
      setStatusFilter4(false);
      setResetStatusFilter5(true);
      setStatusFilter5(false);
      setResetStatusFilter6(true);
      setStatusFilter6(false);
      setResetStatusFilter7(true);
      setStatusFilter7(false);
      setResetStatusFilter8(true);
      setStatusFilter8(false);
      setResetStatusFilter9(true);
      setStatusFilter9(false);
      setResetStatusFilter10(true);
      setStatusFilter10(false);

      var filterData = {survey:'',principals:'',missionType:'',port:'',insured:'',receiver:'',vessel:''};
      setFilterDatas(filterData);
      setSearchTxtAction("", "");
      selectDataContent(filterData, []);
      setFilterOn(false);
      localStorage.setItem("filter", null);
    }


    return (
        <div className='FULL-CONTENT DASHBOARD'>
          <div className="content">
            <div className="contentFilter">
              <Box sx={{ display: 'flex', alignItems: 'flex-end', width: '99%' }} className="FilterSearchBlockField">
                <Box className='SearchBoxText' sx={{ width: '99%' }}>
                  <SearchRoundedIcon sx={{ color: 'action.active' }} className='iconeLeft'/>
                  <FormControl variant="standard"  sx={{ m: 1, minWidth: 300, width: '100%', paddingLeft:3, paddingRight:2 }}>
                    <TextField id="input-search-with-sx" label="Search" variant="standard" value={searchTxt} fullWidth onChange={handleChangeSearchText} color={searchTxtState} />
                  </FormControl>
                </Box>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="category-simple-select-standard-label">Category</InputLabel>
                  <Select
                    labelId="category-simple-select-standard-label"
                    id="category-simple-select-standard"
                    value={category}
                    onChange={handleChangeCategory}
                    label="Category"
                  >
                    <MenuItem value=""><em>All</em></MenuItem>
                    <MenuItem value="ref">Ref JLB</MenuItem>
                    <MenuItem value="port">Port</MenuItem>
                    <MenuItem value="principals">Principals</MenuItem>
                    <MenuItem value="principalsRef">Principal ref</MenuItem>
                    <MenuItem value="vessel">Vessel (BL)</MenuItem>
                    <MenuItem value="b_o_l">Bill of lading</MenuItem>
                    <MenuItem value="warehouse">Warehouse</MenuItem>
                    <MenuItem value="receiver">Receiver</MenuItem>
                    <MenuItem value="cargo">Cargo</MenuItem>
                    <MenuItem value="trader">Trader</MenuItem>
                    <MenuItem value="insured">Insured</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="standard"  sx={{ display: 'inline', m: 1, minWidth: 300 }}>
                  <FilterStatusList
                    setFilter={setFilterStatus}
                    filterStatus={filterStatusArray}
                    statusFilter={[
                      statusFilter1,
                      statusFilter2,
                      statusFilter3,
                      statusFilter4,
                      statusFilter5,
                      statusFilter6,
                      statusFilter7,
                      statusFilter8,
                      statusFilter9,
                      statusFilter10
                    ]}
                    statusFilterReset={[
                      resetStatusFilter1,
                      resetStatusFilter2,
                      resetStatusFilter3,
                      resetStatusFilter4,
                      resetStatusFilter5,
                      resetStatusFilter6,
                      resetStatusFilter7,
                      resetStatusFilter8,
                      resetStatusFilter9,
                      resetStatusFilter10
                    ]}
                    changeStatusFilter={[
                      setResetStatusFilter1,
                      setResetStatusFilter2,
                      setResetStatusFilter3,
                      setResetStatusFilter4,
                      setResetStatusFilter5,
                      setResetStatusFilter6,
                      setResetStatusFilter7,
                      setResetStatusFilter8,
                      setResetStatusFilter9,
                      setResetStatusFilter10
                    ]}
                    opacity="30%"
                  />
                </FormControl>
                <div className={filterOn ? 'resetButtonFilter' : 'resetButtonFilter hide'} onClick={handleResetAll}><i className="bx bx-reset"></i><span>Reset all</span></div>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', width: '100%' }} className="FilterSearchBlockFieldBottom">

                {Boolean(dataGridLoaded1) && Boolean(dataGridLoaded2) && Boolean(dataGridLoaded3) && Boolean(dataGridLoaded4) && (
                  <FilterCheckBox
                    title="Principals"
                    width="200"
                    api="company/list"
                    apiParam="company_role"
                    apiParamValue="Principals"
                    creator={createPrincipalsHandler}
                    setValueOfFilterAndSearch={setPrincipalsHandler}
                    dataValueHandler={principals}
                    setDataValueHandler={setPrincipals}
                    datas={principalRows}
                    loadByList='true'
                  />
                )}

                {Boolean(dataGridLoaded1) && Boolean(dataGridLoaded2) && Boolean(dataGridLoaded3) && Boolean(dataGridLoaded4) && (
                  <FilterCheckBox
                    title="Mission type"
                    width="200"
                    api="mission_type/list"
                    apiParam="hide_cocoa_departure"
                    apiParamValue="1"
                    creator={createMissionTypeHandler}
                    setValueOfFilterAndSearch={setMissionTypeHandler}
                    dataValueHandler={missionType}
                    setDataValueHandler={setMissionType}
                    datas={missionRows}
                    loadByList='true'
                  />
                )}

                {Boolean(dataGridLoaded1) && Boolean(dataGridLoaded2) && Boolean(dataGridLoaded3) && Boolean(dataGridLoaded4) && (
                  <FilterCheckBox
                    title="Port"
                    width="200"
                    api="port/list"
                    apiParam=""
                    apiParamValue=""
                    creator={createPortHandler}
                    setValueOfFilterAndSearch={setPortHandler}
                    dataValueHandler={port}
                    setDataValueHandler={setPort}
                    datas={portRows}
                    loadByList='true'
                  />
                )}

                {Boolean(dataGridLoaded1) && Boolean(dataGridLoaded2) && Boolean(dataGridLoaded3) && Boolean(dataGridLoaded4) && (
                  <FilterCheckBox
                    title="Insured"
                    width="200"
                    api="company/list"
                    apiParam="company_role"
                    apiParamValue="Insured"
                    creator={createInsuredHandler}
                    setValueOfFilterAndSearch={setInsuredHandler}
                    dataValueHandler={insured}
                    setDataValueHandler={setInsured}
                    datas={insuredRows}
                    loadByList='true'
                  />
                )}

                {Boolean(dataGridLoaded1) && Boolean(dataGridLoaded2) && Boolean(dataGridLoaded3) && Boolean(dataGridLoaded4) && (
                  <FilterCheckBox
                    title="Receiver"
                    width="200"
                    api="company/list"
                    apiParam="company_role"
                    apiParamValue="Receiver"
                    creator={createReceiverHandler}
                    setValueOfFilterAndSearch={setReceiverHandler}
                    dataValueHandler={receiver}
                    setDataValueHandler={setReceiver}
                    datas={receiverRows}
                    loadByList='true'
                  />
                )}

                {Boolean(dataGridLoaded1) && Boolean(dataGridLoaded2) && Boolean(dataGridLoaded3) && Boolean(dataGridLoaded4) && (
                  <FilterCheckBox
                    title="Vessel (BL)"
                    width="200"
                    api="vessel/list"
                    apiParam=""
                    apiParamValue=""
                    creator={createVesselHandler}
                    setValueOfFilterAndSearch={setVesselHandler}
                    dataValueHandler={vessel}
                    setDataValueHandler={setVessel}
                    datas={vesselRows}
                    loadByList='true'
                  />
                )}
              </Box>
            </div>
            <div className="listDashbloard">
              <Masonry columns={4} spacing={2} className="dashboardTuiles">
                <Item key={1} className="columnTuiles rightBorder">
                  <MissionsListDashboard
                    title="Modified"
                    param="last_modified_by"
                    paramValue="other"
                    id_principal={localStorage.getItem("id_company")}
                    data={{ filterModel, setFilterModel, loadingGrid, setLoadingGrid, vesselRows, missionRows, portRows, receiverRows, insuredRows, principalRows, setVesselRows, setSurveyRows, setPrincipalRows, setMissionRows, setPortRows, setInsuredRows, setReceiverRows, setVisibleRows, openSnackBar, setOpenSnackBar}}
                    rows={rowsModified}
                    setRows={setRowsModified}
                    setTotalRows={setTotalRowsModified}
                    setDataGridLoaded={setDataGridLoaded1}
                    initFilterMissions={initFilterMissions}
                    all={0}
                   />
                </Item>
                <Item key={2} className="columnTuiles rightBorder">
                  <MissionsListDashboard
                    title="Modified by me"
                    param="last_modified_by"
                    paramValue="me"
                    id_principal={localStorage.getItem("id_company")}
                    data={{ filterModel, setFilterModel, loadingGrid, setLoadingGrid, vesselRows, missionRows, portRows, receiverRows, insuredRows, principalRows, setVesselRows, setSurveyRows, setPrincipalRows, setMissionRows, setPortRows, setInsuredRows, setReceiverRows, setVisibleRows, openSnackBar, setOpenSnackBar}}
                    rows={rowsModifiedMe}
                    setRows={setRowsModifiedMe}
                    setTotalRows={setTotalRowsModifiedMe}
                    setDataGridLoaded={setDataGridLoaded2}
                    initFilterMissions={initFilterMissions}
                    all={1}
                   />
                 </Item>
                <Item key={3} className="columnTuiles rightBorder">
                  <MissionsListDashboard
                    title="To be updated"
                    param="days_without_modif"
                    paramValue="15"
                    id_principal={localStorage.getItem("id_company")}
                    data={{ filterModel, setFilterModel, loadingGrid, setLoadingGrid, vesselRows, missionRows, portRows, receiverRows, insuredRows, principalRows, setVesselRows, setSurveyRows, setPrincipalRows, setMissionRows, setPortRows, setInsuredRows, setReceiverRows, setVisibleRows, openSnackBar, setOpenSnackBar}}
                    rows={rowsToBeupdated}
                    setRows={setRowsToBeupdated}
                    setTotalRows={setTotalRowsToBeupdated}
                    setDataGridLoaded={setDataGridLoaded3}
                    initFilterMissions={initFilterMissions}
                    all={0}
                   />
                </Item>
                <Item key={4} className='columnTuiles'>
                  <MissionsListDashboard
                    title="Pinned"
                    param="pinned"
                    paramValue="1"
                    id_principal={localStorage.getItem("id_company")}
                    data={{ filterModel, setFilterModel, loadingGrid, setLoadingGrid, vesselRows, missionRows, portRows, receiverRows, insuredRows, principalRows, setVesselRows, setSurveyRows, setPrincipalRows, setMissionRows, setPortRows, setInsuredRows, setReceiverRows, setVisibleRows, openSnackBar, setOpenSnackBar}}
                    rows={rowsPinned}
                    setRows={setRowsPinned}
                    setTotalRows={setTotalRowsPinned}
                    setDataGridLoaded={setDataGridLoaded4}
                    initFilterMissions={initFilterMissions}
                    all={1}
                   />
                </Item>
              </Masonry>

            </div>
          </div>
          {/*
            <MissionsListDashboard data={{ filterModel, setFilterModel, rows, setRows, setTotalRows, loadingGrid, setLoadingGrid, vesselRows, setVesselRows, setSurveyRows, setPrincipalRows, setMissionRows, setPortRows, setInsuredRows, setReceiverRows, setDataGridLoaded, setVisibleRows, openSnackBar, setOpenSnackBar}} />
          */}
          
          <Menu />
              
          <Snackbar open={isDownload} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
              File saved in your downloads folder.
            </Alert>
          </Snackbar>
        </div>
    )
}

export default Dashboard
