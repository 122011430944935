import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { DataGridPro,
    GridColDef,
    gridVisibleSortedRowIdsSelector,
    useGridApiRef,
    gridVisibleRowCountSelector } from '@mui/x-data-grid-pro';
import LinearProgress from '@mui/material/LinearProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { randomId } from '@mui/x-data-grid-generator';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import IconButton from '@mui/material/IconButton';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import {isInternalRole, isPortRole, isCustomerRole} from 'components/config/Roles';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import CommentIcon from '@mui/icons-material/Comment';
import Badge from '@mui/material/Badge';


import {checkSecurity} from 'components/config/Security';
import axios from 'api/axios';
const GETMISSIONLIST_URL = 'mission/list';

const Alert = React.forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Tuile = styled(Paper)(({ theme }) => ({
  lineHeight: '60px',
}));

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

export default function MissionsListDashboard(props) {

  const [JLBInternalMode] = React.useState(isInternalRole(localStorage.getItem("role")));

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    props.data.setOpenSnackBar(false);
  };

  // CHECK SECURITY
  const navigate = useNavigate();

  useEffect(() => {
    checkSecurity(navigate, "DashboardList");
    if (isCustomerRole(localStorage.getItem("role"))) setHidePrincipal(true);
    getMissionList();
    apiRef.current.subscribeEvent("stateChange", () => {
      const count = gridVisibleRowCountSelector(apiRef.current.state);
      setVisibleRows(count);
    });
  }, []);
  // END SECURITY

  const RenderType = (props) => {
    const { value } = props;
    return (<div className="typeMissionGridColor" style={{backgroundColor: value}}></div>
    );
  };

  RenderType.propTypes = {
    /**
     * If true, the cell is the active element.
     */
    hasFocus: PropTypes.bool.isRequired,
    /**
     * The cell value, but if the column has valueGetter, use getValue.
     */
    value: PropTypes.instanceOf(Number),
  };

  const RenderNotif = (props) => {
    var notif = false;
    if (typeof props != "undefined") notif = props;
    if(notif){
      return (
        <span className="puceNotifBorder">
          <Badge className="puceNotif" badgeContent="" variant="dot" overlap="circular"></Badge>
        </span>
      );
    }
  };

  const RenderStatus = (props) => {
    const { status } = props.row;
    return (<div className="statusMissionGridColor" style={{backgroundColor: status.statut_color}}>{status.statut_type}</div>);
  };

  const RenderDate = (props) => {
    var created= props.row;
    return (moment(created).format("DD MMM YY"));
  };

  const RenderReceiver = (props) => {
    var txt = "";
    var prefix = "";

    for(var i=0; i < props.row.receiverSearch.length; i++) {
      txt = txt + prefix + props.row.receiverSearch[i];
      prefix = "\n\r";
    }

    if (props.row.receiverSearch.length > 0)
    {
      return (<Tooltip
                title={<span style={{ whiteSpace: 'pre-line' }}>{txt}</span>}
                placement="right">
                  <IconButton>
                    <FormatListBulletedIcon />
                  </IconButton>
              </Tooltip>);
    }
    else return (<></>);
  };

  const RenderBL= (props) => {
    var txt = "";
    var prefix = "";

    for(var i=0; i < props.row.b_o_l.length; i++) {
      txt = txt + prefix + props.row.b_o_l[i];
      prefix = "\n\r";
    }

    if (props.row.b_o_l.length > 0)
    {
      return (<Tooltip
                title={<span style={{ whiteSpace: 'pre-line' }}>{txt}</span>}
                placement="right">
                  <IconButton>
                    <FormatListBulletedIcon />
                  </IconButton>
              </Tooltip>);
    }
    else return (<></>);
  };

  const RenderComment = (props) => {
    var txt = "";
    if (props) txt = props;

    if (txt.length > 0)
    {
      return(<BootstrapTooltip title={txt} placement="top" enabled="false">
                  <CommentIcon fontSize="small" sx={{ marginLeft: '4px' }} />
             </BootstrapTooltip>
      );
    }
    else return (<></>);
  }

  const RenderPrincilapsInsured = (props) => {
    if (props.length > 27)
    {
      var principalsInsurred = props.substring(0, 27) + " ...";
      return (
        <BootstrapTooltip title={props} placement="bottom" enabled="false"><span>{principalsInsurred}</span></BootstrapTooltip>
      );
    }
    else
    {
      return (
        <span>{props}</span>
      );
    }
  }

  const RenderTuile= (props) => {
    var txt = "";
    var prefix = "";

    for(var i=0; i < props.row.b_o_l.length; i++) {
      txt = txt + prefix + props.row.b_o_l[i];
      prefix = "\n\r";
    }

    var principalsInsurred = "";
    if (props.row.principals == props.row.insured)
    {
      principalsInsurred = props.row.principals;
    }
    else {
      principalsInsurred = props.row.principals + " (" + props.row.insured + ")";
    }

    return(
      <Tuile elevation={3} className="tuile">
        
        <div className="statusMissionGridColor" style={{backgroundColor: props.row.status.statut_color}}>
          {props.row.status.statut_type}
          {(JLBInternalMode) && (RenderNotif(props.row.has_unread_internal_notification))}
        </div>
        <div className="typeMissionColor" style={{backgroundColor: props.row.missionColor}}></div>
        <div className="titre">{props.row.ref}{RenderComment(props.row.internal_note)}</div>
        <div className="principales">{RenderPrincilapsInsured(principalsInsurred)}</div>
        <div className="missionTypeLabel">{props.row.missionTypeLabel}</div>
        <div className="created">{RenderDate({row:props.row.created})}</div>
        <div className="b_o_l">BL : <span>{txt}</span></div>
        <div className="vessel">Vessel (BL) : <span>{props.row.vessel}</span></div>
        <div className="warehouse">Warehouse : <span>{props.row.warehouse}</span></div>
      </Tuile>
    );
  }

  function getStatusId(params) {
    return `${params.value.statut_type}`;
  }

  const [hidePrincipal, setHidePrincipal] = useState(false);
  const [refJLBTXTLabel, setRefJLBTXTLabel] = useState('Ref JLB');
  const [visibleRows, setVisibleRows] = React.useState(0);
  const apiRef = useGridApiRef();
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: props.title,
      flex:1,
      hide:false,
      renderCell: RenderTuile,
      renderHeader: (params: GridColumnHeaderParams) => (
        <>
          <span className="HeaderTitle">{props.title}</span>
          <span className="HeaderNumber">({visibleRows})</span>
        </>
      ),
    },
    {
      field: 'survey_handler',
      headerName: 'Survey handler',
      hide:true,
    },
    {
      field: 'ref',
      headerName: 'Ref JLB',
      width:145,
      hide:true,
    },
    {
      field: 'missionType',
      headerName: 'Mission Type',
      hide:true,
      type:'number',
    },
    {
      field: 'missionTypeLabel',
      headerName: 'Type of mission',
      hide:!hidePrincipal,
      width:170,
      hide:true,
    },
    {
      field: 'created',
      headerName: 'Creation date',
      flex:1,
      type:'date',
      align:'left',
      renderCell: RenderDate,
      hide:true,
    },
    {
      field: 'status',
      headerName: 'Status',
      width:64,
      type:'number',
      align:'center',
      renderCell: RenderStatus,
      valueGetter: getStatusId,
      hide:true,
    },
    {
      field: 'port',
      headerName: 'Port',
      flex:1,
      minWidth:110,
      maxWidth:150,
      hide:true,
    },
    {
      field: 'principals',
      headerName: 'Principals',
      flex:1,
      minWidth:140,
      hide: hidePrincipal,
      hide:true,
    },
    {
      field: 'principalsRef',
      headerName: 'Your réf.',
      flex:1,
      minWidth:140,
      hide:true,
    },
    {
      field: 'insured',
      headerName: 'Insured',
      flex:1,
      minWidth:140,
      hide:true,
    },
    {
      field: 'vessel',
      headerName: 'Vessel (BL)',
      flex:1,
      minWidth:140,
      hide:true,
    },
    {
      field: 'receiver',
      headerName: 'Receiver',
      flex:1,
      minWidth:80,
      sortable: false,
      renderCell: RenderReceiver,
      hide:true,
    },
    {
      field: 'receiverSearch',
      headerName: 'Receiver search',
      flex:1,
      minWidth:100,
      sortable: false,
      hide:true,
    },
    {
      field: 'cargo',
      headerName: 'Cargo',
      flex:1,
      minWidth:100,
      sortable: false,
      hide:true,
    },
    {
      field: 'trader',
      headerName: 'Trader',
      flex:1,
      minWidth:100,
      sortable: false,
      hide:true,
    },
    {
      field: 'b_o_l',
      headerName: 'Bill of lading',
      flex:0.7,
      minWidth:140,
      sortable: false,
      renderCell: RenderBL,
      hide:true,
    },
    {
      field: 'warehouse',
      headerName: 'Warehouse',
      flex:1,
      minWidth:140,
      hide:true,
    },
    {
      field: 'internal_note',
      headerName: 'internal_note',
      hide:true,
    },
  ];

  const handleRowClick = (params) => {
    navigate("/missionDetails?id="+params.row.id+"&key="+randomId(), { replace: true });
  };

  function getUnique(arr, index) {
    const unique = arr
         .map(e => e[index])

         // store the keys of the unique objects
         .map((e, i, final) => final.indexOf(e) === i && i)

         // eliminate the dead keys & store unique objects
        .filter(e => arr[e]).map(e => arr[e]);

     return unique;
  }

  const getMissionList = async (e) => {
    try {
      props.data.setLoadingGrid(true);
        const config = {
          headers: {
              "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
            }
        };
        const response = await axios.get(GETMISSIONLIST_URL+"?canceled=0&all="+props.all+"&"+props.param+"="+props.paramValue, config);

        var rowsFromApi = [];
        var vessels = props.data.vesselRows;
        var surveys = [];
        var principals = props.data.principalRows;
        var missions = props.data.missionRows;
        var ports = props.data.portRows;
        var insured = props.data.insuredRows;
        var receivers = props.data.receiverRows;
        var idRow = 0;

        for(var i=0; i < response.data.length; i++) {

          if (isCustomerRole(localStorage.getItem("role")))
          {
            if (parseInt(localStorage.getItem("id_company")) != parseInt(response.data[i].principal.id))
            {
                continue;
            }
          }
          idRow = rowsFromApi.length;
          rowsFromApi[idRow] = {};
          rowsFromApi[idRow].id = response.data[i].id;
          rowsFromApi[idRow].missionType = response.data[i].mission_type.id;
          missions[missions.length] = response.data[i].mission_type;
          rowsFromApi[idRow].missionTypeLabel = response.data[i].mission_type.label;
          rowsFromApi[idRow].missionColor = response.data[i].mission_type.color;
          if (response.data[i].port) rowsFromApi[idRow].ref = response.data[i].mission_type.nomenclature+"-"+response.data[i].port.nomenclature+"-"+pad(response.data[i].id);
          else rowsFromApi[idRow].ref = response.data[i].mission_type.nomenclature+"-<XXX>-"+pad(response.data[i].id);
          rowsFromApi[idRow].created = response.data[i].created_at;
          if (isCustomerRole(localStorage.getItem("role")))
          {
            if (response.data[i].status) rowsFromApi[idRow].status = { statut_type: response.data[i].status.status.public_status.status.value, statut_color: response.data[i].status.status.public_status.status.color};
            else rowsFromApi[idRow].status = { statut_type: '0', statut_color: 'red'};
          }
          else {
            if (response.data[i].status) rowsFromApi[idRow].status = { statut_type: response.data[i].status.status.value, statut_color: response.data[i].status.status.color};
            else rowsFromApi[idRow].status = { statut_type: '0', statut_color: 'red'};
          }

          if (response.data[i].port)
          {
            rowsFromApi[idRow].port = response.data[i].port.label;
            ports[ports.length] = response.data[i].port;
          }

          if (response.data[i].principal_ref) rowsFromApi[idRow].principalsRef = response.data[i].principal_ref;

          rowsFromApi[idRow].principals = response.data[i].principal.name;
          principals[principals.length] = response.data[i].principal;
          if (response.data[i].insured)
          {
            rowsFromApi[idRow].insured = response.data[i].insured.name;
            insured[insured.length] = response.data[i].insured;
          }
          else rowsFromApi[idRow].insured = "";
          if (response.data[i].vessel)
          {
            rowsFromApi[idRow].vessel = response.data[i].vessel.name;
            vessels[vessels.length] = response.data[i].vessel;
          }
          else rowsFromApi[idRow].vessel  = "";
          rowsFromApi[idRow].survey_handler = response.data[i].survey_handler.firstname+" "+response.data[i].survey_handler.lastname;
          surveys[surveys.length] = response.data[i].survey_handler;

          var contentRowLoop = [];
          if (response.data[i].receivers)
          {
            for (var j=0; j < response.data[i].receivers.length; j++) {
              if (response.data[i].receivers[j].receiver)
              {
                contentRowLoop[j] = response.data[i].receivers[j].receiver.name;
                receivers[receivers.length] = response.data[i].receivers[j].receiver;
              }
            }
          }
          rowsFromApi[idRow].receiver = contentRowLoop;
          rowsFromApi[idRow].receiverSearch = contentRowLoop;

          var contentRowLoopBL = [];
          if (response.data[i].bills_of_lading)
          {
            for (var j=0; j < response.data[i].bills_of_lading.length; j++) {
              contentRowLoopBL[j] = response.data[i].bills_of_lading[j].number;
            }
          }
          rowsFromApi[idRow].b_o_l = contentRowLoopBL;

          if (response.data[i].vessel_tonnage) rowsFromApi[idRow].tonnage = Number(response.data[i].vessel_tonnage);
          if (response.data[i].warehouse) rowsFromApi[idRow].warehouse = response.data[i].warehouse.label;
          if (response.data[i].cargo) rowsFromApi[idRow].cargo = response.data[i].cargo.label;
          if (response.data[i].trader) rowsFromApi[idRow].trader = response.data[i].trader.name;
          rowsFromApi[idRow].public_note = response.data[i].public_note;
          rowsFromApi[idRow].internal_note = response.data[i].internal_note;
          rowsFromApi[idRow].has_unread_internal_notification = response.data[i].has_unread_internal_notification;
        }

        props.setRows(rowsFromApi);
        props.setTotalRows(rowsFromApi);

        vessels.sort((a, b) => (a.name > b.name) ? 1 : -1);
        props.data.setVesselRows(getUnique(vessels,'id'));
        principals.sort((a, b) => (a.name > b.name) ? 1 : -1);
        props.data.setPrincipalRows(getUnique(principals,'id'));
        missions.sort((a, b) => (a.label > b.label) ? 1 : -1);
        props.data.setMissionRows(getUnique(missions,'id'));
        ports.sort((a, b) => (a.label > b.label) ? 1 : -1);
        props.data.setPortRows(getUnique(ports,'id'));
        insured.sort((a, b) => (a.name > b.name) ? 1 : -1);
        props.data.setInsuredRows(getUnique(insured,'id'));
        receivers.sort((a, b) => (a.name > b.name) ? 1 : -1);
        props.data.setReceiverRows(getUnique(receivers,'id'));

        if (props.initFilterMissions) props.initFilterMissions(rowsFromApi, props.param, props.paramValue);

    } catch (err) {
      if (err.response?.status === 401) {
          navigate("/logout", { replace: true });
      }
      else {
        props.data.setOpenSnackBar(true);
        console.log(err);
      }
    } finally
    {
      props.data.setLoadingGrid(false);
      props.setDataGridLoaded(true);
    }
  }

  function CustomNoRowsOverlay() {
    return (<div></div>);
  }

  function pad(num) {
      var s = "000000" + num;
      return s.substr(s.length-6);
  }

  const theme = createTheme({ palette: { mode: 'light' } });

  return (
      <>
      <ThemeProvider theme={theme}>
        <DataGridPro sx={{ height: '100%', width: '100%' }}
          apiRef={apiRef}
          rowHeight={167}
          components={{
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: 'created', sort: 'desc' }],
            },
          }}
          loading={props.data.loadingGrid}
          rows={props.rows}
          columns={columns}
          disableSelectionOnClick
          disableColumnMenu
          disableColumnFilter
          onRowClick={handleRowClick}
          filterModel={props.data.filterModel}
          onFilterModelChange={props.data.rowsSelected}
          // onStateChange={(state) => {
          //   const newRows = gridVisibleSortedRowIdsSelector(state);
          //   props.data.setVisibleRows(newRows);
          // }}
        />
        <Snackbar open={props.data.openSnackBar} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Technical error ! Please try again or contact our support.
          </Alert>
        </Snackbar>
      </ThemeProvider>
      </>
  );
}
