import React, { useEffect, useRef, forwardRef, useState } from 'react';

import { useNavigate } from "react-router-dom";
import MuiAlert from '@mui/material/Alert';

import Menu from 'components/page/section/Menu';
import { FilterProvider } from "context/ContextFilter";
import { checkSecurity } from 'components/config/Security';
import { isInternalRole, isCustomerRole } from 'components/config/Roles';

import DashboardMainList from 'components/page/section/DashboardMain/DashboardMainList';
import DashboardMainFilter from "components/page/section/DashboardMain/DashboardMainFilter";

import 'styles/globalPortalDashboard.css';
import 'styles/DashboardList.css';
import 'bootstrap/dist/css/bootstrap.css';

const Alert = forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const DashboardMain = (props) => {
  //config
  const navigate = useNavigate();
  const filterRef = useRef('filterRef');
  const filterName = !props?.archivedList == 1 ? 'filter' : 'filterArchived';
  const JLBCustomerMode = isCustomerRole(localStorage.getItem("role"));

  //
  const [hidePrincipal, setHidePrincipal] = useState(false);


  //CHECK SECURITY
  useEffect(() => {
    checkSecurity(navigate, 'DashboardList');
    if (JLBCustomerMode) setHidePrincipal(true);
  }, []);
  //END SECURITY


  //function
  function initFilterMissions(totalsRowParam) {
    filterRef.current.initFilter(totalsRowParam)
  }


  return (
    <div className='FULL-CONTENT DASHBOARD'>
      <FilterProvider>
        <div className="content">
          <DashboardMainFilter
            ref={filterRef}
            filterName={filterName}
            // Set visible filters
            visibleFilters={{
              statusFilter: true,
              surveyHandlerFilter: true,
              principalsFilter: true,
              missionTypeFilter: true,
              portFilter: true,
              insuredFilter: true,
              receiverFilter: true,
              vesselFilter: true,
              contractNumberFilter: true,
              cancelFilter: true,
            }}
            // Set visible categories 
            visibleCategories={{
              refJLBCategory: true,
              portCategory: true,
              principalsCategory: true,
              vesselCategory: true,
              billOfLadingCategory: true,
              warehouseCategory: true,
              receiverCategory: true,
              cargoCategory: true,
              traderCategory: true,
              insuredCategory: true,
              contractNumberCategory: true,
            }}
          />
          <div className="contentList">
            <DashboardMainList
              archivedList={props?.archivedList}
              initFilterMissions={initFilterMissions}
              // Set visible columns
              visibleColumns={{
                missionColorColumn: true,
                refJLBColumn: true,
                internalNoteColumn: hidePrincipal,
                publicNoteColumn: hidePrincipal,
                missionTypeLabelColumn: !hidePrincipal,
                createdDateColumn: true,
                statusColumn: true,
                contractNumberColumn: true,
                portColumn: true,
                principalsColumn: hidePrincipal,
                principalsRefColumn: !hidePrincipal,
                insuredColumn: true,
                vesselColumn: true,
                tonnageColumn: true,
                receiverColumn: true,
                billOfLadingColumn: true,
                warehouseColumn: true
              }}
            />
          </div>
        </div>
      </FilterProvider>
      <Menu />
    </div>
  )
}

export default DashboardMain;
