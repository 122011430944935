import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import MuiAlert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import axios from '../../../api/axios';
import { useNavigate } from "react-router-dom";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { saveAs } from 'file-saver';
import Moment from 'moment';
import {isInternalRole, isPortRole, isCustomerRole} from '../../config/Roles';
import '../../../styles/Popin.css';

const Alert = React.forwardRef(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ExportPopin = forwardRef((props, ref) => {
    const navigate = useNavigate();
    
    const [includeArchivedMissions, setIncludeArchivedMissions] = React.useState(false);
    const [loadingSave, setLoadingSave] = React.useState(false);
    
    useImperativeHandle(ref, () => ({
        async exportFiles() {
            await handleClickSave();
        },
    }));
    
    const handleIncludeArchivedMissions = (event) => {
        setIncludeArchivedMissions(!includeArchivedMissions);
    }
    
    const handleClose = () => {
        setIncludeArchivedMissions(false);
        props.setOpen(false)
    };
    
    const handleClickSave = async (e) => {
        
        if (props?.visibleRows.length > 0) {
          try {
            setLoadingSave(true);
            var finalExportUrl = includeArchivedMissions ? props?.exportUrl + '/v2' : props?.exportUrl
            
            const config = {
              url: finalExportUrl,
              responseType: 'arraybuffer',
              method: "post",
              headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                'Content-Type': 'application/json'
              },
              data: !includeArchivedMissions ? { id: props?.visibleRows } : null,
              params: {}
            }
            if (includeArchivedMissions) {
                // Hide Cocoa Departure missions
                config.params.hide_cocoa_departure = 1;
                
                // Filter by local office (Port)
                if (isPortRole(localStorage.getItem("role"))) {
                    config.params.id_local_office = parseInt(localStorage.getItem("id_company"));
                }
                
                // Insured filter
                var idInsuredFilter = props?.insuredRows?.find(insured => String(insured?.name) === String(props?.filterDatas?.insured))?.id;
                if (idInsuredFilter) {
                    config.params.id_insured = idInsuredFilter;
                }
                // Mission type filter
                var idMissionTypeFilter = props?.missionTypeRows?.find(missionType => String(missionType?.label) === String(props?.filterDatas?.missionType))?.id;
                if (idMissionTypeFilter) {
                    config.params.id_mission_type = idMissionTypeFilter;
                }
                // Port filter
                var idPortFilter = props?.portRows?.find(port => String(port?.label) === String(props?.filterDatas?.port))?.id;
                if (idPortFilter) {
                    config.params.id_port = idPortFilter;
                }
                // Principal filter
                var idPrincipalFilter = props?.principalRows?.find(principal => String(principal?.name) === String(props?.filterDatas?.principals))?.id;
                if (idPrincipalFilter) {
                    config.params.id_principal = idPrincipalFilter;
                }
                // Receiver filter
                var idReceiverFilter = props?.receiverRows?.find(receiver => String(receiver?.name) === String(props?.filterDatas?.receiver))?.id;
                if (idReceiverFilter) {
                    config.params.id_receiver = idReceiverFilter;
                }
                // Survey filter
                var idSurveyFilter = props?.surveyRows.find(survey => {const fullName = `${survey?.firstname} ${survey?.lastname}`; return String(fullName) === String(props?.filterDatas?.survey); })?.id;
                if (idSurveyFilter) {
                    config.params.id_survey_handler = idSurveyFilter;
                }
                // Vessel filter
                var idVesselFilter = props?.vesselRows?.find(vessel => String(vessel?.name) === String(props?.filterDatas?.vessel))?.id;
                if (idVesselFilter) {
                    config.params.id_vessel = idVesselFilter;
                }
                // Status filter
                if (props?.filterStatusArray.length > 0) {
                    config.params.status = props.filterStatusArray;
                }
                // Category filter
                if (props?.filterCategory) {
                    config.params.category = props.filterCategory;
                }
                // Text search 
                if (props?.filterSearchTxt.length > 0) {
                    config.params.search_txt = props.filterSearchTxt;
                }
            }
            const response = await axios(config);
  
            // Let the user save the file.
            var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
            saveAs(blob, 'Export_files_'+Moment().format('YYYYMMDD-HHmmss')+'.xlsx');
            props?.setIsDownload(true);
            if (props?.open) {
                props?.setOpen(false); 
            }
            setLoadingSave(false);
            setIncludeArchivedMissions(false);
          } catch (err) {
            if (err.response?.status === 401) {
                navigate("/logout", { replace: true });
            } else {
                props?.setOpenSnackBar(true);
            }
          }
        }
    }
    

    return (
        <Modal
            open={props.open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableAutoFocus
            className='modalPopin export'
        >
            <Box className='boxPopin' >
                <div className='headerPopin'>Export files</div>
                <div className='contentPopin'>
                    <FormControlLabel 
                        label='Include archived files'
                        control={<Checkbox checked={includeArchivedMissions} onChange={handleIncludeArchivedMissions} />}
                    />
                </div>
                <div className='footerPopin'>
                    <Box textAlign="center" sx={{ '& > button': { m: 1 } }}>
                        <LoadingButton
                            onClick={handleClose}
                            loadingIndicator="Cancel"
                            variant="outlined"
                            loading={loadingSave}
                        >
                            Cancel
                        </LoadingButton>

                        <LoadingButton
                            onClick={handleClickSave}
                            loading={loadingSave}
                            endIcon={<SaveIcon />}
                            loadingPosition="end"
                            variant="contained"
                        >
                            Save
                        </LoadingButton>
                    </Box>
                </div>
            </Box>
        </Modal>
    );
})

export default ExportPopin