import React, { useEffect, useState, forwardRef, useImperativeHandle, useContext } from 'react';
import { useNavigate } from "react-router-dom";

import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import MuiAlert from '@mui/material/Alert';
import Box from "@mui/material/Box";
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import AddIcon from '@mui/icons-material/Add';
import AddLinkIcon from '@mui/icons-material/AddLink';
import SyncIcon from '@mui/icons-material/Sync';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Close';
import EditTypeIcon from '@mui/icons-material/FileOpenOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditRightsIcon from '@mui/icons-material/ManageAccountsOutlined';

import axios from 'api/axios';
import momentDate from 'moment';
import { saveAs } from 'file-saver';

import { ContextBlock } from "context/ContextBlock";
import { ContextMissionDetails } from "context/ContextMissionDetails";
import { ContextMissionWorkflow } from "context/ContextMissionWorkflow";

import { isCustomerRole, isInternalRole } from 'components/config/Roles';
import MissionDetailsFilePopin from '../Popin/MissionDetailsFilePopin';
import DocumentsLinkBloc from "components/page/section/DocumentsLinkBloc";
import ConfirmDialog from 'components/page/object/ConfirmDialog';
import LinkGenerationPopin from 'components/page/section/LinkGenerationPopin';
import { DataGridPro, GridToolbarContainer, DataGridListValueFormatterCustom } from 'components/page/object/DataGridProCustom';

import "styles/detailsCommon.css";
import "styles/Popin.css";
import 'styles/detailsFiles.css';

const Alert = forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MissionDetailsFiles = forwardRef((props, ref) => {

  const navigate = useNavigate();

  //init param
  const {update} = useContext(ContextBlock);
  const [JLBCustomerMode] = useState(isCustomerRole(localStorage.getItem("role")));
  const [JLBInternalMode] = useState(isInternalRole(localStorage.getItem("role")));

  //generic
  const { openSnackBar, setOpenSnackBar } = useContext(ContextBlock);
  const { snackBarType, setSnackBarType } = useContext(ContextBlock);
  const { snackBarMessage, setSnackBarMessage } = useContext(ContextBlock);

  //workflow
  const {canRead} = useContext(ContextMissionWorkflow);
  const {canWrite} = useContext(ContextMissionWorkflow);
  const {canUpload} = useContext(ContextMissionWorkflow);
  const {controlDocuments} = useContext(ContextMissionWorkflow);
  const read = canRead('file_block');
  const write = canWrite('file_block');

  //mission data
  const {idMission, setIdMision} = useContext(ContextMissionDetails);
  const {missionStatus, setMissionStatus} = useContext(ContextMissionDetails);

  //filezone
  const [rowsFile, setRowsFile] = useState([]);
  const [selectedRowsFile, setSelectedRowsFile] = useState([]);
  const [selectedAllRowsFile, setSelectedAllRowsFile] = useState(false);
  const [multiUpdateTypeMode, setMultiUpdateTypeMode] = useState(false);
  const [multiUpdateRightsMode, setMultiUpdateRightsMode] = useState(false);
  const [multiDeleteMode, setMultiDeleteMode] = useState(false);
  const [confirmFileDeleteOpen, setConfirmFileDeleteOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState([]);
  const { reloadFileBlock, setReloadFileBlock } = useContext(ContextBlock);

  const [loadingTypes, setLoadingTypes] = useState(false);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [filePermissions, setFilePermissions] = useState([]);
  const [fileWritePermissions, setFileWritePermissions] = useState([]);
  const [rowsLink, setRowsLink] = useState([]);
  
  const [open, setOpen] = useState(false);
  const [syncInProgress, setSyncInProgress] = useState(false);
  const [fileUpload, setFileUpload] = useState(false);
  const [errorFile, setErrorFile] = useState(false);
  const [permission, setPermission] = useState();
  const [type, setType] = useState();
  const [typesOfFile, setTypesOfFile] = useState([]);

  const [openLinkPopin, setLinkPopinOpen] = useState(false);
  useImperativeHandle(ref, () => ({
    getFilesList() {
      return rowsFile;
    },
    getFileTypesList() {
      return typesOfFile;
    },
    getFilePermissionsList() {
      return filePermissions;
    },
    reloadFiles() {
      loadFiles();
    }
  }));
  
  useEffect(() => {
    //if mission is init
    if(!!idMission != false){
      loadTypes();
      loadPermissions();
      loadFiles();
      loadLinks();
    }
  }, [idMission]);

  useEffect(() => {
    loadTypes();
  }, [controlDocuments]);
  // END SECURITY

  useEffect(() => {
    if(reloadFileBlock === true){
      loadFiles();
      setReloadFileBlock(false);
    }
  }, [reloadFileBlock]);
  
  // Check/Uncheck the "Select All" checkbox if all rows are selected/unselected and so on
  useEffect(() => {
    const hasRows = rowsFile?.length > 0;
    const hasSelectedRows = selectedRowsFile?.length > 0;
    const hasAllRowsSelected = rowsFile.length === selectedRowsFile.length ;
    
    if ((hasRows && hasSelectedRows && hasAllRowsSelected && !selectedAllRowsFile) ||
      (hasRows && !hasSelectedRows && selectedAllRowsFile) ||
      (!hasRows && selectedAllRowsFile)) {
      handleSelectAllRowsFile();
    }
    if (hasRows && hasSelectedRows && !hasAllRowsSelected && selectedAllRowsFile) {
      setSelectedAllRowsFile(false);
    }
  }, [rowsFile, selectedAllRowsFile, selectedRowsFile])
  
  // Desactivate multiDeleteMode if delete popin was closed
  useEffect(() => {
    if (!confirmFileDeleteOpen && multiDeleteMode) {
      setMultiDeleteMode(false);
    }
  }, [confirmFileDeleteOpen, multiDeleteMode])
  
  const loadFiles = async () => {
    try {
      setLoadingFiles(false);
      const config = {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
        }
      };

      const response = await axios.get("mission/files?id_mission="+idMission, config);

      var rowsFromApi = [];
      for(var i=0; i < response.data.length; i++) {
        rowsFromApi[rowsFromApi.length] = { id:response.data[i].id, file: response.data[i].file.file_name, id_file_permission: response.data[i].id_file_permission, type:Number(response.data[i].id_file_type), url:response.data[i].file.file_path, mimetype:response.data[i].file.mime_type};
      }
      setRowsFile(rowsFromApi);

    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
          navigate("/logout", { replace: true });
      }
      else {
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    } finally {
      setLoadingFiles(true);
    }
  }

  const loadTypes = async () => {
    try {
      setLoadingTypes(false);
      const config = {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
        }
      };

      const response = await axios.get("file_type/list", config);

      //var rowsFromApiTOI = [{value:-1, label:'None'}];
      var rowsFromApiTOI = [];
      for(var i=0; i < response.data.length; i++) {
        if(response.data[i].display_in_files_list == 1){
          //workflow check if file type is enabled
          if(canUpload(response.data[i].id)){
            rowsFromApiTOI[rowsFromApiTOI.length] = {value:response.data[i].id, label:response.data[i].label};
          }
        }
      }
      rowsFromApiTOI.sort((a, b) => (a.label > b.label) ? 1 : -1);
      setTypesOfFile(rowsFromApiTOI);
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
          navigate("/logout", { replace: true });
      }
      else {
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    } finally {
      setLoadingTypes(true);
    }
  }

  const loadPermissions = async () => {
    try {
      const config = {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
        }
      };

      const response = await axios.get("file_permission/list", config);

      var apiRows = [];
      var writeRows = [];
      for(var i=0; i < response.data.length; i++) {
        var row = {value:response.data[i].id, label:response.data[i].label, can_write:response.data[i].can_write, can_be_shared:response.data[i].can_be_shared};
        apiRows[apiRows.length] = row;
        if(row.can_write == 1){
          writeRows[writeRows.length] = row;
        }
      }
      setFilePermissions(apiRows);
      setFileWritePermissions(writeRows);
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
          navigate("/logout", { replace: true });
      }
      else {
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    }
  }

  const loadLinks = async () => {
    try {
      const config = {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
        }
      };
      const response = await axios.get("mission/documents/link?id_mission="+idMission, config);
      var rowsFromApi = [];
      for(var i=0; i < response.data.links.length; i++) {
        rowsFromApi[rowsFromApi.length] = { 
          id:response.data.links[i].id, 
          files: response.data.links[i].files, 
          link: response.data.links[i].link, 
          created_by: response.data.links[i].creator.firstname+" "+response.data.links[i].creator.lastname, 
          created_on: momentDate(response.data.links[i].created_at).format("DD MMM YY")
        };
      }
      setRowsLink(rowsFromApi);
    } catch (err) {
      if (err.response?.status === 401) {
          navigate("/logout", { replace: true });
      }
    }
  }

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };
  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };
  
  // Mono update of a row, native to MUI
  const processRowUpdateFile = async (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    try {
      setLoadingFiles(false);
      const data = {
        id_mission: idMission,
        ids_mission_file: [updatedRow.id],
        id_file_permission: updatedRow.id_file_permission,
        id_file_type: updatedRow.type
      }

      var config = {
        url: "mission/files",
        method: 'put',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        },
        data: data,
      };
      const reponse = await axios(config);

      if (reponse) {
        setSnackBarMessage("File updated.");
        setSnackBarType("success");
        setOpenSnackBar(true); 
      }
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    } finally {
      setLoadingFiles(true);
    }

    setRowsFile(rowsFile.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };
  
  // Multi update of rows
  const multiUpdateRows = async () => {
    try {
      setLoadingFiles(false);
      
      const data = {
        id_mission: idMission,
        ids_mission_file: selectedRowsFile,
      }
      if (multiUpdateTypeMode) {
        data.id_file_type = Number(type)
      }
      
      if (multiUpdateRightsMode) {
        data.id_file_permission = Number(permission)
      }

      var config = {
        url: "mission/files",
        method: 'put',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        },
        data: data,
      };
      const reponse = await axios(config);
      
      setSnackBarMessage("Files updated.");
      setSnackBarType("success");
      setOpenSnackBar(true);
      
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    } finally {
      setLoadingFiles(true);
    }
    
    // Find all rows in "rowsFile" with the same id as "selectedRowsFile" and update their type or id_file_permission
    setRowsFile(
      rowsFile.map((row) => {
        if (selectedRowsFile.includes(row.id)) {
          if (multiUpdateTypeMode) {
            return { ...row, type: Number(type) };
          }
          if (multiUpdateRightsMode) {
            return { ...row, id_file_permission: Number(permission) };
          }
        }
        return row;
      })
    );
    
    setOpen(false);
    setMultiUpdateTypeMode(false);
    setMultiUpdateRightsMode(false);
    handleCancelSelectionClick();
  };
  
  const handleDeleteRow = (ids) => {
    setConfirmFileDeleteOpen(true);
    setFileToDelete(ids);
  }
  
  const handleUploadedFile = (row) => {
    setRowsFile((rowsFile) => [...rowsFile, row]);
  }
  
  var width = 50;
  if (write) width = 150;
  const columnsFile : GridColDef[] = [
    { field: 'id', headerName: '', editable: false, sortable: false, width: width,
        renderHeader: (cellValues) => {
          if (JLBInternalMode && write) {
            return (
                <Checkbox 
                    checked={selectedAllRowsFile}
                    onChange={handleSelectAllRowsFile}
                    disabled={!rowsFile?.length > 0 || !loadingFiles || !loadingTypes} 
                />
            );
          } else {
              return null;
          }
        },
        renderCell: (cellValues) => {
        const permission = filePermissions.find(obj => {
          return obj.value == cellValues.row.id_file_permission;
        });
        return (
          <div>
            {(JLBInternalMode && write && permission?.can_write) ? (
              <Checkbox 
                checked={selectedRowsFile.includes(cellValues.id)} 
                onChange={() => handleSelectRowsFile(cellValues.id)} 
              />
            ):""}
            
            {(JLBInternalMode && write && permission?.can_write) ? (
              <IconButton aria-label="delete" onClick={(event) => {
                handleDeleteRow(cellValues.id);
              }}>
                <DeleteIcon />
              </IconButton>
            ):""}

            {(JLBInternalMode && (read || (write && permission.can_write))) ? (
              <IconButton aria-label="edit" onClick={(event) => {
                editRowFile(event, cellValues);
              }}>
                <EditIcon />
              </IconButton>
            ):""}

            {(!JLBInternalMode && read) ? (
              <IconButton aria-label="download" onClick={(event) => {
                downloadRowFile(event, cellValues);
              }}>
                <DownloadIcon />
              </IconButton>
            ):""}
          </div>
        );
      }
    },
    { field: 'file', headerName: 'File', editable: false, sortable: false, flex:1},
    { field: 'type', headerName: 'Type', editable: JLBInternalMode, sortable: true, minWidth:250, flex:0.5, type:"singleSelect",
      valueOptions: typesOfFile,
      valueFormatter: ({ id: rowId, value, field, api }) => {
        return DataGridListValueFormatterCustom(rowId, value, field, api);
      },
    },
    { field: 'id_file_permission', headerName: 'Rights', editable: true, sortable: false, width:250, type:"singleSelect", hide:!JLBInternalMode,
      valueOptions: fileWritePermissions,
      valueFormatter: ({ id: rowId, value, field, api }) => {
        return DataGridListValueFormatterCustom(rowId, value, field, api);
      },
    },
    { field: 'url', headerName: 'url', editable: false, sortable: false, hide:true},
    { field: 'mimetype', headerName: 'mimetype', editable: false, sortable: false, hide:true},
  ];

  const deleteRowsFile = async (event, ids) => {
    try {
      setLoadingFiles(false);
      const data = {
        id_mission: idMission
      };
      
      if (Array.isArray(ids) && multiDeleteMode) {
        data.ids_mission_file = ids;
      } else {
        data.ids_mission_file = [ids];
      }

      var config = {
        url: "mission/files",
        method: 'delete',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        },
        data: data,
      };
      const reponse = await axios(config);

      setSnackBarMessage("File(s) deleted.");
      setSnackBarType("success");
      setOpenSnackBar(true);

      if (Array.isArray(ids)) {
        setRowsFile(rowsFile.filter((row) => !ids.includes(row.id)));
        setSelectedRowsFile(selectedRowsFile.filter((rowId) => !ids.includes(rowId)))
      } else {
        setRowsFile(rowsFile.filter((row) => row.id !== ids));
        setSelectedRowsFile(selectedRowsFile.filter((rowId) => rowId !== ids))
      }
    }catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    } finally {
      setLoadingFiles(true);
    }
  };
  
  const downloadRowFile = async (event, datas) => {
    try {
      const response = await axios.get(
        "mission/file",
        {
            params: { id_mission: idMission, id_mission_file:datas.row.id },
            responseType: 'arraybuffer',
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
              'content-type': datas.row.mimetype
            },
        },
      );
      // Let the user save the file.
      var blob = new Blob([response.data], { type: datas.row.mimetype });

      saveAs(blob, datas.row.file);

      setSnackBarMessage("File saved in your downloads folder.");
      setSnackBarType("success");
      setOpenSnackBar(true);
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    }
  };

  const syncFilesFromSharepoint = async() => {
    try {
      var data = new FormData();
      data.append('id_mission', idMission);

      var jsonRequestData = JSON.stringify(Object.fromEntries(data));

      var config = {
        url: "mission/files",
        method: 'patch',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        },
        data: jsonRequestData,
      };
      const response = await axios(config);
      
      var rowsFromApi = [];
      for(var i=0; i < response.data.length; i++) {
        rowsFromApi[rowsFromApi.length] = { id: response.data[i].id, file: response.data[i].file.file_name, id_file_permission: response.data[i]?.file_permission?.id ? Number(response.data[i].file_permission.id) : null, type: response.data[i]?.file_type?.id ? Number(response.data[i].file_type.id) : null, url:response.data[i].file.file_path, mimetype:response.data[i].file.mime_type};
      }
      setRowsFile(rowsFromApi);

      setSyncInProgress(false);
      setSnackBarMessage("Files synchronized with Sharepoint.");
      setSnackBarType("success");
      setOpenSnackBar(true);
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {console.log(err);
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    }
  }

  const editRowFile = (event, cellValues) => {
    window.open(cellValues.row.url, "_blank");
  };
  function CustomNoRowsOverlay() {
    return (<div className="customNoRowsOverlay"></div>);
  }


  const openSharepointFolderBlank = async() => {
    try{
      var config = {
        url: "mission/files/folderpath?id_mission=" + idMission,
        method: 'get',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      };
      const response = await axios(config);
      window.open(response.data.data.mission_folder_path, '_blank', 'noreferrer');
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    }
  }

  const handleClick = () => {
    //Open File Drop Zone
    setOpen(true);
    //INIT MODAL BEFORE
    setPermission(Number(0));
    setType(Number(0));
    setFileUpload(null);
    setErrorFile(false);
  };
  
  function EditToolbarFile(params) {

    const handleSyncClick = () => {
      setSyncInProgress(true);
      syncFilesFromSharepoint();
    }

    const handleOpenFolderClick = () => {
      openSharepointFolderBlank();
    }

    const handleGenerateLinkClick = () => {
      setLinkPopinOpen(true);
    }

    return (
      <GridToolbarContainer className="DataGridToolbar">
        {(!isCustomerRole(localStorage.getItem("role"))) && (
          <div>
            <div className="toolbarButton left">
              {(params.write) ? (
              <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Upload File
              </Button>
              ):""}
              <Button color="primary" startIcon={<SyncIcon />} onClick={handleSyncClick}>
                Sync from Sharepoint
              </Button>
              {syncInProgress && <CircularProgress size="1rem"/>}
              {(missionStatus !== 10) ? (
                <Button color="primary" startIcon={<AddLinkIcon />} onClick={handleGenerateLinkClick}>
                  Generate Link
                </Button>
              ):""}
            </div>
            <div className="toolbarButton right">
              <Button color="primary" startIcon={<FolderOpenIcon />} onClick={handleOpenFolderClick}>
                Open Sharepoint Folder
              </Button>
            </div>
          </div>
        )}
      </GridToolbarContainer>
    );
  }
  
  const handleSelectAllRowsFile = () => {
    if(!selectedAllRowsFile) {
      const tempSelectedRows = [];
      rowsFile.forEach(row => {
          const filePermission = filePermissions.find(obj => {
              return obj.value === row.id_file_permission;
          });
          // Check the permission to select the row
          if (!selectedRowsFile.includes(row.id) && write && filePermission?.can_write) {
            tempSelectedRows.push(row.id);
          }
      });
      setSelectedAllRowsFile(true)
      setSelectedRowsFile(prevSelectedRows => [...prevSelectedRows, ...tempSelectedRows]);
    } else {
      handleCancelSelectionClick();
    }
  }
  
  const handleSelectRowsFile = (id) => {
    setSelectedRowsFile(prevSelectedRows => {
      const index = prevSelectedRows.indexOf(id);
      if (index == -1) {
        return [...prevSelectedRows, id];
      } else {
        return prevSelectedRows.filter(item => item !== id);
      }
    });
  }
  
  const handleCancelSelectionClick = () => {
    setSelectedRowsFile([]);
    setSelectedAllRowsFile(false);
  }
  
  const handleEditSelectedRowsTypeClick = () => {
    handleClick();
    setMultiUpdateTypeMode(true);
      
  }
  
  const handleEditSelectedRowsRightsClick = () => {
    handleClick();
    setMultiUpdateRightsMode(true);
  }
  
  const handleDeleteSelectedRowsClick = () => {
    handleDeleteRow(selectedRowsFile);
    setMultiDeleteMode(true);
  }
  
  const MultiModificationFooter = () => {
    return (
      <div className="multiModificationFooter">
        { !loadingFiles || !loadingTypes ?    
          <div className="skeletonContainer">
            <Skeleton variant="rectangular" className="skeleton" />
          </div>
        :   
          <div className="buttonContainer">
            <Tooltip title="Cancel selection" placement="bottom">
              <IconButton
                onClick={handleCancelSelectionClick}
                size='large'
                className="cancelIconButton"
              >
                <CancelIcon fontSize="large" />
              </IconButton>
            </Tooltip>
            <div>{selectedRowsFile?.length} File(s)</div>
            <Tooltip title="Edit Type" placement="bottom">
              <IconButton
                onClick={handleEditSelectedRowsTypeClick}
                size='large'
                className="editTypeIconButton"
              >
                <EditTypeIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit Rights" placement="bottom">
              <IconButton
                onClick={handleEditSelectedRowsRightsClick}
                size='large'
                className="editRightsIconButton"
              >
                <EditRightsIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete" placement="bottom">
              <IconButton
                onClick={handleDeleteSelectedRowsClick}
                size='large'
                className="deleteIconButton"
              >
                <DeleteOutlineIcon />
              </IconButton>
            </Tooltip>
          </div>          
        }
      </div>
    );
  };  

  //if mission is init
  if(update == 1 && !!idMission != false && (write || read)){
    return (
      <div id="block_files" className="contentDetail block_details">
        <h4>Files</h4>
        <FormControl sx={{ m: 1, width: '100%' }} size="small" id="datagrid-files">
          <DataGridPro
            disableSelectionOnClick
            disableColumnMenu
            disableColumnFilter
            autoHeight
            rows={rowsFile}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdateFile}
            columns={columnsFile}
            experimentalFeatures={{ newEditingApi: true }}
            componentsProps={{
              toolbar: { rowsFile: setRowsFile, write: write },
            }}
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
              Toolbar: EditToolbarFile,
            }}
          />
          <LinearProgress hidden={loadingFiles && loadingTypes} />
        
          {selectedRowsFile?.length > 0 &&
            <MultiModificationFooter/>
          }
        </FormControl>

        <DocumentsLinkBloc 
          idMission={idMission}
          setSnackBarMessage={setSnackBarMessage}
          setSnackBarType={setSnackBarType}
          setOpenSnackBar={setOpenSnackBar}
          missionStatus={missionStatus}
          write={write}
          rowsLink={rowsLink}
        />

        <MissionDetailsFilePopin 
          idMission={idMission}
          handleUploadedFile={handleUploadedFile}
          setSnackBarMessage={setSnackBarMessage}
          setSnackBarType={setSnackBarType}
          setOpenSnackBar={setOpenSnackBar}
          setOpen={setOpen}
          multiUpdateTypeMode={multiUpdateTypeMode}
          setMultiUpdateTypeMode={setMultiUpdateTypeMode}
          multiUpdateRightsMode={multiUpdateRightsMode}
          setMultiUpdateRightsMode={setMultiUpdateRightsMode}
          multiUpdateRows={multiUpdateRows}
          loadingFiles={loadingFiles}
          setFileUpload={setFileUpload}
          setPermission={setPermission}
          setType={setType}
          setErrorFile={setErrorFile}
          open={open}
          permission={permission}
          type={type}
          fileUpload={fileUpload}
          errorFile={errorFile} 
          filePermissions={fileWritePermissions}
          typesOfFile={typesOfFile}
          disableTypeNone={'false'} 
        />
        <ConfirmDialog
          title={"Delete" + (multiDeleteMode ? (" " + selectedRowsFile?.length + " files") : " file") + " ?"}
          open={confirmFileDeleteOpen}
          setOpen={setConfirmFileDeleteOpen}
          onConfirm={deleteRowsFile}
          params={fileToDelete}
        >
          {"Are you sure you want to delete" + (multiDeleteMode ? " these files" : " this file") + " ?"}
        </ConfirmDialog>
        <LinkGenerationPopin
          idMission={idMission}
          setSnackBarMessage={setSnackBarMessage}
          setSnackBarType={setSnackBarType}
          setOpenSnackBar={setOpenSnackBar}
          open={openLinkPopin} 
          setOpen={setLinkPopinOpen}
          files={rowsFile}
          permissions={filePermissions}
          types={typesOfFile}
          setRowsLink={setRowsLink}
          rowsLink={rowsLink}
        />
      </div>
    );
  }
});

export default MissionDetailsFiles;
