import Dashboard from './components/page/Dashboard';
import DashboardList from './components/page/DashboardMain';
import PortDashboard from './components/page/section/Port/PortDashboard';
import MissionDetails from './components/page/MissionDetails';
import MissionDocuments from './components/page/MissionDocuments';
import ShippingInstruction from './components/page/ShippingInstruction';
import Portal from './components/page/Portal';
import MailingLists from './components/page/mailing/MailingLists/MailingLists';
import EditMailingList from './components/page/mailing/EditMailingList';
import Companies from './components/page/Companies';
import EditCompany from './components/page/EditCompany';
import AnalyticsDashboard from './components/page/analytics/AnalyticsDashboard';
import Routing from './components/config/Routing';
import Login from './components/page/Login';
import Logout from './components/page/Logout';
import RenewPassword from './components/page/RenewPassword';
import RenewPasswordDone from './components/page/RenewPasswordDone';
import SetNewPassword from './components/page/SetNewPassword';
import SetNewPasswordDone from './components/page/SetNewPasswordDone';
import ProtisSignUp from './components/page/ProtisSignUp';
import ProtisSignUpDone from './components/page/ProtisSignUpDone';
import ProtisPrivacyPolicy from './static/protisPrivacyPolicy';
import { Routes, Route } from 'react-router-dom';
import { randomId } from '@mui/x-data-grid-generator';
import React  from "react";
function App() {

  return (
    <Routes>
      <Route path="/login" element={<Login key={randomId()} />} />
      <Route path="/logout" element={<Logout key={randomId()} />} />
      <Route path="/protis/privacy-policy" element={<ProtisPrivacyPolicy key={randomId()} />} />
      <Route path="/protis/sign-up" element={<ProtisSignUp key={randomId()} />} />
      <Route path="/protis/sign-up/done" element={<ProtisSignUpDone key={randomId()} />} />
      <Route path="/renewPassword" element={<RenewPassword key={randomId()} />} />
      <Route path="/renewPasswordDone" element={<RenewPasswordDone key={randomId()} />} />
      <Route path="/setNewPassword" element={<SetNewPassword key={randomId()} />} />
      <Route path="/setNewPasswordDone" element={<SetNewPasswordDone key={randomId()} />} />
      <Route path="/dashboard" element={<Dashboard key={randomId()} />} />
      <Route path="/dashboard-list" element={<DashboardList key={randomId()} />} />
      <Route path="/dashboard-port" element={<PortDashboard key={randomId()} />} />
      <Route path="/archived-list" element={<DashboardList archivedList='1' key={randomId()} />} />
      <Route path="/archived-list-port" element={<PortDashboard archivedList='1' key={randomId()} />} />
      <Route path="/missionDetails" element={<MissionDetails key={randomId()} />} />
      <Route path="/updateMission" element={<MissionDetails update='1' key={randomId()} />} />
      <Route path="/createMission" element={<MissionDetails update='0' key={randomId()} />} />
      <Route path="/portal" element={<Portal key={randomId()} />} />
      <Route path="/shippingInstructionDetails" element={<ShippingInstruction key={randomId()} />} />
      <Route path="/updateShippingInstruction" element={<ShippingInstruction update='1' key={randomId()} />} />
      <Route path="/createShippingInstruction" element={<ShippingInstruction update='0' key={randomId()} />} />
      <Route path="/mailing-lists" element={<MailingLists key={randomId()} />} />
      <Route path="/updateMailingList" element={<EditMailingList update='1' key={randomId()} />} />
      <Route path="/createMailingList" element={<EditMailingList update='0' key={randomId()} />} />
      <Route path="/companies-list" element={<Companies key={randomId()} />} />
      <Route path="/updateCompany" element={<EditCompany update='1' key={randomId()} />} />
      <Route path="/createCompany" element={<EditCompany update='0' key={randomId()} />} />
      <Route path="*" element={<Routing key={randomId()} />} />
      <Route path="/missionDocuments" element={<MissionDocuments key={randomId()} />} />
      <Route path="/dashboard-analytics" element={<AnalyticsDashboard key={randomId()} />} />
    </Routes>
  );
}

export default App;
