import React, { forwardRef, useContext } from 'react';
import FormControl from '@mui/material/FormControl';
import { DataGridPro } from '@mui/x-data-grid-pro';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LaunchIcon from '@mui/icons-material/Launch';

import { ContextMissionDetails } from "context/ContextMissionDetails";

const DocumentsLinkBloc = forwardRef((props, ref) => {
  
  const {CustomNoRowsOverlay} = useContext(ContextMissionDetails);
  
  const columnsFile : GridColDef[] = [
    { field: 'id', headerName: '', editable: false, sortable: false, width: 50,},
    { field: '-', headerName: '', editable: false, sortable: false, width: 100,
    renderCell: (cellValues) => {
      return (
        <div>
          <IconButton 
            aria-label="Copy to clipboard"
            title="Copy to clipboard"
            disabled={props.missionStatus == 10}
            onClick={(event) => {
                navigator.clipboard.writeText(cellValues.row.link)
                props.setSnackBarMessage("Link successfully copied to clipboard");
                props.setSnackBarType("success");
                props.setOpenSnackBar(true);
            }}>
            <ContentCopyIcon />
          </IconButton>
          <IconButton 
            aria-label="Open in new window"
            title="Open in new window"
            target="_blank"
            disabled={props.missionStatus == 10}
            href={cellValues.row.link}
            >
            <LaunchIcon />
          </IconButton>
        </div>
      );
    }},
    { field: 'created_by', headerName: 'Created By', editable: false, sortable: true, width:200},
    { field: 'created_on', headerName: 'Created On', editable: false, sortable: true, width:200},
    { field: 'files', headerName: 'Files', editable: false, sortable: true, flex:1,
      renderCell: (cellValues) => {
        var txt = "";
        var prefix = "";
  
        for(var i=0; i <  cellValues.row.files.length; i++) {
          txt = txt + prefix + cellValues.row.files[i].file_type.label+" - "+cellValues.row.files[i].file.file_name;
          prefix = "\n\r";
        }

        return (
        <div>
          <span>{cellValues.row.files.length}</span>
          <Tooltip
            title={<span style={{ whiteSpace: 'pre-line' }}>{txt}</span>}
            placement="right"
          >
              <IconButton>
                <FormatListBulletedIcon />
              </IconButton>
          </Tooltip>
        </div>
        );
      }
    }
  ];

  //if mission is init
  if(props.rowsLink.length > 0){
    return (
      <div>
        <br />
        <h4>Public links</h4>
        <FormControl sx={{ m: 1, width: '100%' }} size="small" id="datagrid-links">
          <DataGridPro
            disableSelectionOnClick
            disableColumnMenu
            disableColumnFilter
            autoHeight
            density="compact"
            hideFooter
            rows={props.rowsLink}
            columns={columnsFile}
            experimentalFeatures={{ newEditingApi: true }}
            componentsProps={{
              toolbar: {write: props.write },
            }}
            components={{
              NoRowsOverlay: CustomNoRowsOverlay
            }}
          />
        </FormControl>
      </div>
    );
  }
});

export default DocumentsLinkBloc;
