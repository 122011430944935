import React, { useRef, useState, useEffect, createContext } from "react";


const ContextBlock = createContext({
  update: 0,
  refs: { elmtRef: { ref: null } },
  blocks: { blockRef: { ref: null, workflowLabel: null, allowCreation: false, saveOrder: 0 } }
});

const BlockProvider = ({ children, value }) => {

  //init config
  const queryParams = new URLSearchParams(window.location.search);
  const actionSave = queryParams.get('actionSave');

  //block config
  const elmtListRefs = value?.refs;
  const blockListRefs = value?.blocks;
  const [initBlock, setInitBlock] = useState(false);
  const [blockEdit, setBlockEdit] = useState([
    { blockRef: null, reload: false, onEdit: false }
  ]);
  const [blockGrid, setBlockGrid] = useState([
    { blockRef: null, onError: false, fieldsOnError: {} }
  ]);
  const [blockSave, setBlockSave] = useState([
    { blockRef: null, workflowLabel: null, allowCreation: false, order: 0, onSave: { start: false, done: false, error: false } }
  ]);

  //composant ref
  const generalRef = blockListRefs.generalRef.ref;

  //treatment
  const [blockOnSave, setBlockOnSave] = useState(false);
  const [blockOrderSave, setBlockOrderSave] = useState(0);
  const [saveIsInError, setSaveIsInError] = useState(false);
  const [saveIsFinished, setSaveIsFinished] = useState(false);
  const [reloadFileBlock, setReloadFileBlock] = useState(false);

  //composant company ref
  const companyPrincipalsSelectRef = useRef("principalsSelectRef");
  const companyTraderSelectRef = useRef("traderSelectRef");
  const companyInsuredSelectRef = useRef("insuredSelectRef");
  const companyLocalOfficeSelectRef = useRef("localOfficeSelectRef");
  const companyWarehouseKeeperSelectRef = useRef("warehouseKeeperSelectRef");

  //init param
  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarType, setSnackBarType] = useState("");

  //init mission
  var elementIdParam = parseInt(queryParams.get('id'));
  if (isNaN(elementIdParam)) elementIdParam = 0;
  const update = elementIdParam > 0 ? 1 : value?.update;

  const [idElement, setIdElement] = useState(elementIdParam);

  //info popin
  const [infosPopinMsg, setInfosPopinMsg] = useState('');
  const [infosPopinType, setInfosPopinType] = useState('');
  const [infosPopinTitle, setInfosPopinTitle] = useState('');
  const [openInfosPopin, setOpenInfosPopin] = useState(false);


  //init block
  useEffect(() => {
    const blockEditList = [];
    const blockGridList = [];
    const blockSaveList = [];
    if (blockListRefs) {
      if (Object.keys(blockListRefs).length > 0) {
        var i = 0;
        for (const [key, block] of Object.entries(blockListRefs)) {
          blockEditList.push({ blockRef: block.ref, reload: false, onEdit: false });
          blockGridList.push({ blockRef: block.ref, onError: false, fieldsOnError: {} });
          blockSaveList.push({ 
            blockRef: block.ref, 
            workflowLabel: block?.workflowLabel ? block.workflowLabel : null, 
            allowCreation: block?.allowCreation ? block.allowCreation : false, 
            saveOrder: typeof block?.saveOrder !== 'undefined' ? block.saveOrder : i,
            onSave: { start: false, done: false, error: false } 
          });
          i++;
        }
      }
    }
    setBlockEdit(blockEditList);
    setBlockGrid(blockGridList);
    setBlockSave(blockSaveList);
  }, []);

  useEffect(() => {
    if (!initBlock) {
      if (blockEdit.length > 0 && blockSave.length > 0) {
        setInitBlock(true);
      }
    }
  }, [blockEdit, blockSave]);


  //function interactive
  function setSnackErrorMsg(data) {
    var errorMsg = "Technical error ! Please try again or contact our support.";
    /*if(typeof data != "undefined"){
      var responseMsg = "";
      var errorData = Array.from(new Map(Object.entries(data)));
      if(Array.isArray(errorData) && errorData.length >= 1){
        errorData.map((err, i) => {
          if(typeof err != String){
            err.map((msg, k) => {
              if(Array.isArray(msg)){
                msg.map((txt, idx) => {
                  responseMsg += "\n" + txt;
                });
              }
            });
          }else{
            responseMsg += "\n" + err;
          }
        });
      }else{
        responseMsg += "\n" + errorData;
      }
      errorMsg = errorMsg + responseMsg;
    }*/

    return errorMsg;
  }

  //INIT FUNC RELOAD
  const setReloadBlockData = (blockRef, reload = false) => {
    //cancel edit for this blockRef
    var blocks = blockEdit.map((block) => {
      if (block.blockRef === blockRef) block = { ...block, reload: reload };
      return block;
    });
    //maj list of blocks
    setBlockEdit(blocks);
  }

  //INIT FUNC EDIT
  const setCancelEdit = (reload = false) => {
    //cancel edit for this blockRef
    var blocks = blockEdit.map((block) => {
      if (block.onEdit === true) {
        block = { ...block, onEdit: false };
        if (reload) block = { ...block, reload: true };
      }
      return block;
    });
    //maj list of blocks
    setBlockEdit(blocks);
  }

  const setCancelBlockEdit = (blockRef, reload = false) => {
    //cancel edit for this blockRef
    var blocks = blockEdit.map((block) => {
      if (block.blockRef === blockRef) {
        block = { ...block, onEdit: false };
        if (reload) block = { ...block, reload: true };
      }
      return block;
    });
    //maj list of blocks
    setBlockEdit(blocks);
  }

  const setActiveEdit = () => {
    //cancel edit for this blockRef
    var blocks = blockEdit.map((block) => {
      block = { ...block, onEdit: true };
      return block;
    });
    //maj list of blocks
    setBlockEdit(blocks);
  }

  const setActiveBlockEdit = (blockRef) => {
    //active edit for this blockRef
    var blocks = blockEdit.map((block) => {
      if (block.blockRef === blockRef) block = { ...block, onEdit: true };
      return block;
    });
    //maj list of blocks
    setBlockEdit(blocks);
  }

  //INIT FUNC SAVE
  const setResetSave = () => {
    //reset save for all
    var blocks = blockSave.map((block) => {
      block = { ...block, onSave: { start: false, done: false, error: false } };
      return block;
    });
    //maj list of blocks
    setBlockSave(blocks);
    setBlockOnSave(false);
    setBlockOrderSave(0);
  }

  const setResetBlockSave = (blockRef) => {
    //reset save for this blockRef
    var blocks = blockSave.map((block) => {
      if (block.blockRef === blockRef) block = { ...block, onSave: { start: false, done: false, error: false } };
      return block;
    });
    //maj list of blocks
    setBlockSave(blocks);
  }

  const setStartSave = () => {
    //reset save for all
    var blocks = blockSave.map((block) => {
      block = { ...block, onSave: { start: true, done: false, error: false } };
      return block;
    });
    //maj list of blocks
    setBlockSave(blocks);
    setBlockOnSave(true);
    setSaveIsInError(false);
    setSaveIsFinished(false);

    setLoading(true);
    setLoadingUpdate(false);
  }

  const setStartBlockSave = (blockRef) => {
    //start save for this blockRef
    var blocks = blockSave.map((block) => {
      if (block.blockRef === blockRef) block = { ...block, onSave: { start: true, done: false, error: false } };
      return block;
    });
    //maj list of blocks
    setBlockSave(blocks);
  }

  const setDoneBlockSave = (blockRef) => {
    //done save for this blockRef
    var blocks = blockSave.map((block) => {
      if (block.blockRef === blockRef) {
        block = { ...block, onSave: { start: true, done: true, error: false } };
        setBlockOrderSave(block.saveOrder + 1);
      }
      return block;
    });
    //maj list of blocks
    setBlockSave(blocks);
  }

  const setDoneBlockMultiSave = (blockRefs = []) => {
    //done save for this multi blockRefs
    var blocks = blockSave.map((block) => {
      var blockOnSave = blockRefs.findIndex(obj => (obj === block.blockRef));
      if (blockOnSave >= 0) {
        block = { ...block, onSave: { start: true, done: true, error: false } };
      }
      return block;
    });
    //maj list of blocks
    setBlockSave(blocks);
  }

  const setErrorBlockSave = (blockRef) => {
    //error save for this blockRef
    var blocks = blockSave.map((block) => {
      if (block.blockRef === blockRef) block = { ...block, onSave: { start: true, done: false, error: true } };
      return block;
    });
    //maj list of blocks
    setBlockSave(blocks);
  }

  //INIT FUNC GRID
  const setFieldErrorBlockGrid = (blockRef, field, error) => {
    //field not on error for this blockRef
    var blocks = blockGrid.map((block) => {
      if (block.blockRef === blockRef) {
        var fields = block.fieldsOnError;
        fields = { ...fields, [field]: error };
        block = { ...block, fieldsOnError: fields };
      }
      return block;
    });
    //maj list of blocks
    setBlockGrid(blocks);
  }

  const getErrorBlockGrid = (blockRef) => {
    //error ot not for this blockRef
    var onError = false
    blockGrid.map((block) => {
      if (block.blockRef === blockRef && Object.keys(block.fieldsOnError).length > 0) {
        for (const [field, value] of Object.entries(block.fieldsOnError)) {
          if (value === true) onError = true;
        }
      }
    });
    return onError;
  }

  const getErrorGrid = () => {
    //error ot not on one block
    var onError = false
    blockGrid.map((block) => {
      if (Object.keys(block.fieldsOnError).length > 0) {
        for (const [field, value] of Object.entries(block.fieldsOnError)) {
          if (value === true) onError = true;
        }
      }
    });
    return onError;
  }

  //handle button
  const handleClickBlockEdit = (blockRef) => {
    setActiveBlockEdit(blockRef);
  }
  const handleClickBlockCancel = (blockRef, reload = true) => {
    setCancelBlockEdit(blockRef, reload);
  }

  // REFRESH SELECT COMPANY
  function companySelectRefresh() {
    var blockOnEdit = blockEdit.findIndex(obj => (obj.blockRef === generalRef && obj.onEdit === true));
    if (blockOnEdit >= 0) {
      companyPrincipalsSelectRef.current.getList();
      companyTraderSelectRef.current.getList();
      companyInsuredSelectRef.current.getList();
      companyLocalOfficeSelectRef.current.getList();
      companyWarehouseKeeperSelectRef.current.getList();
    }
  }

  function triggerInfosPopin(title, msg, type) {
    //open error poppin
    setOpenInfosPopin(true);
    setInfosPopinMsg(msg);
    if (typeof type == "string")
      setInfosPopinType(type);
    if (typeof title == "string")
      setInfosPopinTitle(title);
  }

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  function CustomNoRowsOverlay() {
    return (<div className="customNoRowsOverlay"></div>);
  }


  return (
    <ContextBlock.Provider
      value=
      {{
        //config context
        update,
        actionSave,
        blockListRefs, elmtListRefs,
        idElement, setIdElement,

        //composant data ref
        companyPrincipalsSelectRef, companyTraderSelectRef, companyInsuredSelectRef, companyLocalOfficeSelectRef, companyWarehouseKeeperSelectRef,

        //composant company ref
        companySelectRefresh,

        //block interaction
        initBlock, setInitBlock,
        blockEdit, setBlockEdit,
        blockGrid, setBlockGrid,
        blockSave, setBlockSave,
        setFieldErrorBlockGrid, getErrorBlockGrid, getErrorGrid,
        setReloadBlockData, setCancelEdit, setCancelBlockEdit, setActiveBlockEdit, setActiveEdit,
        setResetSave, setResetBlockSave, setStartSave, setStartBlockSave, setDoneBlockSave, setDoneBlockMultiSave, setErrorBlockSave,
        handleClickBlockEdit, handleClickBlockCancel,

        //treatment
        blockOnSave, setBlockOnSave,
        blockOrderSave, setBlockOrderSave,
        saveIsInError, setSaveIsInError,
        saveIsFinished, setSaveIsFinished,
        reloadFileBlock, setReloadFileBlock,

        //generic data
        loading, setLoading,
        loadingUpdate, setLoadingUpdate,

        openSnackBar, setOpenSnackBar,
        snackBarType, setSnackBarType,
        snackBarMessage, setSnackBarMessage,
        setSnackErrorMsg,

        //popin
        triggerInfosPopin,
        infosPopinMsg, setInfosPopinMsg,
        openInfosPopin, setOpenInfosPopin,
        infosPopinType, setInfosPopinType,
        infosPopinTitle, setInfosPopinTitle,

        //html
        CustomNoRowsOverlay,
        handleRowEditStart,
        handleRowEditStop
      }}
    >
      {children}
    </ContextBlock.Provider>
  );
};

export { BlockProvider, ContextBlock };
