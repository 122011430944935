import React, { useEffect, SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { forwardRef, useImperativeHandle } from "react";

import Box from '@mui/material/Box';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FormHelperText from '@mui/material/FormHelperText';
import LinearProgress from '@mui/material/LinearProgress';
import AddElement from 'components/page/object/AddElement';
import DetailField from 'components/page/object/DetailField';
import { randomId } from "@mui/x-data-grid-generator";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {isCustomerRole} from 'components/config/Roles';
import moment from 'moment';
import axios from 'api/axios';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Alert = React.forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SelectAutoCustom = forwardRef((props,ref) => {

    const navigate = useNavigate();
    const [dataFilter, setDataFilter] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [snackBarMessage, setSnackBarMessage] = React.useState("");
    const [snackBarType, setSnackBarType] = React.useState("");
    const [duplicate, setDuplicate] = React.useState(false);
    const [JLBCustomerMode] = React.useState(isCustomerRole(localStorage.getItem("role")));

    const handleCloseSnackbar = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpenSnackBar(false);
    };

    useImperativeHandle(ref, () => ({
      async getList() {
         await getList();
      },
   }));

    const getList = async (e) => {
      try {
        setLoading(false);
          const config = {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
              }
          };
          
          var getValue = "";
          if (props.apiParam.length > 0) {
            getValue = "?"+props.apiParam+"="+props.apiParamValue;
          }

          //GESTION DU CACHE
          if (localStorage.getItem(props.api+getValue+"TTL")) {
            if (moment.unix(localStorage.getItem(props.api+getValue+"TTL")).unix() <  moment().unix()) {
              localStorage.removeItem(props.api+getValue);
              localStorage.removeItem(props.api+getValue+"TTL");
            }
          }

          if (!localStorage.getItem(props.api+getValue)||!props.cached) {
            var response = await axios.get(props.api+getValue, config);
            localStorage.setItem(props.api+getValue, JSON.stringify(response));
            if (props.cacheTTL) {
              localStorage.setItem(props.api+getValue+"TTL", Number(moment().unix())+Number(props.cacheTTL));
            }
          }else {
            var response = JSON.parse(localStorage.getItem(props.api+getValue));
          }
          var result = Array.isArray(response.data) ? response.data : Array.isArray(response.data?.data) ? response.data?.data : [];
          //END OF CACHE

          var rowsFromApi = [];
          for(var i=0; i < result.length; i++) {
            if (!!props?.resultFormat !== false) {
              if (Object.keys(props?.resultFormat).length > 0) {
                for (const [key, value] of Object.entries(props?.resultFormat)) {
                  var formatValue = '';
                  if (Array.isArray(value) && value.length > 0) {
                    value.map((v, k) => {
                      if (result[i][v]) {
                        formatValue += result[i][v] + ' ';
                      } else {
                        formatValue += v + ' ';
                      }
                    });
                  } else {
                    formatValue = result[i][value];
                  }
                  result[i] = {...result[i], [key]: formatValue};
                }
              }
            }

            rowsFromApi[i] = {};
            rowsFromApi[i] = props.creator(result[i]);
          }
          
          var sort = true;
          if (props.nosort) sort = false;
          if (sort == true) {
            rowsFromApi.sort((a, b) => (a.labelValue > b.labelValue) ? 1 : -1);
          }
          setDataFilter(rowsFromApi.filter((value, index, self) =>
            index === self.findIndex((t) => (
              t.label === value.label
            ))
          ));

      } catch (err) {
        if (err.response?.status === 401) {
            navigate("/logout", { replace: true });
        }
        else {
          setSnackBarMessage("Technical error ! Please try again or contact our support.");
          setSnackBarType("error");
          setOpenSnackBar(true);
          console.log(err);
        }
      } finally
      {
        setLoading(true);
      }
    }

  useEffect(() => {
    getList();
    if (props.duplicate) setDuplicate(true);
  }, []);

  useEffect(() => {
    getList();
  }, [props.apiParamValue]);

  var paddingRight = "0px";
  if (props.enableAddButton)
  {
      paddingRight = "30px";
  }

  var size='18%';
  if(props.size)
  {
    if (props.size == 'large')
    {
      size='95%';
    }
  }

  const handleClose = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  };

  function setNewElement(data, label, alias){
    setDataFilter((oldRows) => [...oldRows, props.creator(data)]);
    if (props.targetItem) {
      props.setDataValue(data.id, label, alias, props.targetItem)
    } else {
      props.setDataValue(data.id, label, alias, data);
    }
  }

  function renderOptionCustom(option, selected) {
    if (!props?.multiple) {
      var optionCustom = '';
      if (props?.renderOption) {
        if (Array.isArray(props?.renderOption) && props?.renderOption.length > 0) {
          props?.renderOption.map((v, k) => {
            if (option[v]) {
              optionCustom += option[v] + ' ';
            } else {
              optionCustom += v + ' ';
            }
          });
        } else {
          optionCustom = option[props?.renderOption];
        }
      }
      return optionCustom;
    } else {
      return (
        <>
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          checked={selected}
        />
        { option.title }
        </>
      );
    }
  }

  var canRead = 1;
  var canWrite = 1;
  var isRequired = false;

  if (typeof props?.controlFieldsStatus != "undefined" && props?.controlFieldsStatus)
  {
    canRead = props?.read;
    canWrite = props?.write;
    isRequired = props?.required;
  }else if(typeof props?.required != "undefined"){
    isRequired = props?.required;
  }

  var requiredStar = "";
  if (isRequired) requiredStar = " *";

  if (canWrite){
    return (
      <FormControl className={props.error?"textField-form error-forelm":"textField-form"} sx={{ m: 1, width: size, paddingRight: paddingRight, marginLeft: 1, marginRight: 1, marginTop: 0, marginBottom: 0 }} size="small" error={props.error} required={isRequired}>
        <Autocomplete
          onMouseDownCapture={typeof props?.openOnFocus !== 'undefined' && !props?.openOnFocus ? (e) => e.stopPropagation() : null}
          openOnFocus={typeof props?.openOnFocus !== 'undefined' ? props?.openOnFocus : true}
          multiple={props?.multiple ? true : false}
          disableCloseOnSelect={props?.multiple ? true : false}
          className={props?.enableUpdButton ? 'upd' : ''}
          componentsProps={{
            paper: {
              sx: !props?.sizeComponents ? {
                width: 300
              } : {
                width: props?.sizeComponents
              }
            }
          }}
          clearOnEscape
          freeSolo={props?.freeSolo ? props?.freeSolo : false}
          id={'combo-box-Autocomplete-' + randomId()}
          options={dataFilter}
          renderOption={props?.renderOption ? (props, option, { selected }) => {
            const { key, ...optionProps } = props;
            return (
              <Box component="li" key={key} {...optionProps}>
                {renderOptionCustom(option, selected)}
              </Box>
            );
          } : null}
          value={props.dataValue}
          disabled={props.disabled}
          autoHighlight
          onChange={(event, newValue) => {
            if (newValue) props.setDataValue(newValue.id, newValue.label, newValue.alias, props.targetItem);
            else if (props?.freeSolo) props.setDataValue(0, event.target.textContent, event.target.textContent, props.targetItem);
            else props.setDataValue(0, null, props.defaultAlias, props.targetItem);
          }}
          renderInput={(params) => (
            <TextField {...params} label={props?.title+requiredStar} variant={props?.variant ? props.variant : "standard"} error={props.error} onBlur={(event) => {
              if (props?.freeSolo) {
                const exist = dataFilter.find(obj => obj.label === event.target.defaultValue);
                const id = exist ? exist.id : 0;
                props.setDataValue(id, event.target.defaultValue, event.target.defaultValue, props.targetItem);
              }
            }} />
          )}
          isOptionEqualToValue={(option, value) => option.labelValue === value}
        />
        {Boolean(props?.enableUpdButton) && (
          <AddElement
            {...props}
            upd={true}
            api={props.apiUpdButton}
            setNewElement={setNewElement}
            setOpenSnackBar={setOpenSnackBar}
            setSnackBarMessage={setSnackBarMessage}
            setSnackBarType={setSnackBarType}
            duplicate={duplicate}
            datas={dataFilter}
            dataValueId={props?.dataValueId}
            parentApi={props.api}
            parentApiParam={!!props.apiParam != false ? props.apiParam : ''}
            parentApiParamValue={!!props.apiParamValue != false ? props.apiParamValue : ''}
            resetCache={typeof props.resetCache != "undefined" ? props.resetCache : "0"}
          />
        )}
        {Boolean(props.enableAddButton) && (
          <AddElement
            {...props}
            api={props.apiAddButton}
            setNewElement={setNewElement}
            setOpenSnackBar={setOpenSnackBar}
            setSnackBarMessage={setSnackBarMessage}
            setSnackBarType={setSnackBarType}
            duplicate={duplicate}
            datas={dataFilter}
            parentApi={props.api}
            parentApiParam={!!props.apiParam != false ? props.apiParam : ''}
            parentApiParamValue={!!props.apiParamValue != false ? props.apiParamValue : ''}
            resetCache={typeof props.resetCache != "undefined" ? props.resetCache : "0"}
            classType={props?.enableUpdButton ? props.classType + ' upd' : props.classType }
          />
        )}
        {(props.error && (!!props.helperText != false)) && <FormHelperText sx={{ ml: 0}}>{props.helperText}</FormHelperText>}
        <LinearProgress hidden={loading}  />
        <Snackbar open={openSnackBar} autoHideDuration={4000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <Alert onClose={handleCloseSnackbar} severity={snackBarType} sx={{ width: '100%' }}>
            {snackBarMessage}
          </Alert>
        </Snackbar>
      </FormControl>
    );
  }

  if (canRead){
    var theValue = "-";
    if (props.dataValue) theValue = props.dataValue.toUpperCase().trim();

    return (<DetailField title={props.title.toUpperCase().trim()} content={theValue} size={!JLBCustomerMode ? '':'large'} canRead={canRead} />);
  }
})
export default SelectAutoCustom
