import React, { useEffect, useState, useContext } from 'react';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Badge from '@mui/material/Badge';
import Rating from '@mui/material/Rating';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import LinearProgress from '@mui/material/LinearProgress';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import momentDate from 'moment';

import FilterStatusList from 'components/filter/FilterStatusList';
import {isInternalRole, isPortRole, isCustomerRole} from 'components/config/Roles';

import { ContextBlock } from "context/ContextBlock";
import { ContextMissionDetails } from "context/ContextMissionDetails";
import { ContextMissionWorkflow } from "context/ContextMissionWorkflow";
import { ContextMissionDetailsHeader } from "./ContextMissionDetailsHeader";


const MissionDetailsHeaderContent = (props) => {

  //init config
  const [JLBPortMode] = useState(isPortRole(localStorage.getItem("role")));
  const [JLBInternalMode] = useState(isInternalRole(localStorage.getItem("role")));
  const [JLBCustomerMode] = useState(isCustomerRole(localStorage.getItem("role")));

  //init workflow
  const {workflowLoaded, setWorkflowLoaded} = useContext(ContextMissionWorkflow);

  //init generic
  const { loadingUpdate, setLoadingUpdate } = useContext(ContextBlock);

  //mission header data
  const {idMission, setIdMission} = useContext(ContextMissionDetails);
  const {missionRef, setMissionRef} = useContext(ContextMissionDetails);
  const {missionColor, setMissionColor} = useContext(ContextMissionDetailsHeader);
  const {missionCanceled, setMissionCanceled} = useContext(ContextMissionDetails);

  const {pinned, setPinned} = useContext(ContextMissionDetails);

  const {createdMissionDate, setCreatedMissionDate} = useContext(ContextMissionDetails);
  const {modifiedMissionDate, setModifiedMissionDate} = useContext(ContextMissionDetails);

  const {filterStatusArray, setFilterStatusArray} = useContext(ContextMissionDetailsHeader);
  const { filterStatusArrayOld, setFilterStatusArrayOld } = useContext(ContextMissionDetails);

  const {statusFilter1, setStatusFilter1} = useContext(ContextMissionDetailsHeader);
  const {resetStatusFilter1, setResetStatusFilter1} = useContext(ContextMissionDetailsHeader);
  const {statusFilter2, setStatusFilter2} = useContext(ContextMissionDetailsHeader);
  const {resetStatusFilter2, setResetStatusFilter2} = useContext(ContextMissionDetailsHeader);
  const {statusFilter3, setStatusFilter3} = useContext(ContextMissionDetailsHeader);
  const {resetStatusFilter3, setResetStatusFilter3} = useContext(ContextMissionDetailsHeader);
  const {statusFilter4, setStatusFilter4} = useContext(ContextMissionDetailsHeader);
  const {resetStatusFilter4, setResetStatusFilter4} = useContext(ContextMissionDetailsHeader);
  const {statusFilter5, setStatusFilter5} = useContext(ContextMissionDetailsHeader);
  const {resetStatusFilter5, setResetStatusFilter5} = useContext(ContextMissionDetailsHeader);
  const {statusFilter6, setStatusFilter6} = useContext(ContextMissionDetailsHeader);
  const {resetStatusFilter6, setResetStatusFilter6} = useContext(ContextMissionDetailsHeader);
  const {statusFilter7, setStatusFilter7} = useContext(ContextMissionDetailsHeader);
  const {resetStatusFilter7, setResetStatusFilter7} = useContext(ContextMissionDetailsHeader);
  const {statusFilter8, setStatusFilter8} = useContext(ContextMissionDetailsHeader);
  const {resetStatusFilter8, setResetStatusFilter8} = useContext(ContextMissionDetailsHeader);
  const {statusFilter9, setStatusFilter9} = useContext(ContextMissionDetailsHeader);
  const {resetStatusFilter9, setResetStatusFilter9} = useContext(ContextMissionDetailsHeader);
  const {statusFilter10, setStatusFilter10} = useContext(ContextMissionDetailsHeader);
  const {resetStatusFilter10, setResetStatusFilter10} = useContext(ContextMissionDetailsHeader);
  const [disabledStatus, setDisabledFilter] = useState(!JLBInternalMode);

  const {dateReminderChanged, setDateReminderChanged} = useContext(ContextMissionDetails);
  const {dateReminder, setDateReminder} = useContext(ContextMissionDetails);
  const [dateReminderMax, setDateReminderMax] = useState(momentDate().add(30, 'days'));
  const {dateReminderTXT, setDateReminderTXT} = useContext(ContextMissionDetailsHeader);
  const [openDatePickerReminder, setOpenDatePickerReminder] = useState(false);
  const setDateReminderFormat = (value) => {
    setDateReminderChanged(true);
    setDateReminder(value);
    setDateReminderTXT(value.format("DD MMM YY"));
  };

  const {setFilterStatus} = useContext(ContextMissionDetailsHeader);

  //general context
  const {principalsAlias, setPrincipalsAlias} = useContext(ContextMissionDetails);

  //notification context
  const {nInternalNotifNoRead, setNRowsInternalNotifNoRead} = useContext(ContextMissionDetails);


  //useEffect
  useEffect(()=>{
    if (props?.visible) {
      if (filterStatusArrayOld.length > 0) {
        var currentFilter = filterStatusArrayOld[0];
        if (filterStatusArrayOld.length > 1) currentFilter = filterStatusArrayOld[1];

        setResetStatusFilter1(currentFilter != 1 ? true : false);
        setResetStatusFilter2(currentFilter != 2 ? true : false);
        setResetStatusFilter3(currentFilter != 3 ? true : false);
        setResetStatusFilter4(currentFilter != 4 ? true : false);
        setResetStatusFilter5(currentFilter != 5 ? true : false);
        setResetStatusFilter6(currentFilter != 6 ? true : false);
        setResetStatusFilter7(currentFilter != 7 ? true : false);
        setResetStatusFilter8(currentFilter != 8 ? true : false);
        setResetStatusFilter9(currentFilter != 9 ? true : false);
        setResetStatusFilter10(currentFilter != 10 ? true : false);
      }
    }
  }, [props?.visible]);
  

  //function interactive
  const RenderButtonNotif = (nRows) => {
    var notif = 0;
    if (typeof nRows != "undefined") notif = nRows;
    if(notif > 0){
      return (
        <IconButton className="puceButtonNotif" aria-label="Internal Notifications" onClick={() =>  {
          const notificationsSection = document.getElementById('notifications');
          notificationsSection.scrollIntoView({ behavior: 'smooth' });
        }}>
          <Badge className="puceNotif" badgeContent={nRows} variant="standard" overlap="circular"></Badge>
        </IconButton>
      );
    }
  };


  return(
    <div className="contentHeader">
      <Box className={props.floating ? 'missionTypeDetail sticky' : 'missionTypeDetail'} sx={{background:missionColor}}/>
      <div className="mission-titre">
        {missionRef}
        {(JLBInternalMode) && (RenderButtonNotif(nInternalNotifNoRead))}
      </div>
      <div className="mission-principal">{principalsAlias}</div>

      <div className={props.floating ? 'filterStatusSticky' : ''}>
      <FormControl variant="standard"  sx={{ display: 'inline', m: 1, minWidth: 300 }} className={missionCanceled ? 'filterStatusDisabled' : ''}>
        <FilterStatusList
          setFilter={setFilterStatus}
          filterStatus={filterStatusArray}
          statusFilter={[
            statusFilter1,
            statusFilter2,
            statusFilter3,
            statusFilter4,
            statusFilter5,
            statusFilter6,
            statusFilter7,
            statusFilter8,
            statusFilter9,
            statusFilter10
          ]}
          statusFilterReset={[
            resetStatusFilter1,
            resetStatusFilter2,
            resetStatusFilter3,
            resetStatusFilter4,
            resetStatusFilter5,
            resetStatusFilter6,
            resetStatusFilter7,
            resetStatusFilter8,
            resetStatusFilter9,
            resetStatusFilter10
          ]}
          changeStatusFilter={[
            setResetStatusFilter1,
            setResetStatusFilter2,
            setResetStatusFilter3,
            setResetStatusFilter4,
            setResetStatusFilter5,
            setResetStatusFilter6,
            setResetStatusFilter7,
            setResetStatusFilter8,
            setResetStatusFilter9,
            setResetStatusFilter10
          ]}
          disable={disabledStatus}
          missionEdit={JLBCustomerMode}
          workflowLoaded={workflowLoaded}
          JLBInternalMode={!JLBCustomerMode}
        />
      </FormControl>
      </div>

      {(!JLBPortMode) &&
      <div className={missionCanceled ? 'reminderDate filterStatusDisabled' : 'reminderDate'}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DesktopDatePicker
            className="picker"
            open={openDatePickerReminder}
            onOpen={() => setOpenDatePickerReminder(true)}
            onClose={() => setOpenDatePickerReminder(false)}
            label="Date begining operations"
            inputFormat="DD/MM/YYYY"
            value={dateReminder}
            onChange={setDateReminderFormat}
            maxDate={dateReminderMax}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>

        <Chip
          className="reminderDateSelector"
          label={dateReminderTXT}
          size="small"
          onClick={() => setOpenDatePickerReminder(true)}
          onDelete={() => setOpenDatePickerReminder(true)}
          deleteIcon={<NotificationsActiveIcon />}
          variant="outlined"
        />
      </div>
      }

      {(!JLBPortMode) &&
      <div className={missionCanceled ? 'pinned filterStatusDisabled' : 'pinned'}>
        <Rating
          name="mission-pinned"
          size="large"
          max={1}
          value={pinned}
          onChange={(event, newValue) => {
            setPinned(newValue);
          }}
        />
      </div>
      }

      {!props.floating && Boolean((idMission > 0)) && loadingUpdate && (
        <div className='datesHeader datesHeaderTitle'>
          <div className='titleDate'>Date created :</div>
          <div className='titleDate'>Date modified :</div>
        </div>
      )}
      {!props.floating && Boolean((idMission > 0)) && loadingUpdate && (
        <div className='datesHeader datesHeaderContent'>
          <div className='contentDate'>{createdMissionDate}</div>
          <div className='contentDate'>{modifiedMissionDate}</div>
        </div>
      )}

      <LinearProgress hidden={loadingUpdate}  id="loadingGeneral"/>
    </div>
  );
};

export default MissionDetailsHeaderContent;
