import React, {useState, useEffect, useContext, forwardRef} from 'react';

import Moment from 'moment';
import { saveAs } from 'file-saver';

import Box from '@mui/material/Box';
import axios from 'api/axios';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useNavigate } from "react-router-dom";
import Accordion from '@mui/material/Accordion';
import LoadingButton from '@mui/lab/LoadingButton';
import SettingsIcon from '@mui/icons-material/Settings';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { ContextBlock } from "context/ContextBlock";
import { ContextMissionDetails } from "context/ContextMissionDetails";
import { ContextMissionDetailsReceiver } from "../ContextMissionDetailsReceiver";

import MissionDetailsReceiverImportCR from "./MissionDetailsReceiverImportCR";

const Alert = forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function MissionDetailsReceiverCRDispatch(props) {

  //init config
  const navigate = useNavigate();

  //API URL
  const IMPORTCR_PARSING_URL = 'mission/import/receiver/cr';
  const EXPORTCR_DOWNLOAD_URL = 'mission/export/receiver/cr';
  const EXPORTCR_CREATE_URL = 'mission/export/receiver/cr/create';
  const EXPORTCR_CREATE_STATUS_URL = 'mission/export/receiver/cr/create/status';
  const EXPORTESTIMATE_URL = 'mission/export/receiver/estimate';

  //generic
  const { openSnackBar, setOpenSnackBar } = useContext(ContextBlock);
  const { snackBarType, setSnackBarType } = useContext(ContextBlock);
  const { snackBarMessage, setSnackBarMessage } = useContext(ContextBlock);

  //init id mission
  const {idMission, setIdMision} = useContext(ContextMissionDetails);
  const {missionRef, setMissionRef} = useContext(ContextMissionDetails);

  //receiver context
  const {importCRData, setImportCRData} = useContext(ContextMissionDetailsReceiver);
  const {importPreviousCRData, setImportPreviousCRData} = useContext(ContextMissionDetailsReceiver);

  const [importCR, setImportCR] = useState(false);
  const [download, setDownload] = useState(false);
  const [downloadCR, setDownloadCR] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [sharepointCR, setSharepointCR] = useState(false);

  const [intervalId, setIntervalId] = useState(0);
  const [intervalIsOn, setIntervalIsOn] = useState(false);
  const [generateIsDone, setGenerateIsDone] = useState(false);
  const [generateIsStart, setGenerateIsStart] = useState(false);
  const {generateStatusCR, setGenerateStatusCR} = useContext(ContextMissionDetailsReceiver);


  //check status generation of cr
  useEffect(()=>{
    //3 status possible[enum] : none (not generate), start (generation in progress), done (generate)
    switch (generateStatusCR) {
      case 'start': {
        setGenerateIsDone(false);
        setGenerateIsStart(true);

        //when generation is launched, we start an interval
        //all of 1mn, we send API request for know the status of generation
        if (!intervalIsOn) {
          setIntervalIsOn(true);
          setIntervalId(setInterval(getCreateStatusExportCR, 60000));
        }
      }
      break;

      case 'done': {
        setGenerateIsDone(true);
        setGenerateIsStart(false);

        //when generation is launched, we start an interval
        //when generation is done, we stop this interval
        if (intervalIsOn) {
          setIntervalIsOn(false);
          clearInterval(intervalId);
        }
      }
      break;

      //(case 'none')
      default: {
        setGenerateIsDone(false);
        setGenerateIsStart(false);

        if (intervalIsOn) {
          setIntervalIsOn(false);
          clearInterval(intervalId);
        }
      }
    }
  }, [generateStatusCR]);


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsDownload(false);
  };

  const handleExportEstimate = async (e) => {
    try {
      setDownload(true);
      var config = {
        url: EXPORTESTIMATE_URL,
        responseType: 'arraybuffer',
        method: "post",
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        },
        data: { id: idMission },
      };
      const response = await axios(config);

      // Let the user save the file.
      var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, missionRef + ' - Estimate_' + Moment().format('YYYYMMDD-HHmmss') + '.xlsx');
      setIsDownload(true);
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    } finally {
      setDownload(false);
    }
  }

  const getCreateStatusExportCR = async (e) => {
    try {
      var config = {
        url: EXPORTCR_CREATE_STATUS_URL,
        method: "get",
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        },
        params: { id: idMission },
      };
      const response = await axios(config);
      setGenerateStatusCR(response.data.data);
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    }
  }

  const handleCreateExportCR = async (e) => {
    try {
      var config = {
        url: EXPORTCR_CREATE_URL,
        method: "post",
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        },
        data: { id: idMission },
      };
      const response = await axios(config);

      var mission = response.data.data;
      setGenerateStatusCR(mission.cr_created_status);
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    }
  }

  const handleDownloadExportCR = async (e) => {
    try {
      setDownloadCR(true);
      var config = {
        url: EXPORTCR_DOWNLOAD_URL,
        responseType: 'arraybuffer',
        method: "get",
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        },
        params: { id: idMission, download: 1 },
      };
      const response = await axios(config);

      // Let the user save the file.
      var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, missionRef + ' - CR_' + Moment().format('YYYYMMDD-HHmmss') + '.xlsx');
      setIsDownload(true);
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    } finally {
      setDownloadCR(false);
    }
  }

  const handleOpenSharepointExportCR = async (e) => {
    try {
      setSharepointCR(true);
      var config = {
        url: EXPORTCR_DOWNLOAD_URL,
        method: "get",
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        },
        params: { id: idMission, download: 0 },
      };
      const response = await axios(config);

      // Let the user open the file in sharepoint.
      window.open(response.data.data.file_path, "_blank");
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    } finally {
      setSharepointCR(false);
    }
  }

  const handleImportCR = async (e) => {
    try {
      setImportCR(true);
      var config = {
        url: IMPORTCR_PARSING_URL,
        method: 'get',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json'
        },
        params: { id_mission: idMission }
      };
      const response = await axios(config);
      setImportCRData(response.data.data.import);
      setImportPreviousCRData(response.data.data.importPrevious);
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      } else {
        setImportCRData([]);
        setSnackBarMessage("Technical error ! Please try again or contact our support.");
        setSnackBarType("error");
        setOpenSnackBar(true);
      }
    } finally {
      setImportCR(false);
    }
  }

  return (
    <div className="exportCRDiv">
      <Accordion className="exportCRAccordion" expanded={true}>
        <AccordionSummary>
          CR Dispatch
        </AccordionSummary>
        <AccordionDetails>
          <div className="exportCRFields">
            {props.countReceivers > 0 && <Box className="exportCREstimate">
              <LoadingButton
                size="small"
                variant="outlined"
                loading={download} loadingPosition="start" 
                startIcon={<FileDownloadIcon/>}
                onClick={handleExportEstimate}
              >
                Download Estimations
              </LoadingButton>
              &nbsp;
              {generateIsDone ? 
              <>
              <LoadingButton 
                size="small" 
                color="success" 
                variant="outlined" 
                loading={downloadCR} loadingPosition="start" 
                startIcon={<FileDownloadIcon/>} 
                onClick={handleDownloadExportCR}
              >
                Download CR
              </LoadingButton>
              &nbsp;
              <LoadingButton 
                size="small" 
                color="success" 
                variant="outlined" 
                loading={sharepointCR} loadingPosition="start" 
                startIcon={<FileOpenIcon/>} 
                onClick={handleOpenSharepointExportCR}
              >
                Open CR in Sharepoint
              </LoadingButton>
              <LoadingButton 
                size="small" 
                variant="outlined" 
                className="float right"
                loading={importCR} loadingPosition="start" 
                startIcon={<FileUploadIcon/>}
                onClick={handleImportCR}
              >
                Import from CR
              </LoadingButton>
              </>
              : 
              <LoadingButton 
                size="small" 
                variant="outlined" 
                loading={generateIsStart} loadingPosition="start" 
                startIcon={<SettingsIcon/>}
                onClick={handleCreateExportCR}
              >
                Generate CR
              </LoadingButton>
              }
              <span className="spacer"></span>
            </Box>}
          </div>

          {Object.keys(importCRData).length > 0 &&
          <MissionDetailsReceiverImportCR/>
          }
        </AccordionDetails>
      </Accordion>

      <Snackbar open={isDownload} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          File saved in your downloads folder.
        </Alert>
      </Snackbar>
    </div>
  );
};
