import React, { useEffect, useState, useContext, SyntheticEvent, forwardRef } from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import { useNavigate } from 'react-router-dom';

import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import MuiAlert from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import { randomId } from '@mui/x-data-grid-generator';
import CommentIcon from '@mui/icons-material/Comment';
import ForwardIcon from '@mui/icons-material/Forward';
import LinearProgress from '@mui/material/LinearProgress';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CancelIcon from '@mui/icons-material/HighlightOffRounded';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { DataGridPro, GridColDef, gridVisibleSortedRowIdsSelector } from '@mui/x-data-grid-pro';

import { ContextFilter } from "context/ContextFilter";
import { checkSecurity } from 'components/config/Security';
import { isInternalRole, isPortRole, isCustomerRole } from 'components/config/Roles';

import axios from 'api/axios';

const Alert = forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function DashboardMainList(props) {
  //config
  const navigate = useNavigate();
  const [JLBPortRole] = useState(isPortRole(localStorage.getItem("role")));
  const [JLBInternalRole] = useState(isInternalRole(localStorage.getItem("role")));
  const [JLBCustomerRole] = useState(isCustomerRole(localStorage.getItem("role")));

  //URL API
  const GETMISSIONLIST_URL = 'mission/list';
  const EXPORTMISSIONLIST_URL = 'mission/export';
  const GETMISSIONTYPESLIST_URL = 'mission_type/list';
  const EXPORTMISSIONLIST_COD_URL = 'mission/export/cocoa_departure';

  const MISSION_TYPE_COD_NOMENCLATURE = 'COD';

  //params
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [chosenMissionTypeId, setChosenMissionTypeId] = useState('');

  //context
  const { visibleCODRows, setVisibleCODRows } = useContext(ContextFilter);
  const { hidePrincipal, setHidePrincipal } = useContext(ContextFilter);
  const { visibleRows, setVisibleRows } = useContext(ContextFilter);
  const { isDownload, setIsDownload } = useContext(ContextFilter);

  const { loadingGrid, setLoadingGrid } = useContext(ContextFilter);
  const { setDataGridLoaded } = useContext(ContextFilter);
  const { filterModel } = useContext(ContextFilter);

  const { rows, setRows } = useContext(ContextFilter);
  const { setTotalRows } = useContext(ContextFilter);
  const { setVesselRows } = useContext(ContextFilter);
  const { setSurveyRows } = useContext(ContextFilter);
  const { setPrincipalRows } = useContext(ContextFilter);
  const { setMissionRows } = useContext(ContextFilter);
  const { setPortRows } = useContext(ContextFilter);
  const { setInsuredRows } = useContext(ContextFilter);
  const { setShipperRows } = useContext(ContextFilter);
  const { setReceiverRows } = useContext(ContextFilter);
  const { setContractNumberRows } = useContext(ContextFilter);

  const [missionTypeCOD, setMissionTypeCOD] = useState({})


  // CHECK SECURITY
  useEffect(() => {
    checkSecurity(navigate, 'DashboardList');
    if (JLBCustomerRole) setHidePrincipal(true);
    loadMissionTypes();
    getMissionList();
  }, []);
  // END SECURITY


  //function
  const handleClose = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsDownload(false);
    setOpenSnackBar(false);
  };

  const RenderType = (props) => {
    const { value } = props;
    return (<div className="typeMissionGridColor" style={{ backgroundColor: value }}></div>
    );
  };

  RenderType.propTypes = {
    /**
     * If true, the cell is the active element.
     */
    hasFocus: PropTypes.bool.isRequired,
    /**
     * The cell value, but if the column has valueGetter, use getValue.
     */
    value: PropTypes.instanceOf(Number),
  };

  const RenderNotif = (props) => {
    var notif = false;
    if (typeof props != "undefined") notif = props;
    if (notif) {
      return (
        <span className="puceNotifBorder">
          <Badge className="puceNotif" badgeContent="" variant="dot" overlap="circular"></Badge>
        </span>
      );
    }
  };

  const RenderStatus = (props) => {
    if (!!props.row.missionCancelReason == false) {
      return (<div className="statusMissionGridColor" style={{ backgroundColor: props.row.status.statut_color }}>
        {props.row.status.statut_type}
        {(JLBInternalRole) && (RenderNotif(props.row.has_unread_internal_notification))}
      </div>);
    } else {
      var txt = "";
      if (props.row.missionCancelReason) txt = props.row.missionCancelReason;

      if (txt.length > 0) {
        return (<div className="statusMissionGridColor" style={{ width: 'auto' }}>
          <Tooltip
            title={<span style={{ whiteSpace: 'pre-line' }}>{txt}</span>}
            placement="right">
            <div><CancelIcon fontSize="large" sx={{ color: 'red', opacity: '0.6' }} /></div>
          </Tooltip>
          {(JLBInternalRole) && (RenderNotif(props.row.has_unread_internal_notification))}
        </div>);
      }
    }
  };

  const RenderDate = (props) => {
    const { created } = props.row;
    return (moment(created).format("DD MMM YY"));
  };

  const RenderReceiver = (props) => {
    var txt = "";
    var prefix = "";

    if (props.row.receiverSearch.length > 0) {
      for (var i = 0; i < props.row.receiverSearch.length; i++) {
        txt = txt + prefix + props.row.receiverSearch[i];
        prefix = "\n\r";
      }

      return (<Tooltip
        title={<span style={{ whiteSpace: 'pre-line' }}>{txt}</span>}
        placement="right">
        <IconButton>
          <FormatListBulletedIcon />
        </IconButton>
      </Tooltip>);
    }
    else return (<></>);
  };

  const RenderBL = (props) => {
    var txt = "";
    var prefix = "";

    if (props.row.b_o_l.length > 0) {
      for (var i = 0; i < props.row.b_o_l.length; i++) {
        txt = txt + prefix + props.row.b_o_l[i];
        prefix = "\n\r";
      }

      return (<Tooltip
        title={<span style={{ whiteSpace: 'pre-line' }}>{txt}</span>}
        placement="right">
        <IconButton>
          <FormatListBulletedIcon />
        </IconButton>
      </Tooltip>);
    }
    else return (<></>);
  };

  const RenderCommentInternal = (props) => {
    var txt = "";
    if (props.row.internal_note) txt = props.row.internal_note;

    if (txt.length > 0) {
      return (<Tooltip
        title={<span style={{ whiteSpace: 'pre-line' }}>{txt}</span>}
        placement="right">
        <CommentIcon fontSize="small" />
      </Tooltip>
      );
    }
    else return (<CommentIcon fontSize="small" style={{ opacity: '0.1' }} />);
  }

  const RenderCommentPublic = (props) => {
    var txt = "";
    if (props.row.public_note) txt = props.row.public_note;

    if (txt.length > 0) {
      return (<Tooltip
        title={<span style={{ whiteSpace: 'pre-line' }}>{txt}</span>}
        placement="right">
        <CommentIcon fontSize="small" />
      </Tooltip>
      );
    }
    else return (<CommentIcon fontSize="small" style={{opacity: '0.1'}} />);
  }

  const RenderContractNumber = (props) => {
    if (props.row.id_shipping_instruction) {
      return (<Button size="small" className="small link" variant="outlined" endIcon={<ForwardIcon fontSize="small" />} onClick={(event) => {
        navigate('/shippingInstructionDetails?id=' + props.row.id_shipping_instruction, { replace: true });
        event.stopPropagation();
        event.preventDefault();
      }}>{props.row.contractNumber}</Button>);
    }
    else return ('');
  }

  function getStatusId(params) {
    if (!!params.row.missionCancelReason == false) {
      return `${params.row.status.statut_type}`;
    } else {
      return 'canceled';
    }
  }

  const handleRowClick = (params) => {
    navigate(`/missionDetails?id=${params.row.id}&key=${randomId()}`, { replace: true });
  };

  function getUnique(arr, index) {
    const unique = arr
      .map(e => e[index])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter(e => arr[e]).map(e => arr[e]);

    return unique;
  }

  const handleExport = async (e) => {
    if (visibleRows.length > 0) {
      try {

        var config = {
          url: EXPORTMISSIONLIST_URL,
          responseType: 'arraybuffer',
          method: "post",
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json'
          },
          data: { id: visibleRows },
        };
        const response = await axios(config);

        // Let the user save the file.
        var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        saveAs(blob, 'Export_files_' + moment().format('YYYYMMDD-HHmmss') + '.xlsx');
        setIsDownload(true);

      } catch (err) {
        if (err.response?.status === 401) {
          navigate("/logout", { replace: true });
        }
        else {
          setOpenSnackBar(true);
        }
      }
    }
  }

  const handleExportCocoaDeparture = async (e) => {
    if (visibleCODRows.length > 0) {
      try {

        var config = {
          url: EXPORTMISSIONLIST_COD_URL,
          responseType: 'arraybuffer',
          method: "post",
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json'
          },
          data: { id: visibleCODRows },
        };
        const response = await axios(config);

        // Let the user save the file.
        var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        saveAs(blob, 'Export_Cocoa_Departure_files_' + moment().format('YYYYMMDD-HHmmss') + '.xlsx');
        setIsDownload(true);

      } catch (err) {
        if (err.response?.status === 401) {
          navigate("/logout", { replace: true });
        }
        else {
          setOpenSnackBar(true);
        }
      }
    }
  }

  const loadMissionTypes = async () => {
    try {
      const config = {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      };
      const response = await axios.get('mission_type/list', config);
      const typeCOD = response.data.find(obj => obj.nomenclature === MISSION_TYPE_COD_NOMENCLATURE);
      setMissionTypeCOD(typeCOD);
    } catch (err) {
      if (err.response?.status === 401) {
        navigate("/logout", { replace: true });
      }
      else {
        setOpenSnackBar(true);
      }
    }
  }

  const getMissionList = async (e) => {
    try {
      setLoadingGrid(true);

      //config call API
      const config = {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
        },
        params: {
          all:1
	}
      }

      if (!JLBCustomerRole) {
        if (props.archivedList == 1) {
          config.params.archived_missions = 1;
        } else {
          config.params.archived_missions = 0;
        }
      } else {
        config.params.id_principal = parseInt(localStorage.getItem("id_company"));
      }

      const response = await axios.get(GETMISSIONLIST_URL, config);

      //set data
      var rowsFromApi = [];
      var vessels = [];
      var surveys = [];
      var principals = [];
      var missions = [];
      var ports = [];
      var insured = [];
      var shippers = [];
      var receivers = [];
      var contractNumbers = [];
      var idRow = 0;

      for (var i = 0; i < response.data.length; i++) {

        if (JLBCustomerRole) {
          if (parseInt(localStorage.getItem("id_company")) != parseInt(response.data[i].principal.id)) {
            continue;
          }
        }
        if (JLBPortRole) {
          if (parseInt(localStorage.getItem("id_company")) != parseInt(response.data[i].local_office.id)) {
            continue;
          }
        }
        
        idRow = rowsFromApi.length;
        rowsFromApi[idRow] = {};
        rowsFromApi[idRow].id = response.data[i].id;
        rowsFromApi[idRow].missionType = response.data[i].mission_type.id;
        missions[missions.length] = response.data[i].mission_type;
        rowsFromApi[idRow].missionTypeLabel = response.data[i].mission_type.label;
        rowsFromApi[idRow].missionColor = response.data[i].mission_type.color;
        if (response.data[i].canceled_at != null && response.data[i].cancel) {
          rowsFromApi[idRow].missionCancelReason = response.data[i].cancel.reason.label;
        }
        if (response.data[i].port) rowsFromApi[idRow].ref = response.data[i].mission_type.nomenclature + "-" + response.data[i].port.nomenclature + "-" + pad(response.data[i].id);
        else rowsFromApi[idRow].ref = response.data[i].mission_type.nomenclature + "-<XXX>-" + pad(response.data[i].id);
        rowsFromApi[idRow].created = response.data[i].created_at;
        if (JLBCustomerRole) {
          if (response.data[i].status) rowsFromApi[idRow].status = { statut_type: response.data[i].status.status.public_status.status.value, statut_color: response.data[i].status.status.public_status.status.color };
          else rowsFromApi[idRow].status = { statut_type: '0', statut_color: 'red' };
        } else {
          if (response.data[i].status) rowsFromApi[idRow].status = { statut_type: response.data[i].status.status.value, statut_color: response.data[i].status.status.color };
          else rowsFromApi[idRow].status = { statut_type: '0', statut_color: 'red' };
        }

        if (response.data[i].port) {
          rowsFromApi[idRow].port = response.data[i].port.label;
          ports[ports.length] = response.data[i].port;
        }

        if (response.data[i].principal_ref) rowsFromApi[idRow].principalsRef = response.data[i].principal_ref;

        rowsFromApi[idRow].principals = response.data[i].principal.name;
        principals[principals.length] = response.data[i].principal;
        if (response.data[i].insured) {
          rowsFromApi[idRow].insured = response.data[i].insured.name;
          insured[insured.length] = response.data[i].insured;
        }
        else rowsFromApi[idRow].insured = "";

        rowsFromApi[idRow].cargo = '';
        rowsFromApi[idRow].shipper = '';
        if (response.data[i].shipping_instruction) {
          rowsFromApi[idRow].id_shipping_instruction = response.data[i].shipping_instruction.id;
          rowsFromApi[idRow].contractNumber = response.data[i].shipping_instruction.contract_number;
          contractNumbers[contractNumbers.length] = {...response.data[i].shipping_instruction, 'contractNumber' : rowsFromApi[idRow].contractNumber};

          if (response.data[i].shipping_instruction.shipper) {
            rowsFromApi[idRow].shipper = response.data[i].shipping_instruction.shipper.name;
            shippers[shippers.length] = response.data[i].shipping_instruction.shipper;
          }
          if (response.data[i].shipping_instruction.cargo_type) rowsFromApi[idRow].cargo = response.data[i].shipping_instruction.cargo_type.label;
        }
        else {
          rowsFromApi[idRow].contractNumber = "";
          rowsFromApi[idRow].id_shipping_instruction = "";
        }
        if (response.data[i].vessel) {
          rowsFromApi[idRow].vessel = response.data[i].vessel.name;
          vessels[vessels.length] = response.data[i].vessel;
        }
        else rowsFromApi[idRow].vessel = "";
        rowsFromApi[idRow].survey_handler = response.data[i].survey_handler.firstname + " " + response.data[i].survey_handler.lastname;
        surveys[surveys.length] = response.data[i].survey_handler;

        var contentRowLoop = [];
        if (response.data[i].receivers) {
          for (var j = 0; j < response.data[i].receivers.length; j++) {
            if (response.data[i].receivers[j].receiver) {
              contentRowLoop[j] = response.data[i].receivers[j].receiver.name;
              receivers[receivers.length] = response.data[i].receivers[j].receiver;
            }
          }
        }
        rowsFromApi[idRow].receiver = contentRowLoop;
        rowsFromApi[idRow].receiverSearch = contentRowLoop;

        var contentRowLoopBL = [];
        if (response.data[i].bills_of_lading) {
          for (var j = 0; j < response.data[i].bills_of_lading.length; j++) {
            contentRowLoopBL[j] = response.data[i].bills_of_lading[j].number;
          }
        }
        rowsFromApi[idRow].b_o_l = contentRowLoopBL;

        if (response.data[i].vessel_tonnage) rowsFromApi[idRow].tonnage = Number(response.data[i].vessel_tonnage);
        if (response.data[i].warehouse) rowsFromApi[idRow].warehouse = response.data[i].warehouse.label;
        if (response.data[i].trader) rowsFromApi[idRow].trader = response.data[i].trader.name;
        rowsFromApi[idRow].public_note = response.data[i].public_note;
        rowsFromApi[idRow].internal_note = response.data[i].internal_note;
        rowsFromApi[idRow].has_unread_internal_notification = response.data[i].has_unread_internal_notification;
      }
      setRows(rowsFromApi);
      setTotalRows(rowsFromApi);

      vessels.sort((a, b) => (a.name > b.name) ? 1 : -1);
      setVesselRows(getUnique(vessels, 'id'));
      surveys.sort((a, b) => ((a.firstname + " " + a.lastname) > (b.firstname + " " + b.lastname)) ? 1 : -1);
      setSurveyRows(getUnique(surveys, 'id'));
      principals.sort((a, b) => (a.name > b.name) ? 1 : -1);
      setPrincipalRows(getUnique(principals, 'id'));
      missions.sort((a, b) => (a.label > b.label) ? 1 : -1);
      setMissionRows(getUnique(missions, 'id'));
      ports.sort((a, b) => (a.label > b.label) ? 1 : -1);
      setPortRows(getUnique(ports, 'id'));
      insured.sort((a, b) => (a.name > b.name) ? 1 : -1);
      setInsuredRows(getUnique(insured, 'id'));
      shippers.sort((a, b) => (a.name > b.name) ? 1 : -1);
      setShipperRows(getUnique(shippers, 'id'));
      receivers.sort((a, b) => (a.name > b.name) ? 1 : -1);
      setReceiverRows(getUnique(receivers, 'id'));
      contractNumbers.sort((a, b) => (a.contractNumber > b.contractNumber) ? 1 : -1);
      setContractNumberRows(getUnique(contractNumbers, 'contractNumber'));
      if (props.initFilterMissions) props.initFilterMissions(rowsFromApi);

    } catch (err) {
      if (err.response?.status === 401) {
        navigate('/logout', { replace: true });
      }
      else {
        setOpenSnackBar(true);
        console.log(err);
      }
    } finally {
      setLoadingGrid(false);
      setDataGridLoaded(true);
    }
  }

  function CustomNoRowsOverlay() {
    return (<div></div>);
  }

  function pad(num) {
    var s = "000000" + num;
    return s.substr(s.length - 6);
  }

  //xGrid
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', hide: !props?.visibleColumns?.idColumn },
    { field: 'survey_handler', headerName: 'Survey handler', hide: !props?.visibleColumns?.surveyHandlerColumn },
    { field: 'missionColor', headerName: '', sortable: false, flex: 1, width: 5, padding: 0, type: 'number', renderCell: RenderType, hide: !props?.visibleColumns?.missionColorColumn },
    { field: 'ref', headerName: 'Ref JLB', width: 145, hide: !props?.visibleColumns?.refJLBColumn },
    { field: 'internal_note', headerName: '', sortable: false, flex: 1, width: 40, padding: 0, align: 'center', renderCell: RenderCommentInternal, hide: hidePrincipal ? true : !props?.visibleColumns?.internalNoteColumn },
    { field: 'public_note', headerName: '', sortable: false, flex: 1, width: 40, padding: 0, align: 'center', renderCell: RenderCommentPublic, hide: !hidePrincipal ? false : !props?.visibleColumns?.publicNoteColumn },
    { field: 'missionType', headerName: 'Mission Type', type: 'number', hide: !props?.visibleColumns?.missionTypeColumn },
    { field: 'missionTypeLabel', headerName: 'Type of mission', width: 170, hide: !hidePrincipal ? false : !props?.visibleColumns?.missionTypeLabelColumn },
    { field: 'created', headerName: 'Creation date', width: 110, type: 'date', align: 'center', renderCell: RenderDate, hide: !props?.visibleColumns?.createdDateColumn },
    { field: 'status', headerName: 'Status', width: 64, type: 'number', align: 'center', renderCell: RenderStatus, valueGetter: getStatusId, hide: !props?.visibleColumns?.statusColumn },
    { field: 'id_shipping_instruction', headerName: 'ID SI', hide: !props?.visibleColumns?.idSIColumn },
    { field: 'contractNumber', headerName: 'Contract N°', flex: 1, minWidth: 130, maxWidth: 150, renderCell: RenderContractNumber, hide: !props?.visibleColumns?.contractNumberColumn },
    { field: 'port', headerName: 'Port', flex: 1, minWidth: 110, maxWidth: 150, hide: !props?.visibleColumns?.portColumn },
    { field: 'principals', headerName: 'Principals', flex: 1, minWidth: 140, hide: hidePrincipal ? true : !props?.visibleColumns?.principalsColumn },
    { field: 'principalsRef', headerName: 'Your réf.', flex: 1, minWidth: 140, hide: !hidePrincipal ? false : !props?.visibleColumns?.principalsRefColumn },
    { field: 'insured', headerName: 'Insured', flex: 1, minWidth: 140, hide: !props?.visibleColumns?.insuredColumn },
    { field: 'vessel', headerName: 'Vessel', flex: 1, minWidth: 140, hide: !props?.visibleColumns?.vesselColumn },
    { field: 'tonnage', headerName: 'Tonnage', flex: 0.7, minWidth: 100, type: 'number', hide: !props?.visibleColumns?.tonnageColumn },
    { field: 'receiver', headerName: 'Receiver', flex: 1, minWidth: 80, sortable: false, renderCell: RenderReceiver, hide: !props?.visibleColumns?.receiverColumn },
    { field: 'receiverSearch', headerName: 'Receiver search', flex: 1, minWidth: 100, sortable: false, hide: !props?.visibleColumns?.receiverSearchColumn },
    { field: 'cargo', headerName: 'Cargo', flex: 1, minWidth: 100, sortable: false, hide: !props?.visibleColumns?.cargoColumn },
    { field: 'trader', headerName: 'Trader', flex: 1, minWidth: 100, sortable: false, hide: !props?.visibleColumns?.traderColumn },
    { field: 'shipper', headerName: 'Shipper', flex: 1, minWidth: 100, sortable: false, hide: !props?.visibleColumns?.shipperColumn },
    { field: 'b_o_l', headerName: 'Bill of lading', flex: 0.7, minWidth: 140, sortable: false, renderCell: RenderBL, hide: !props?.visibleColumns?.billOfLadingColumn },
    { field: 'warehouse', headerName: 'Warehouse', flex: 1, minWidth: 140, hide: !props?.visibleColumns?.warehouseColumn }
  ];


  return (
    <>
      <DataGridPro sx={{ height: '100%', width: '100%' }}
        components={{
          LoadingOverlay: LinearProgress,
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        initialState={{
          pagination: {
            pageSize: 15,
          },
          sorting: {
            sortModel: [{ field: 'created', sort: 'desc' }],
          },
        }}
        loading={loadingGrid}
        rows={rows}
        columns={columns}
        rowsPerPageOptions={[8, 15, 25, 50, 100]}
        pagination
        disableSelectionOnClick
        disableColumnMenu
        disableColumnFilter
        onRowClick={handleRowClick}
        filterModel={filterModel}
        onFilterModelChange={props.rowsSelected}
        onStateChange={(state) => {
          //all line
          const newRows = gridVisibleSortedRowIdsSelector(state);
          setVisibleRows(newRows);
          //COD line
          const codRows = [];
          rows.map(row => {
            if (row?.missionType == missionTypeCOD?.id) {
              codRows.push(row.id);
            }
          });
          setVisibleCODRows(codRows);
        }}
        getRowClassName={(params) => {
          var className = '';
          if (!!params.row.missionCancelReason != false) {
            className = 'dataGridRowMissionCanceled';
          }
          return className;
        }}
      />
      <div id="listExport">
        <Button size="small" variant="outlined" startIcon={<FileDownloadIcon />} onClick={handleExport}>Export</Button>
        &nbsp;
        <Button size="small" variant="outlined" startIcon={<FileDownloadIcon />} onClick={handleExportCocoaDeparture}>Export Cocoa Departure</Button>
      </div>
      <Snackbar open={openSnackBar} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Technical error ! Please try again or contact our support.
        </Alert>
      </Snackbar>
      <Snackbar open={isDownload} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          File saved in your downloads folder.
        </Alert>
      </Snackbar>
    </>
  );
}

DashboardMainList.propTypes = {
  visibleColumns: PropTypes.shape({
    idColumn: PropTypes.bool,
    surveyHandlerColumn: PropTypes.bool,
    missionColorColumn: PropTypes.bool,
    refJLBColumn: PropTypes.bool,
    internalNoteColumn: PropTypes.bool,
    publicNoteColumn: PropTypes.bool,
    missionTypeColumn: PropTypes.bool,
    missionTypeLabelColumn: PropTypes.bool,
    createdDateColumn: PropTypes.bool,
    statusColumn: PropTypes.bool,
    idSIColumn: PropTypes.bool,
    contractNumberColumn: PropTypes.bool,
    portColumn: PropTypes.bool,
    principalsColumn: PropTypes.bool,
    principalsRefColumn: PropTypes.bool,
    insuredColumn: PropTypes.bool,
    vesselColumn: PropTypes.bool,
    tonnageColumn: PropTypes.bool,
    receiverColumn: PropTypes.bool,
    receiverSearchColumn: PropTypes.bool,
    cargoColumn: PropTypes.bool,
    traderColumn: PropTypes.bool,
    shipperColumn: PropTypes.bool,
    billOfLadingColumn: PropTypes.bool,
    warehouseColumn: PropTypes.bool
  })
};
