import React from 'react';
import TextField from '@mui/material/TextField';
import {isInternalRole, isPortRole, isCustomerRole} from 'components/config/Roles';
import DetailField from 'components/page/object/DetailField';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import momentDate from 'moment';

const DesktopDatePickerCustom = (props) => {
  const [JLBInternalMode] = React.useState(isInternalRole(localStorage.getItem("role")));

  var canRead = 1;
  var canWrite = 1;
  var isRequired = false;

  if (props.controlFieldsStatus)
  {
    canRead = props.read;
    canWrite = props.write;
    isRequired = props.required;
  }else if(typeof props.required != "undefined"){
    isRequired = props.required;
  }

  var requiredStar = "";
  if (isRequired) requiredStar = " *";

  var widthPercent = '18%';
  if (props.fullWidth) widthPercent = '100%';

  var classError = "";
  if(props.error){
    classError = " Mui-error";
  }

  const getInputFormatFormParamViews = () => {
    var viewsFormat = [];
    if (props?.views) {
      if (props?.views.length > 0) {
        const day = props?.views.findIndex(arr => arr === 'day');
        if (day >= 0) viewsFormat.push('DD');
        const month = props?.views.findIndex(arr => arr === 'month');
        if (month >= 0) viewsFormat.push('MM');
        const year = props?.views.findIndex(arr => arr === 'year');
        if (year >= 0) viewsFormat.push('YYYY');
      }
    }
    const inputFormat = viewsFormat.join('/');
    return inputFormat;
  }
  
  if (canWrite){
    return (
      <FormControl className={"textField-form-date"+classError} sx={{ m: 1, width: widthPercent, marginLeft: 1, marginRight: 1, marginTop: 0, marginBottom: 0 }} size="small" error={props.error} required={isRequired}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DesktopDatePicker
            label={!!props?.label ? props.label+requiredStar : ''}
            inputFormat={props?.views ? getInputFormatFormParamViews() : "DD/MM/YYYY"}
            value={props.value}
            views={props?.views ? props.views : null}
            onChange={props.onChange}
            renderInput={(params) => <TextField {...params} />}
            error={props.error}
          />
        </LocalizationProvider>
        {(props.error && (!!props.helperText != false)) && <FormHelperText sx={{ ml: 0}}>{props.helperText}</FormHelperText>}
      </FormControl>
    );
  }

  if (canRead){
    return <DetailField title={props.label} content={(props.value && props.value.length > 3) ? momentDate(props.value).format("DD MMM YY"):""} size={JLBInternalMode ? '':'large'} canRead={canRead} />
  }
}
export default DesktopDatePickerCustom
