import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import DetailField from 'components/page/object/DetailField';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

const TextFieldCustom = (props) => {

  const [value, setVal] = useState((typeof props.value != undefined && props.value != null ? props.value : ''));

  const handleChange = (e) => {
    if(!!e != false) {
      if(!!props.type !== false && props.type == 'number'){
        e.target.value = setValueStrictNumber(e.target.value);
      }
      if(!!props.type !== false && props.type == 'decimal'){
        e.target.value = setValueStrictDecimal(e.target.value);
      }
      //for exception/textarea, we use blur not change
      if(!!props.onChange !== false) props.onChange(e);
      setVal(e.target.value);
    }
  };
  
  // Submit value using 'Enter' or 'Tab' key
  const handleKeySubmit = (e) => {
    if(e?.keyCode == 13 || e?.keyCode == 9 ){
      props.onBlur(e);
    }
  };

  //for exception/textarea, we use blur for change data (not change)
  const handleBlur = (e) => {
    if(!!e != false && !!props.onBlur !== false) {
      props.onBlur(e);
    }
  };

  const setValueStrictNumber = (val) => {
    var num = val.toString().replace(/[^0-9]/g, '');
    setVal(num); return num;
  };

  const setValueStrictDecimal = (val) => {
    const regex = /([0-9]*[\.]{0,1}[0-9]{0,3})/s;
    var decimal = val.toString().match(regex)[0];
    setVal(decimal); return decimal;
  };


  useEffect(() => {
    if (!!props.type != false && !!props.value != false) {
      if (props.type == 'number') {
        setValueStrictNumber(props.value);
      } else if (props.type == 'decimal') {
        setValueStrictDecimal(props.value);
      } else {
        setVal(props.value);
      }
    } else if (typeof props.value != undefined && props.value != null) {
      setVal(props.value);
    }
  }, [props.value]);
  
  var canRead = 1;
  var canWrite = 1;
  var isRequired = false;

  if (props.controlFieldsStatus)
  {
    canRead = props.read;
    canWrite = props.write;
    isRequired = props.required;
  }else if(typeof props.required != "undefined"){
    isRequired = props.required;
  }

  var widthPercent = '18%';
  if (props.fullWidth) widthPercent = '100%';
  if (props.mediumWidth) widthPercent = '80%'
  if (props.smallWidth) widthPercent = '40%'
  if (props.fullSizeWidth) widthPercent = 'calc(' + widthPercent + ' + 8px)'

  var requiredStar = "";
  if (isRequired) requiredStar = " *";

  if (canWrite){
    return (
      <FormControl className="textField-form" sx={{ m: !props?.noMargin ? 1 : 0, width: widthPercent, marginLeft: !props?.noMargin ? 1 : 0, marginRight: !props?.noMargin ? 1 : 0, marginTop: 0, marginBottom: 0 }} size="small" error={props.error} required={isRequired}>
        <TextField 
          id={props.id} 
          label={props.label+requiredStar} 
          variant={props.variant}
          value={value} 
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={!!props.multiline != false ? null : handleKeySubmit}
          type={"text"}
          rows={!!props.rows ? props.rows : null} 
          multiline={!!props.multiline != false ? true : false}
          className={!!props.className != false ? props.className : "textFieldCustom"} 
          disabled={!!props.disabled != false ? props.disabled : false} 
          error={props.error}
          sx={{ 
            backgroundColor: props?.backgroundColor ? props.backgroundColor : '', 
            borderRadius: props.borderRadius ? props?.borderRadius : 0 
          }}
        />
        {(props.error && (!!props.helperText != false)) && <FormHelperText sx={{ ml: 0}}>{props.helperText}</FormHelperText>}
      </FormControl>
    );
  }

  if (canRead){
    var sizeDetailField = 'small';
    if (props.fullWidth)
    {
      sizeDetailField = 'full';
    }  

    return (<DetailField title={props.label} content={props.value} size={sizeDetailField} canRead={canRead} multiline={!!props.multiline != false ? true : false} />);
  }
}
export default TextFieldCustom
